import { Amplify } from 'aws-amplify';
import '@aws-amplify/ui-react/styles.css';
import UserDetails from './UserDetails';
import awsExports from './aws-exports';

// App.js
import React, { useState, useEffect } from 'react'; // useEffect here
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Modal from 'react-modal';
import { CognitoProvider } from './CognitoContext';
import WaitingList from './WaitingList';
import Example from './Example';
import Dashboard from './Dashboard';
//import Login from './Login';
import About from './About';
import Home from './Home';
import Contact from './Contact';
import './App.css';
import FlowFieldAnimation from './flow';
import Science from './Science'
import HomeSafari from './HomeSafari'
import AboutSafari from './AboutSafari'
import MobileHomepage from './MobileHomepage';

import SponsorDashboard from './SponsorDashboard';
import MemberReportDashboard from './MemberDashboard';
import SignUpForm from './SignUpForm';

import DemoLayout from './components/DemoLayout';
import DemoDashboard from './components/DemoDashboard';
import TestsAndDisease from './components/TestsAndDisease';
import CohortsForTesting from './components/CohortsForTesting';
import ParticipationAndSentiment from './components/ParticipationAndSentiment';
import TestResults from './components/TestResults';
import RiskTracking from './components/RiskTracking';
import Surveys from './components/Surveys';
import HealthLearning from './components/HealthLearning';
import ProfileSettings from './components/ProfileSettings';
import Support from './components/Support';
import DemoLogin from './components/DemoLogin';
import SurveyQuestions from './components/SurveyQuestions';


import Header from './Header';
import Footer from './Footer';

import {
  ClinicalTrialEnrichment, PolygenicGameteDonorScreening,
  PolygenicModeling, MachineLearning, Partnerships, Accessibility, PolygenicDiseases,
} from './PlaceholderPages';


import {
  HowGWASWorks, CurrentGWASTrends, PolygenicScoresFromGWAS, ImprovingPolygenicScores,
  MultiTraitApproaches, BayesianApproaches, DiseasePRSStudies, TermsAndConcepts,
  ClinicalPerception, CaveatsAndLimitations
} from './SciencePages';


const PASSWORD = 'UnlockTheData!';


Amplify.configure(awsExports);
Amplify.Logger.LOG_LEVEL = 'DEBUG';


const ProtectedRoute = ({ element }) => {
  const [password, setPassword] = useState('');
  const [authenticated, setAuthenticated] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(true);

  const handleLogin = () => {
      if (password === PASSWORD) {
          setAuthenticated(true);
          setModalIsOpen(false);
      } else {
          alert('Incorrect password');
      }
  };

  return (
<div style={{ 
      position: 'relative', 
      padding: '20px', 
      minHeight: '100vh', // Ensure the content takes up at least the full viewport height
      paddingTop: '80px', // Adjust this value based on your header height
      paddingBottom: '80px' // Adjust this value based on your footer height
    }}>
      {authenticated ? (
        element
      ) : (
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={() => {}}
          style={{
            overlay: {
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
              zIndex: 1000 // Ensure the modal appears above other content
            },
            content: {
              top: '50%',
              left: '50%',
              right: 'auto',
              bottom: 'auto',
              transform: 'translate(-50%, -50%)',
              padding: '20px',
              borderRadius: '8px',
              border: '1px solid #ccc',
              boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
              width: '300px',
              zIndex: 1001 // Ensure the modal content appears above the overlay
            }
          }}
        >
          <h3 style={{ textAlign: 'center', marginBottom: '20px' }}>Enter Password to Access Sponsor Dashboard</h3>
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Enter password"
            style={{
              width: '100%',
              padding: '10px',
              marginBottom: '10px',
              borderRadius: '4px',
              border: '1px solid #ccc'
            }}
          />
          <button
            onClick={handleLogin}
            style={{
              width: '100%',
              padding: '10px',
              borderRadius: '4px',
              border: 'none',
              backgroundColor: '#007BFF',
              color: '#fff',
              fontSize: '16px',
              cursor: 'pointer'
            }}
          >
            Submit
          </button>
        </Modal>
      )}
    </div>

  );
};







const App = () => {
  const [isSafari, setIsSafari] = useState(false);

  useEffect(() => {
    setIsSafari(/^((?!chrome|android).)*safari/i.test(navigator.userAgent));
    document.title = 'PolyCypher Health';
  }, []);

  return (
    <Router>
      <CognitoProvider>
        <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
          <Header />
          <main style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
            <Routes>
              <Route path="/" element={isSafari ? <HomeSafari /> : <Home />} />
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/example-dev" element={<Example />} />
              <Route path="/waiting-list" element={<WaitingList />} />
              <Route path="/about" element={isSafari ? <AboutSafari /> : <About />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/polygenic-health-report" element={<Example />} />
              <Route path="/clinical-trial-enrichment" element={<ClinicalTrialEnrichment />} />
              <Route path="/polygenic-gamete-donor-screening" element={<PolygenicGameteDonorScreening />} />
              <Route path="/polygenic-modeling" element={<PolygenicModeling />} />
              <Route path="/ML" element={<MachineLearning />} />
              <Route path="/partnerships" element={<Partnerships />} />
              <Route path="/science" element={<Science />} />
              <Route path="/accessibility" element={<Accessibility />} />
              <Route path="/polygenic-diseases" element={<PolygenicDiseases />} />
              <Route path="/flow" element={<FlowFieldAnimation />} />
              <Route path="/how-gwas-works" element={<HowGWASWorks />} />
              <Route path="/current-gwas-trends" element={<CurrentGWASTrends />} />
              <Route path="/polygenic-scores-gwas" element={<PolygenicScoresFromGWAS />} />
              <Route path="/improving-polygenic-scores" element={<ImprovingPolygenicScores />} />
              <Route path="/multi-trait-approaches" element={<MultiTraitApproaches />} />
              <Route path="/bayesian-approaches" element={<BayesianApproaches />} />
              <Route path="/disease-prs-studies" element={<DiseasePRSStudies />} />
              <Route path="/terms-concepts" element={<TermsAndConcepts />} />
              <Route path="/clinical-perception" element={<ClinicalPerception />} />
              <Route path="/caveats-limitations" element={<CaveatsAndLimitations />} />
              <Route path="/mobile-homepage" element={<MobileHomepage />} />
              <Route path="/register" element={<SignUpForm />} />
              <Route path="/login" element={<SignUpForm />} />
              <Route path="/user-details" element={<UserDetails />} />
              <Route path="/sponsor-dashboard" element={<ProtectedRoute element={<SponsorDashboard />} />} />
              <Route path="/member-example-report" element={<MemberReportDashboard />} />
              
              <Route path="/demo" element={<DemoLayout />}>
                <Route index element={<DemoDashboard />} />
                <Route path="tests-and-disease" element={<TestsAndDisease />} />
                <Route path="cohorts" element={<CohortsForTesting />} />
                <Route path="participation" element={<ParticipationAndSentiment />} />
                <Route path="results" element={<TestResults />} />
                <Route path="risk-tracking" element={<RiskTracking />} />
                <Route path="surveys" element={<Surveys />} />
                <Route path="health-learning" element={<HealthLearning />} />
                <Route path="profile-settings" element={<ProfileSettings />} />
                <Route path="support" element={<Support />} />
                <Route path="login" element={<DemoLogin />} />
                <Route path="survey-questions/:id" element={<SurveyQuestions />} />
              </Route>
            </Routes>
          </main>
          <Footer />
        </div>
      </CognitoProvider>
    </Router>
  );
};



export default App;

import React, { useState } from 'react';
import { Typography, TextField, Button, Grid, Paper, Box, Snackbar } from '@mui/material';
import { Alert } from '@mui/material';

// Mock user data
const initialUserData = {
  name: 'John Doe',
  email: 'john.doe@example.com',
  role: 'Manager',
  department: 'Risk Assessment',
  lastLogin: '2024-08-23 14:30:00'
};

function ProfileSettings() {
  const [userData, setUserData] = useState(initialUserData);
  const [editing, setEditing] = useState(false);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });

  const handleEdit = () => {
    setEditing(true);
  };

  const handleCancel = () => {
    setUserData(initialUserData);
    setEditing(false);
  };

  const handleSave = () => {
    // Here you would typically send the updated data to a server
    setEditing(false);
    setSnackbar({ open: true, message: 'Profile updated successfully!', severity: 'success' });
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setUserData(prevData => ({ ...prevData, [name]: value }));
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbar({ ...snackbar, open: false });
  };

  return (
    <Paper>
      <Box p={3}>
        <Typography variant="h4" gutterBottom>
          Profile & Settings
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Name"
              name="name"
              value={userData.name}
              onChange={handleChange}
              disabled={!editing}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Email"
              name="email"
              value={userData.email}
              onChange={handleChange}
              disabled={!editing}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Role"
              value={userData.role}
              disabled
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Department"
              value={userData.department}
              disabled
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body2">
              Last Login: {userData.lastLogin}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            {!editing ? (
              <Button variant="contained" color="primary" onClick={handleEdit}>
                Edit Profile
              </Button>
            ) : (
              <>
                <Button variant="contained" color="primary" onClick={handleSave} style={{ marginRight: '10px' }}>
                  Save Changes
                </Button>
                <Button variant="outlined" color="secondary" onClick={handleCancel}>
                  Cancel
                </Button>
              </>
            )}
          </Grid>
        </Grid>
      </Box>
      <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity={snackbar.severity}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Paper>
  );
}

export default ProfileSettings;

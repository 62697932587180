import React from 'react';
import './Footer.css';

const Footer = () => {
  return (
    <footer className="footer">
        <div className="main-container">
      <div className="content">
      <div className="footer-section logo">
        <img
          src="../white_logo512.png" // Replace with the correct path to your logo image
          alt="PolyCypher Genomics Logo"
          className="footer-logo"
        />
      </div>
      <div className="footer-section text">
        <p className="tagline"><i>Precision Health Intelligence</i></p>
        <p className="copyright">©2024 PolyCypher Health</p>
      </div>
      <div className="footer-section contact">
        <p className="contact-title">Contact</p>
        <a href="mailto:info@polycypherhealth.com" className="contact-info">info@polycypherhealth.com</a>
        <div className="social-media">
        <a href="https://twitter.com/polycypher" className="social-link">
            <img 
            src="../white_x_logo.png" 
            alt="Twitter" 
            style={{ width: '24px', height: '24px' }} // Inline style for sizing
            />
        </a>
        <a href="https://instagram.com/polycypher" className="social-link">
            <img 
            src="../insta_logo.png" 
            alt="Instagram" 
            style={{ width: '30px', height: '30px' }} // Inline style for sizing
            />
        </a>
        </div>
      </div>
      </div>
      </div>
    </footer>
  );
};

export default Footer;
import React, { useEffect, useState, useRef } from 'react';
import { Auth } from 'aws-amplify';
import { useNavigate } from 'react-router-dom';
import * as THREE from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { RoomEnvironment } from 'three/examples/jsm/environments/RoomEnvironment.js';
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer.js';
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass.js';
import { ShaderPass } from 'three/examples/jsm/postprocessing/ShaderPass.js';
import './UserDetails.css';

const UserDetails = () => {
  const [userAttributes, setUserAttributes] = useState(null);
  const [error, setError] = useState('');
  const [isLoaded, setIsLoaded] = useState(false);
  const navigate = useNavigate();
  const mountRef = useRef(null);
  const sceneRef = useRef(null);
  const rendererRef = useRef(null);
  const cameraRef = useRef(null);
  const composerRef = useRef(null);
  const modelRef = useRef(null);
  const customMaterialRef = useRef(null);
  const controlsRef = useRef(null);

  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then(user => {
        setUserAttributes(user.attributes);
        setIsLoaded(true);
      })
      .catch(() => {
        setError('Error fetching user details. Please try again.');
      });
  }, []);

  useEffect(() => {
    if (!isLoaded || !mountRef.current) return;

    const scene = new THREE.Scene();
    sceneRef.current = scene;

    const aspect = mountRef.current.clientWidth / mountRef.current.clientHeight;
    const camera = new THREE.PerspectiveCamera(75, aspect, 0.1, 1000);
    camera.position.z = 5;
    cameraRef.current = camera;

    const renderer = new THREE.WebGLRenderer({ antialias: true });
    renderer.setSize(mountRef.current.clientWidth, mountRef.current.clientHeight);
    renderer.outputColorSpace = THREE.SRGBColorSpace;
    mountRef.current.appendChild(renderer.domElement);
    rendererRef.current = renderer;

    const pmremGenerator = new THREE.PMREMGenerator(renderer);
    scene.environment = pmremGenerator.fromScene(new RoomEnvironment(), 0.04).texture;

    const ambientLight = new THREE.AmbientLight(0xffffff, 0.5);
    scene.add(ambientLight);

    const directionalLight = new THREE.DirectionalLight(0xffffff, 0.8);
    directionalLight.position.set(1, 1, 1);
    scene.add(directionalLight);

    const composer = new EffectComposer(renderer);
    composerRef.current = composer;

    const renderPass = new RenderPass(scene, camera);
    composer.addPass(renderPass);

    const overlayPass = new ShaderPass({
      uniforms: {
        tDiffuse: { value: null },
        time: { value: 0 },
        resolution: { value: new THREE.Vector2(mountRef.current.clientWidth, mountRef.current.clientHeight) },
      },
      vertexShader: `
        varying vec2 vUv;
        void main() {
          vUv = uv;
          gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
        }
      `,
      fragmentShader: `
      uniform float time;
      uniform vec2 resolution;
      uniform sampler2D tDiffuse;
      varying vec2 vUv;

        vec3 mod289(vec3 x) { return x - floor(x * (1.0 / 289.0)) * 289.0; }
        vec4 mod289(vec4 x) { return x - floor(x * (1.0 / 289.0)) * 289.0; }
        vec4 permute(vec4 x) { return mod289(((x*34.0)+1.0)*x); }
        vec4 taylorInvSqrt(vec4 r) { return 1.79284291400159 - 0.85373472095314 * r; }
        float snoise(vec3 v) {
          const vec2 C = vec2(1.0/6.0, 1.0/3.0);
          const vec4 D = vec4(0.0, 0.5, 1.0, 2.0);
          vec3 i  = floor(v + dot(v, C.yyy));
          vec3 x0 = v - i + dot(i, C.xxx);
          vec3 g = step(x0.yzx, x0.xyz);
          vec3 l = 1.0 - g;
          vec3 i1 = min(g.xyz, l.zxy);
          vec3 i2 = max(g.xyz, l.zxy);
          vec3 x1 = x0 - i1 + C.xxx;
          vec3 x2 = x0 - i2 + C.yyy;
          vec3 x3 = x0 - D.yyy;
          i = mod289(i);
          vec4 p = permute(permute(permute(
                    i.z + vec4(0.0, i1.z, i2.z, 1.0))
                  + i.y + vec4(0.0, i1.y, i2.y, 1.0))
                  + i.x + vec4(0.0, i1.x, i2.x, 1.0));
          float n_ = 0.142857142857;
          vec3 ns = n_ * D.wyz - D.xzx;
          vec4 j = p - 49.0 * floor(p * ns.z * ns.z);
          vec4 x_ = floor(j * ns.z);
          vec4 y_ = floor(j - 7.0 * x_);
          vec4 x = x_ *ns.x + ns.yyyy;
          vec4 y = y_ *ns.x + ns.yyyy;
          vec4 h = 1.0 - abs(x) - abs(y);
          vec4 b0 = vec4(x.xy, y.xy);
          vec4 b1 = vec4(x.zw, y.zw);
          vec4 s0 = floor(b0)*2.0 + 1.0;
          vec4 s1 = floor(b1)*2.0 + 1.0;
          vec4 sh = -step(h, vec4(0.0));
          vec4 a0 = b0.xzyw + s0.xzyw*sh.xxyy;
          vec4 a1 = b1.xzyw + s1.xzyw*sh.zzww;
          vec3 p0 = vec3(a0.xy, h.x);
          vec3 p1 = vec3(a0.zw, h.y);
          vec3 p2 = vec3(a1.xy, h.z);
          vec3 p3 = vec3(a1.zw, h.w);
          vec4 norm = taylorInvSqrt(vec4(dot(p0,p0), dot(p1,p1), dot(p2, p2), dot(p3,p3)));
          p0 *= norm.x;
          p1 *= norm.y;
          p2 *= norm.z;
          p3 *= norm.w;
          vec4 m = max(0.6 - vec4(dot(x0,x0), dot(x1,x1), dot(x2,x2), dot(x3,x3)), 0.0);
          m = m * m;
          return 42.0 * dot(m*m, vec4(dot(p0,x0), dot(p1,x1), dot(p2,x2), dot(p3,x3)));
        }



        void main() {
          vec4 originalColor = texture2D(tDiffuse, vUv);
          float timeOffset = mod(time, 100.0) / 100.0; // Extend the time range for a slower change
        
          // Adjust the gradient with a very slow-moving depth effect
          float depthEffect = vUv.y;
          float alpha = 1.0 / (1.0 + exp(5.0 * (depthEffect - 0.5 - timeOffset * 0.1))); // Slowly vary the depth effect over time
        
          // Slow-moving cloud-like noise
          vec3 noiseEffect = vec3(snoise(vec3(vUv * 10.0 + vec2(timeOffset * 0.05, 0.0), time * 0.2)));
          vec3 backgroundLayerColor = vec3(0.9, 0.8, 1.0) + noiseEffect * 0.1; // Apply noise with very slow time change
        
          // Blend the original color with the slow-changing background color using the depth-modulated alpha
          vec3 blendedColor = mix(originalColor.rgb, backgroundLayerColor, alpha);
        
          gl_FragColor = vec4(blendedColor, originalColor.a * alpha); // Use depth-modulated alpha for final output transparency
        }
        
      `,
    });
    composer.addPass(overlayPass);


    const loader = new GLTFLoader();
    loader.load(
      '/scene.gltf',
      (gltf) => {
        const model = gltf.scene;
        modelRef.current = model;
        const customMaterial = new THREE.ShaderMaterial({
          uniforms: {
            time: { value: 0 },
            resolution: { value: new THREE.Vector2(mountRef.current.clientWidth, mountRef.current.clientHeight) },
          },

          vertexShader: `
            varying vec3 vPosition;
            varying vec3 vNormal;
            varying vec2 vUv;
            
            void main() {
              vPosition = position;
              vNormal = normal;
              vUv = uv;
              gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
            }
          `,
          fragmentShader: `
            uniform float time;
            uniform vec2 resolution;
            varying vec3 vPosition;
            varying vec3 vNormal;
            varying vec2 vUv;
            
            vec3 palette(float t) {
              vec3 a = vec3(0.8, 0.5, 0.9);
              vec3 b = vec3(0.2, 0.5, 0.5);
              vec3 c = vec3(1.0, 1.0, 1.0);
              vec3 d = vec3(0.00, 0.33, 0.67);
              
              return a + b * cos(6.28318 * (c * t + d)) * vec3(0.8, 1.0, 1.2);
            }
          
            float noise(vec3 p) {
              return fract(sin(dot(p, vec3(12.9898, 78.233, 45.5432))) * 43758.5453);
            }
          
            void main() {
              vec3 normal = normalize(vNormal);
              vec3 viewDirection = normalize(cameraPosition - vPosition);
              
              float pattern = sin(vPosition.x * 5.1 + time) * 
                              cos(vPosition.y * 5.2 + time * 1.1) * 
                              sin(vPosition.z * 5.3 + time * 0.9);
              pattern += noise(vPosition * 2.0 + time * 0.5) * 0.5;
              
              vec3 baseColor = palette(pattern * 0.5 + 0.5);
              
              float diffuse = max(dot(normal, vec3(1.0, 1.0, 1.0)), 0.0);
              diffuse = pow(diffuse, 1.0);
              
              float fresnel = pow(1.0 - max(dot(viewDirection, normal), 0.0), 4.0);
              vec3 glowColor = vec3(0.8, 0.2, 1.0);
              
              vec3 finalColor = mix(baseColor * (0.5 + 0.5 * diffuse), glowColor, fresnel * 0.7);
              
              float haze = noise(vPosition * 0.1 + time * 0.05) * 0.1;
              finalColor += vec3(haze);
              
              finalColor = pow(finalColor, vec3(1.0));
              finalColor *= 0.2;
              
              gl_FragColor = vec4(finalColor, 1);
            }
            `,
            side: THREE.DoubleSide,
          });
          customMaterialRef.current = customMaterial;




        model.traverse((child) => {
          if (child.isMesh) {
            child.material = customMaterial;
          }
        });

        scene.add(model);

        const box = new THREE.Box3().setFromObject(model);
        const center = box.getCenter(new THREE.Vector3());
        const size = box.getSize(new THREE.Vector3());

        const maxDim = Math.max(size.x, size.y, size.z);
        const fov = 75;
        const cameraZ = maxDim / 2 / Math.tan((fov / 2) * Math.PI / 180);

        model.position.set(-center.x, -center.y, -center.z);
        camera.position.set(0, 0, cameraZ * 1.5);
        camera.lookAt(new THREE.Vector3(0, 0, 0));
        camera.updateProjectionMatrix();

        const controls = new OrbitControls(camera, renderer.domElement);
        controls.target.set(0, 0, 0);
        controls.update();
        controlsRef.current = controls;

        animate();
      },
      undefined,
      (error) => console.error('Error loading GLTF model:', error)
    );

    function animate() {
      requestAnimationFrame(animate);
      const time = performance.now() / 600;
    
      if (customMaterialRef.current && customMaterialRef.current.uniforms) {
        customMaterialRef.current.uniforms.time.value = time;
      }
    
      if (overlayPass.uniforms) {
        overlayPass.uniforms.time.value = time;
      }
    
      if (modelRef.current) {
        modelRef.current.rotation.y += 0.005;
        modelRef.current.rotation.z += 0.005;
      }
    
      if (cameraRef.current && controlsRef.current) {
        const targetZoom = 0;
        const targetPosition = new THREE.Vector3(0, 0, 0);
        
        // Animate zoom
        if (Math.abs(cameraRef.current.zoom - targetZoom) > 0.001) {
          cameraRef.current.zoom += (targetZoom - cameraRef.current.zoom) * 0;
          cameraRef.current.updateProjectionMatrix();
        }
    
        // Animate position
        cameraRef.current.position.lerp(targetPosition, 0.05);
        
        controlsRef.current.update();
      }
    
      composer.render();
    }
    
    const handleResize = () => {
      if (!mountRef.current || !cameraRef.current || !rendererRef.current || !composerRef.current) return;

      const width = mountRef.current.clientWidth;
      const height = mountRef.current.clientHeight;

      cameraRef.current.aspect = width / height;
      cameraRef.current.updateProjectionMatrix();


      rendererRef.current.setSize(width, height);
      composerRef.current.setSize(width, height);

      if (customMaterialRef.current && customMaterialRef.current.uniforms) {
        customMaterialRef.current.uniforms.resolution.value.set(width, height);
      }

      if (overlayPass.uniforms) {
        overlayPass.uniforms.resolution.value.set(width, height);
      }
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
      if (mountRef.current && rendererRef.current) {
        mountRef.current.removeChild(rendererRef.current.domElement);
      }
      if (sceneRef.current) {
        sceneRef.current.clear();
      }
      if (composerRef.current) {
        composerRef.current.dispose();
      }
      if (controlsRef.current) {
        controlsRef.current.dispose();
      }
    };
  }, [isLoaded]);

  const handleSignOut = async () => {
    try {
      await Auth.signOut();
      navigate('/login');
    } catch (error) {
      setError('Error signing out. Please try again.');
    }
  };

  if (error) {
    return <div className="error-message">{error}</div>;
  }

  if (!userAttributes) {
    return <div className="loading-message">Loading...</div>;
  }

  return (
    <div className="user-details-container">
      <div id="d3-container" ref={mountRef}></div>
      <div className="user-info-panel">
        <div className="user-greeting">Hello, {userAttributes.name || userAttributes.email}</div>
        <div className="user-info">Email: {userAttributes.email}</div>
        <div className="user-info">Account Type: {userAttributes['custom:accountType'] || 'N/A'}</div>
        {userAttributes['custom:accountType'] === 'Organization' ? (
          <a href="/sponsor-dashboard" className="dashboard-button">
            Go to Organizational Dashboard
          </a>
        ) : (
          <a href="/member-example-report" className="dashboard-button">
            View Member Report
          </a>
        )}
        <div className="signout-button" onClick={handleSignOut}>Sign out</div>
      </div>
    </div>
  );
  
};

export default UserDetails;
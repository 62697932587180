import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import debounce from 'lodash/debounce';
import "./Contact.css"

//  Contact
const Contact = () => {
    const [cardPositions, setCardPositions] = useState([]);
  
  
  
    const useLoadingText = (isLoading) => {
      const [loadingText, setLoadingText] = useState('Loading');
    
      useEffect(() => {
        if (!isLoading) return;
    
        const interval = setInterval(() => {
          setLoadingText(prevText => prevText + '.' === 'Loading....' ? 'Loading' : prevText + '.');
        }, 500);
    
        return () => clearInterval(interval);
      }, [isLoading]);
    
      return loadingText;
    };
    
      const [email, setEmail] = useState('');
      const [message, setMessage] = useState('');
      const [responseMessage, setResponseMessage] = useState('');
      const [isLoading, setIsLoading] = useState(false);
      const loadingText = useLoadingText(isLoading);
    
      const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
    
        try {
          const response = await axios.post('https://uebp8qa1ge.execute-api.us-east-2.amazonaws.com/add', { email, message });
          setResponseMessage(response.status === 200 ? 'Message sent! 🎉' : 'An error occurred while adding to the waiting list.');
          setMessage('');
        } catch (error) {
          console.error('There was an error sending the request', error);
          setResponseMessage('An error occurred.');
        } finally {
          setIsLoading(false);
        }
      };
  
      const updateCardPositions = () => {
        // Select all card elements
        const cards = document.querySelectorAll('.text-center');
      
        const positions = Array.from(cards).map(card => {
          const rect = card.getBoundingClientRect();
          return {
            x: rect.left + window.scrollX,
            y: rect.top + window.scrollY,
            width: rect.width,
            height: rect.height
          };
        });
    
        // Only update state if positions have actually changed
        setCardPositions(prevPositions => {
          if (JSON.stringify(prevPositions) !== JSON.stringify(positions)) {
            return positions;
          }
          return prevPositions;
        });
      };
    
      // Debounced version of the update function
      const debouncedUpdateCardPositions = debounce(updateCardPositions, 100);
    
      useEffect(() => {
        window.addEventListener('resize', debouncedUpdateCardPositions);
        
        // Update card positions after the layout has likely stabilized
        setTimeout(updateCardPositions, 500);
    
        return () => {
          window.removeEventListener('resize', debouncedUpdateCardPositions);
        };
      }, []);

      return (
        <div className="container mt-5">
          <div className="row">
            <div className="col-md-6 offset-md-3">
    
              <div className="contact-form">
              <div style={{
              textAlign: 'center',
              padding: '20px',
              backgroundColor: '#000000',
            }}>
              <h1 style={{
                fontFamily: 'Helvetica, Arial, sans-serif',
                fontSize: '2.5rem',
                fontWeight: 'bold',
                color: '#FFFFFF',
                margin: '0',
              }}>
                Contact Us
              </h1>
            </div>
                <h3>Send us a message</h3>
                <form onSubmit={handleSubmit}>
                  <div>
                    <label htmlFor="email-react">Email:</label>
                    <input 
                      type="email" 
                      id="email-react" 
                      value={email} 
                      onChange={(e) => setEmail(e.target.value)} 
                      required 
                    />
                  </div>
                  <div>
                    <label htmlFor="message-react">Message:</label>
                    <textarea 
                      id="message-react" 
                      value={message} 
                      onChange={(e) => setMessage(e.target.value)}
                    />
                  </div>
                  <button type="submit">Submit</button>
                </form>
                <div className="response-message">
                  {isLoading ? loadingText : responseMessage}
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    };
    
    export default Contact;
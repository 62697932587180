import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Typography, TextField, Button, List, ListItem, ListItemText, IconButton, Radio, RadioGroup, FormControlLabel, FormControl, FormLabel } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

function SurveyQuestions() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [questions, setQuestions] = useState([]);
  const [newQuestion, setNewQuestion] = useState('');
  const [newAnswers, setNewAnswers] = useState(['', '']);
  const [answerType, setAnswerType] = useState('yesNo');

  useEffect(() => {
    const storedSurveys = JSON.parse(localStorage.getItem('surveys')) || [];
    const currentSurvey = storedSurveys.find(survey => survey.id === parseInt(id));
    if (currentSurvey && currentSurvey.questions) {
      setQuestions(currentSurvey.questions);
    }
  }, [id]);

  const handleAddQuestion = () => {
    if (newQuestion.trim() !== '') {
      const newQuestionObj = {
        question: newQuestion.trim(),
        answerType: answerType,
        answers: answerType === 'yesNo' ? ['Yes', 'No'] : newAnswers.filter(answer => answer.trim() !== '')
      };
      setQuestions([...questions, newQuestionObj]);
      setNewQuestion('');
      setNewAnswers(['', '']);
      setAnswerType('yesNo');
    }
  };

  const handleRemoveQuestion = (index) => {
    const updatedQuestions = questions.filter((_, i) => i !== index);
    setQuestions(updatedQuestions);
  };

  const handleSaveSurvey = () => {
    const storedSurveys = JSON.parse(localStorage.getItem('surveys')) || [];
    const updatedSurveys = storedSurveys.map(survey => {
      if (survey.id === parseInt(id)) {
        return { ...survey, questions: questions };
      }
      return survey;
    });
    localStorage.setItem('surveys', JSON.stringify(updatedSurveys));
    navigate('/surveys');
  };

  const handleAnswerTypeChange = (event) => {
    setAnswerType(event.target.value);
  };

  const handleAnswerChange = (index, value) => {
    const updatedAnswers = [...newAnswers];
    updatedAnswers[index] = value;
    setNewAnswers(updatedAnswers);
  };

  return (
    <div>
      <Typography variant="h4" gutterBottom>
        Create Survey Questions
      </Typography>
      <Typography variant="subtitle1" gutterBottom>
        Survey ID: {id}
      </Typography>
      <TextField
        fullWidth
        label="New Question"
        value={newQuestion}
        onChange={(e) => setNewQuestion(e.target.value)}
        margin="normal"
      />
      <FormControl component="fieldset">
        <FormLabel component="legend">Answer Type</FormLabel>
        <RadioGroup row aria-label="answer type" name="answerType" value={answerType} onChange={handleAnswerTypeChange}>
          <FormControlLabel value="yesNo" control={<Radio />} label="Yes/No" />
          <FormControlLabel value="custom" control={<Radio />} label="Custom Answers" />
        </RadioGroup>
      </FormControl>
      {answerType === 'custom' && (
        <div>
          <TextField
            label="Answer 1"
            value={newAnswers[0]}
            onChange={(e) => handleAnswerChange(0, e.target.value)}
            margin="normal"
          />
          <TextField
            label="Answer 2"
            value={newAnswers[1]}
            onChange={(e) => handleAnswerChange(1, e.target.value)}
            margin="normal"
          />
        </div>
      )}
      <Button variant="contained" color="primary" onClick={handleAddQuestion}>
        Add Question
      </Button>
      <List>
        {questions.map((questionObj, index) => (
          <ListItem key={index}>
            <ListItemText 
              primary={questionObj.question} 
              secondary={`Answer Type: ${questionObj.answerType === 'yesNo' ? 'Yes/No' : 'Custom'}, Answers: ${questionObj.answers.join(', ')}`} 
            />
            <IconButton edge="end" aria-label="delete" onClick={() => handleRemoveQuestion(index)}>
              <DeleteIcon />
            </IconButton>
          </ListItem>
        ))}
      </List>
      <Button variant="contained" color="secondary" onClick={handleSaveSurvey} style={{ marginTop: '20px' }}>
        Save Survey
      </Button>
    </div>
  );
}

export default SurveyQuestions;

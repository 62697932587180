import React, { useState, useEffect, memo } from 'react';
import { LineChart, Line, BarChart, Bar, XAxis, YAxis, CartesianGrid, Legend, ResponsiveContainer, PieChart, Pie, Cell, ScatterChart, Scatter } from 'recharts';
import { Search, Filter, Download, Info } from 'lucide-react';
import { scaleQuantile } from 'd3-scale';
import { Tooltip } from 'react-tooltip';
import zipcodes from 'zipcodes';
import './SponsorDashboard.css';
import { ComposableMap, Geographies, Geography, Marker } from "react-simple-maps";
import { geoPath } from "d3-geo";
import { feature } from "topojson-client";
import { geoAlbersUsa } from "d3-geo";
import CodeVisualizer from './CodeGen';

const geoUrl = "https://cdn.jsdelivr.net/npm/us-atlas@3/counties-10m.json";

const generateRandomData = (count, allCounties) => {
    const diseases = ['Coronary Artery Disease', 'Type 2 Diabetes', 'Stroke', 'Prostate Cancer', 'Colorectal Cancer', 'Breast Cancer', 'Atrial Fibrillation', "Alzheimer's Disease", 'Hypercholesterolemia', 'Obesity'];
    const statuses = ['Test Ordered', 'Sample Obtained', 'Report Issued', 'Report Accessed'];
    const riskLevels = ['Low', 'Medium', 'High'];
    const interventions = ['None', 'Statins', 'Beta Blockers', 'Insulin', 'Lifestyle Changes'];
  
    // Helper function for normal distribution
    const normalRandom = (mean, stdDev) => {
      const u = 1 - Math.random();
      const v = Math.random();
      const z = Math.sqrt(-2.0 * Math.log(u)) * Math.cos(2.0 * Math.PI * v);
      return z * stdDev + mean;
    };
  
    // Helper function for skewed normal distribution
    const skewedNormalRandom = (mean, stdDev, skew) => {
      let r = normalRandom(0, 1);
      r = r + skew * Math.sinh(r);
      return r * stdDev + mean;
    };
  
    return Array.from({ length: count }, (_, i) => {
      const disease = diseases[Math.floor(Math.random() * diseases.length)];
      const prsPercentile = Math.min(Math.max(skewedNormalRandom(50, 20, 0.5), 0), 99.9);
      const baselineRisk = Math.min(Math.max(skewedNormalRandom(0.1, 0.05, 1), 0), 0.2);
      const relativeRisk = 1 + (prsPercentile / 100) * 2;
      const absoluteRisk = baselineRisk * relativeRisk;
      const location = allCounties[Math.floor(Math.random() * allCounties.length)];
      if (!location) return null;
  
      const age = Math.floor(Math.min(Math.max(skewedNormalRandom(45, 15, 0.5), 18), 80));
      const isMale = Math.random() > 0.51;
  
      const bmi = (() => {
        // Mean BMI of 26, which is slightly overweight
        // Standard deviation of 4, which covers a range from underweight to obese
        // Skewness parameter (positive value will skew to the right)
        const mean = 26;
        const stdDev = 4;
        const skew = 2;
    
        function normalRandom(mean, stdDev) {
            let u1 = Math.random();
            let u2 = Math.random();
            let randStdNormal = Math.sqrt(-2.0 * Math.log(u1)) * Math.sin(2.0 * Math.PI * u2);
            return mean + stdDev * randStdNormal;
        }
    
        function skewNormalRandom(mean, stdDev, skew) {
            let u0 = normalRandom(0, 1);
            let v = normalRandom(0, 1);
            let delta = skew / Math.sqrt(1 + skew * skew);
            let u1 = delta * u0 + Math.sqrt(1 - delta * delta) * v;
            let z = (u0 >= 0) ? u1 : -u1;
            return mean + stdDev * z;
        }
    
        let value = skewNormalRandom(mean, stdDev, skew);
    
        // Ensure BMI is within realistic bounds (18.5 to 35)
        value = Math.min(Math.max(value, 16.5), 42);
    
        return value;
    })();

        console.log("Generated member:", {
          fips: '36' + location.fips.padStart(3, '0'),
          county: location.name,
          state: location.state,
        });
        
  
      const smoker = Math.random() < 0.15; // 15% chance of being a smoker
      const regularExercise = Math.random() > (0.6 + (age - 45) / 100); // Exercise likelihood decreases with age
  
      return {
        id: i + 1,
        name: `Member ${i + 1}`,
        age,
        gender: isMale ? 'Male' : 'Female',
        county: location.name,
        state: location.state,
        fips: location.fips, // Assuming all counties are in New York (FIPS 36)
        zip: location.zip,
        latitude: location.latitude,
        longitude: location.longitude,
        disease,
        risk: riskLevels[Math.floor(prsPercentile / 33.33)],
        status: statuses[Math.floor(Math.random() * statuses.length)],
        intervention: interventions[Math.floor(Math.random() * interventions.length)],
        prsPercentile: prsPercentile.toFixed(2),
        relativeRisk: relativeRisk.toFixed(2),
        absoluteRisk: (absoluteRisk * 100).toFixed(2),
        bmi: bmi.toFixed(1),
        smoker,
        regularExercise,
      };
    });
  };
  

const COLORS = ['#3B82F6', '#60A5FA', '#93C5FD', '#818CF8', '#A78BFA', '#4ADE80', '#FBBF24', '#EF4444'];

export default function SponsorDashboard() {


    const columns = [
      'name',
      'age',
      'gender',
      'county',
      'state',
      'zip',
      'disease',
      'risk',
      'status',
      'intervention',
      'prsPercentile',
      'relativeRisk',
      'absoluteRisk',
      'bmi',
      'smoker',
      'regularExercise'
    ];

  const [members, setMembers] = useState([]);
  const [filteredMembers, setFilteredMembers] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [filters, setFilters] = useState({
    risk: 'All',
    disease: 'All',
    intervention: 'All',
    ageRange: [0, 100],
  });
  const [selectedDisease, setSelectedDisease] = useState('Coronary Artery Disease');

  useEffect(() => {
    const fetchData = async () => {
      const allCounties = await fetchCountyData();
      const generatedMembers = generateRandomData(5000, allCounties);
      setMembers(generatedMembers);
      setFilteredMembers(generatedMembers);
    };

    fetchData();
  }, []);

  useEffect(() => {
    const filtered = members.filter(member =>
      member.name.toLowerCase().includes(searchTerm.toLowerCase()) &&
      (filters.risk === 'All' || member.risk === filters.risk) &&
      (filters.disease === 'All' || member.disease === filters.disease) &&
      (filters.intervention === 'All' || member.intervention === filters.intervention) &&
      (member.age >= filters.ageRange[0] && member.age <= filters.ageRange[1])
    );
    setFilteredMembers(filtered);
  }, [searchTerm, filters, members]);

  const getRiskColor = (risk) => {
    switch (risk) {
      case 'Low': return COLORS[5]; // Green
      case 'Medium': return COLORS[6]; // Yellow
      case 'High': return COLORS[7]; // Red
      default: return COLORS[0]; // Default blue
    }
  };

  const riskDistribution = filteredMembers.reduce((acc, member) => {
    acc[member.risk] = (acc[member.risk] || 0) + 1;
    return acc;
  }, {});

  const diseaseDistribution = filteredMembers.reduce((acc, member) => {
    const randomFactor = Math.pow(Math.random(), 9); // Cubing random value to create a fat-tailed distribution
    acc[member.disease] = (acc[member.disease] || 0) + 5 * randomFactor;
    return acc;
  }, {});
  
  const interventionDistribution = filteredMembers.reduce((acc, member) => {
    acc[member.intervention] = (acc[member.intervention] || 0) + 1;
    return acc;
  }, {});


  const riskData = Object.entries(riskDistribution).map(([name, value]) => ({ name, value }));
  const diseaseData = Object.entries(diseaseDistribution).map(([name, value]) => ({ name, value }));
  const interventionData = Object.entries(interventionDistribution).map(([name, value]) => ({ name, value }));
  
const mapData = members.reduce((acc, member) => {
    if (!acc[member.fips.padStart(5, '0')]) {
      acc[member.fips.padStart(5, '0')] = {
        fips: member.fips.padStart(5, '0'),
          county: member.county,
          state: member.state,
          totalPRS: 0,
          count: 0
    };
  }
  acc[member.fips].totalPRS += parseFloat(member.prsPercentile);
  acc[member.fips].count += 1;
  acc[member.fips].avgPRS = acc[member.fips].totalPRS / acc[member.fips].count;
  return acc;
}, {});

console.log("Sample mapData entry:", mapData[Object.keys(mapData)[0]]);
console.log("Members:", members);
console.log("Sample mapData entry:", mapData[Object.keys(mapData)[1]]);
console.log("mapData:", mapData);
console.log("mapData keys:", Object.keys(mapData));

// Calculate average PRS for each county
Object.values(mapData).forEach(county => {
  county.avgPRS = county.totalPRS / county.count;
});
  

  
  const relevantStates = ['Massachusetts', 'New Jersey', 'New York'];
  
  const fetchCountyData = async () => {
    const response = await fetch(geoUrl);
    const data = await response.json();
  
    return data.objects.counties.geometries.flatMap(county => {
      const stateFips = county.id.substring(0, 2);
      if (['36'].includes(stateFips)) { // MA, NJ, NY state FIPS codes. , '34', '36'
        const countyName = county.properties.name;
          const state = 'New York';
        //const state = stateFips === '25' ? 'Massachusetts' : stateFips === '34' ? 'New Jersey' : 'New York';
  
        // Use the `zipcodes` library to get additional location data
        const location = zipcodes.lookupByName(countyName, state)[0] || {};
        return {
          name: countyName,
          state,
          fips: county.id,
          zip: location.zip || '[Zip Redacted]',
          latitude: location.latitude || 0,
          longitude: location.longitude || 0
        };
      }
      return [];
    });
  };
  
  fetchCountyData().then(flattenedCounties => {
    // flattenedCounties will be an array of all county objects
    console.log(flattenedCounties);
  });




  const getRandomColor = () => {
    const baseR = 255;
    const baseG = Math.floor(Math.random() * 200);
    const baseB = Math.floor(Math.random() * 20);
    const variation = 10;
    const r = baseR - 2 * Math.floor(Math.random() * variation);
    const g = baseG - 2 * Math.floor(Math.random() * variation);
    const b = baseB - 2 * Math.floor(Math.random() * variation);
    const finalR = Math.min(255, Math.max(0, r));
    const finalG = Math.min(255, Math.max(0, g));
    const finalB = Math.min(255, Math.max(0, b));
    const whiteOverlayAmount = Math.random() * 0.9;
    const overlayR = Math.floor(finalR + (255 - finalR) * whiteOverlayAmount);
    const overlayG = Math.floor(finalG + (255 - finalG) * whiteOverlayAmount);
    const overlayB = Math.floor(finalB + (255 - finalB) * whiteOverlayAmount);
    const color = `rgb(${overlayR}, ${overlayG}, ${overlayB})`;
    return color;
  };


    const MapChart = ({ data, selectedDisease }) => {
      const [tooltipContent, setTooltipContent] = useState("");
      const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 });
      const [geographyData, setGeographyData] = useState(null);
      const [geoColors, setGeoColors] = useState({});

        console.log("MapChart data prop:", data);

      console.log("MapChart rendering, tooltipContent:", tooltipContent);

      useEffect(() => {
        console.log("MapChart useEffect running");
        fetch(geoUrl)
          .then(response => response.json())
          .then(jsonData => {
            console.log("Geography data fetched");
            const filteredGeometries = jsonData.objects.counties.geometries.filter(geo =>
              ['36'].includes(geo.id.substring(0, 2))
            );
            const newGeographyData = {
              ...jsonData,
              objects: {
                ...jsonData.objects,
                counties: {
                  ...jsonData.objects.counties,
                  geometries: filteredGeometries
                }
              }
            };
            setGeographyData(newGeographyData);

            const colors = {};
            filteredGeometries.forEach(geo => {
              colors[geo.id] = getRandomColor();
            });
            setGeoColors(colors);
          });
      }, []);
  
    if (!geographyData) {
      return <div>Loading...</div>;
    }
  
 
  return (
    <>

      <div 
          style={{ position: "relative" }}
          onMouseMove={(e) => {
            setTooltipPosition({ x: e.clientX, y: e.clientY });
          }}
        >
        <ComposableMap
          projection="geoMercator"
          projectionConfig={{
            scale: 5000,
            center: [-75, 42.5],
          }}
          style={{ width: "100%", height: "400px" }}
        >
          <Geographies geography={geographyData}>
            {({ geographies }) =>
              geographies.map(geo => {
                console.log("Geography FIPS:", geo.id);
                const countyData = data[geo.id];
                return (
                  <Geography
                    key={geo.rsmKey}
                    geography={geo}
                    fill={geoColors[geo.id]}
                    stroke="#FFF"
                    strokeWidth={0.5}
                    onMouseEnter={(event) => {
                      const { currentTarget } = event;
                      console.log("geo.id:", geo.id);
                      const countyData = data[geo.id];
                      console.log("countyData:", countyData);
                      const tooltipContent = countyData
                        ? `${geo.properties.name}, ${countyData.state}: Mean percentile: ${countyData.avgPRS ? countyData.avgPRS.toFixed(2) : 'N/A'}`
                        : `${geo.properties.name}: No data`;
                      console.log("Tooltip content:", tooltipContent);
                      setTooltipContent(tooltipContent);
                      currentTarget.setAttribute('stroke', 'black');
                      currentTarget.setAttribute('stroke-width', '2');
                    }}
                  onMouseLeave={(event) => {
                    const { currentTarget } = event;
                    setTooltipContent("");
                    currentTarget.setAttribute('stroke', '#FFF');
                    currentTarget.setAttribute('stroke-width', '0.5');
                  }}
                />
                );
              })
            }
          </Geographies>
        </ComposableMap>
        <div
          style={{
            position: "fixed",
            top: `${tooltipPosition.y + 10}px`,
            left: `${tooltipPosition.x + 10}px`,
            right: "10px",
            backgroundColor: "white",
            padding: "10px",
            border: "1px solid #000",
            borderRadius: "8px",
            maxWidth: "400px",
            minWidth: "200px",
            zIndex: 1000,
            display: tooltipContent ? "block" : "none",
            color: "#000000 !important",
            fontWeight: "bold",
          }}
        >
          {tooltipContent}
        </div>
      </div>
      <div style={{ marginTop: "20px", textAlign: "center" }}>
              <h3 style={{ color: "#000000 !important" }}>Legend</h3>
              <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start", width: "150px" }}>
                {[
                  { color: "#FF0000", label: "90-100%" },
                  { color: "#FF4500", label: "80-89%" },
                  { color: "#FFA500", label: "70-79%" },
                  { color: "#FFD700", label: "60-69%" },
                  { color: "#FFFF00", label: "50-59%" },
                  { color: "#FFFACD", label: "40-49%" },
                  { color: "#FAFAD2", label: "30-39%" },
                  { color: "#FFFFFF", label: "0-29%" },
                ].map(({ color, label }) => (
                  <div key={label} style={{ display: "flex", alignItems: "center", width: "100%", marginBottom: "5px" }}>
                    <div style={{ width: "20px", height: "20px", backgroundColor: color, marginRight: "10px", border: color === "#FFFFFF" ? "1px solid #000" : "none" }}></div>
                    <span style={{ width: "100px", color: "#000000 !important", fontWeight: "bold" }}>{label}</span>
                  </div>
                ))}
              </div>
            </div>

    </>
  );
};
  


  return (
    <div className="p-6 max-w-7xl mx-auto sponsorDashboard">
<h1
  className="text-4xl font-bold mb-8 text-indigo-900"
  style={{
    backgroundColor: '#FFFFFF',
    padding: '20px',
    borderRadius: '10px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    border: '2px solid #E0E0E0',
    textAlign: 'center',
    fontFamily: 'Arial, sans-serif',
    color: '#333',
    letterSpacing: '1px',
    margin: '20px 0',
    transition: 'all 0.3s ease',
    backgroundImage: 'linear-gradient(to right, #FFFcFF, #F0F2fe)'
  }}
>
  Sponsor Dashboard
</h1>


<div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 mb-8">
        <div className="bg-white p-6 rounded-xl shadow-lg">
          <h2 className="text-2xl font-semibold mb-4 text-indigo-800">Risk Distribution: Averaged Over All Diseases</h2>
          <ResponsiveContainer width="100%" height={300}>
            <PieChart>
              <Pie
                data={riskData}
                dataKey="value"
                nameKey="name"
                cx="50%"
                cy="50%"
                outerRadius={80}
                fill="#8884d8"
                label
              >
                {riskData.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={getRiskColor(entry.name)} />
                ))}
              </Pie>
              <Tooltip />
              <Legend />
            </PieChart>
          </ResponsiveContainer>
        </div>

        <div className="bg-white p-6 rounded-xl shadow-lg">
        <h2 className="text-2xl font-semibold mb-4 text-indigo-800" style={{ marginBottom: '100px' }}>Disease Frequency Distribution</h2>
        <ResponsiveContainer width="100%" height={400}>
            <BarChart data={diseaseData}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" angle={-45} textAnchor="end" height={150} />
            <YAxis />
            <Tooltip />
            <Bar dataKey="value" fill={COLORS[0]} />
            </BarChart>
        </ResponsiveContainer>
        </div>

        <div className="bg-white p-6 rounded-xl shadow-lg">
          <h2 className="text-2xl font-semibold mb-4 text-indigo-800">Recommended Intervention Distribution</h2>
          <ResponsiveContainer width="100%" height={300}>
            <PieChart>
              <Pie
                data={interventionData}
                dataKey="value"
                nameKey="name"
                cx="50%"
                cy="50%"
                outerRadius={80}
                fill="#8884d8"
                label
              >
                {interventionData.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={COLORS[index % 5]} />
                ))}
              </Pie>
              <Tooltip />
              <Legend />
            </PieChart>
          </ResponsiveContainer>
        </div>
      </div>

      <div className="bg-white p-6 rounded-xl shadow-lg mb-8">
        <h2 className="text-2xl font-semibold mb-4 text-indigo-800">Risk Prediction Map: Polygenic Risk and Lifestyle Interaction Percentiles</h2>
        <select
          className="mb-4 p-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
          value={selectedDisease}
          onChange={(e) => setSelectedDisease(e.target.value)}
        >
          {Object.keys(diseaseDistribution).map(disease => (
            <option key={disease} value={disease}>{disease}</option>
          ))}
        </select>
        <div className="w-full h-[400px] flex justify-center items-center">
          <MapChart data={mapData} selectedDisease={selectedDisease} />
        </div>
      </div>

      <div className="bg-white p-6 rounded-lg shadow-lg mb-8">
        <h2 className="text-2xl font-bold mb-6">Member Management</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 mb-6">
          <div className="relative">
            <input
              type="text"
              placeholder="    Search members..."
              className="w-full px-4 py-2 pl-10 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <Search className="absolute left-3 top-3 text-gray-400" size={20} />
          </div>
          <select
            className="px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            value={filters.risk}
            onChange={(e) => setFilters({ ...filters, risk: e.target.value })}
          >
            <option value="All">All Risks</option>
            <option value="Low">Low Risk</option>
            <option value="Medium">Medium Risk</option>
            <option value="High">High Risk</option>
          </select>
          <select
            className="px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            value={filters.disease}
            onChange={(e) => setFilters({ ...filters, disease: e.target.value })}
          >
            <option value="All">All Diseases</option>
            <option value="Coronary Artery Disease">Coronary Artery Disease</option>
            <option value="Type 2 Diabetes">Type 2 Diabetes</option>
            <option value="Stroke">Stroke</option>
            <option value="Prostate Cancer">Prostate Cancer</option>
            <option value="Colorectal Cancer">Colorectal Cancer</option>
            <option value="Breast Cancer">Breast Cancer</option>
            <option value="Atrial Fibrillation">Atrial Fibrillation</option>
            <option value="Alzheimer's Disease">Alzheimer's Disease</option>
            <option value="Hypercholesterolemia">Hypercholesterolemia</option>
            <option value="Obesity">Obesity</option>
          </select>
          <select
            className="px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            value={filters.intervention}
            onChange={(e) => setFilters({ ...filters, intervention: e.target.value })}
          >
            <option value="All">All Interventions</option>
            <option value="None">None</option>
            <option value="Statins">Statins</option>
            <option value="Beta Blockers">Beta Blockers</option>
            <option value="Insulin">Insulin</option>
            <option value="Lifestyle Changes">Lifestyle Changes</option>
          </select>
        </div>
        <button className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2">
          <Download className="inline-block mr-2" size={20} />
          Export Data
        </button>
        <div className="mt-8 overflow-x-auto">
        <table className="w-full text-left border-collapse">
            <thead>
                <tr className="bg-gray-100 text-gray-600 uppercase text-sm leading-normal">
                <th className="px-4 py-3">Name</th>
                <th className="px-4 py-3">Age</th>
                <th className="px-4 py-3">Gender</th>
                <th className="px-4 py-3">Location</th>
                <th className="px-4 py-3">Disease</th>
                <th className="px-4 py-3">Risk Level</th>
                <th className="px-4 py-3">PRS Percentile</th>
                <th className="px-4 py-3">Relative Risk</th>
                <th className="px-4 py-3">Absolute Risk (%)</th>
                <th className="px-4 py-3">Status</th>
                <th className="px-4 py-3">Intervention</th>
                <th className="px-4 py-3">BMI</th>
                <th className="px-4 py-3">Smoker</th>
                <th className="px-4 py-3">Regular Exercise</th>
                </tr>
            </thead>
            <tbody className="text-gray-600 text-sm">
                {filteredMembers.slice(0, 10).map((member, index) => (
                <tr key={member.id} className={index % 2 === 0 ? 'bg-gray-50' : ''}>
                    <td className="px-4 py-3">{member.name}</td>
                    <td className="px-4 py-3">{member.age}</td>
                    <td className="px-4 py-3">{member.gender}</td>
                    <td className="px-4 py-3">{`${member.county}, ${member.state} ${member.zip}`}</td>
                    <td className="px-4 py-3">{member.disease}</td>
                    <td className="px-4 py-3">
                    <span
                        className={`px-2 py-1 rounded-full text-xs font-semibold ${
                        member.risk === 'Low'
                            ? 'bg-green-100 text-green-800'
                            : member.risk === 'Medium'
                            ? 'bg-yellow-100 text-yellow-800'
                            : 'bg-red-100 text-red-800'
                        }`}
                    >
                        {member.risk}
                    </span>
                    </td>
                    <td className="px-4 py-3">{member.prsPercentile}</td>
                    <td className="px-4 py-3">{member.relativeRisk}</td>
                    <td className="px-4 py-3">{member.absoluteRisk}</td>
                    <td className="px-4 py-3">{member.status}</td>
                    <td className="px-4 py-3">{member.intervention}</td>
                    <td className="px-4 py-3">{member.bmi}</td>
                    <td className="px-4 py-3">{member.smoker ? 'Yes' : 'No'}</td>
                    <td className="px-4 py-3">{member.regularExercise ? 'Yes' : 'No'}</td>
                </tr>
                ))}
            </tbody>
            </table>

        </div>

          <div>
          <CodeVisualizer data={filteredMembers} columns={columns} />
        </div>

        <div className="bg-white p-4 rounded-lg shadow mb-6">
          <h2 className="text-xl font-semibold mb-4">Risk Factor Analysis</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div>
              <h3 className="text-lg font-semibold mb-2">Smoking Impact</h3>
              <p>
                Smokers: {filteredMembers.filter(m => m.smoker).length} <br />
                Non-smokers: {filteredMembers.filter(m => !m.smoker).length}
              </p>
              <p className="mt-2 text-sm text-gray-600">
                Smoking is a significant risk factor for many diseases. This data can help identify the prevalence of smoking among members.
              </p>
            </div>
            <div>
              <h3 className="text-lg font-semibold mb-2">Exercise Habits</h3>
              <p>
                Regular exercise: {filteredMembers.filter(m => m.regularExercise).length} <br />
                Irregular to no exercise: {filteredMembers.filter(m => !m.regularExercise).length}
              </p>
              <p className="mt-2 text-sm text-gray-600">
                Regular exercise can significantly reduce the risk of many diseases. This data shows the exercise habits of members.
              </p>
            </div>
          </div>
        </div>

        <div className="bg-white p-4 rounded-lg shadow mb-6">
        <h2 className="text-xl font-semibold mb-4" style={{ marginBottom: '4rem' }}>BMI Distribution</h2>
          <ResponsiveContainer width="100%" height={400}>
            <BarChart
              data={Object.entries(
                filteredMembers.reduce((acc, member) => {
                  const bmi = member.bmi;
                  const binWidth = 1;
                  const binIndex = Math.floor((bmi - 18) / binWidth);
                  const binStart = 18 + binIndex * binWidth;
                  const binEnd = binStart + binWidth;
                  const binRange = `${binStart.toFixed(1)}-${binEnd.toFixed(1)}`;
                  acc[binRange] = (acc[binRange] || 0) + 1;
                  return acc;
                }, {})
              )
                .sort((a, b) => a[0].localeCompare(b[0]))
                .map(([binRange, count]) => ({ binRange, count }))}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis
                height={100}
                dataKey="binRange"
                label={{ value: 'BMI Range', position: 'insideBottomRight', offset: 10 }}
                interval={0}
                tick={{ angle: -45, textAnchor: 'end' }}
              />
              <YAxis label={{ value: 'Count', angle: -90, position: 'insideLeft' }} />
              <Tooltip formatter={(value, name) => [`Count: ${value}`, `BMI Range: ${name}`]} />
              <Bar dataKey="count" fill="#8884d8" />
            </BarChart>
          </ResponsiveContainer>
          <p className="mt-4 text-sm text-gray-600">
            This chart shows the distribution of BMI across all members.
            BMI is an indicator of overall health and can be a risk factor for various diseases.
          </p>
        </div>
      </div>
    </div>
  );
}

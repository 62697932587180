import React from 'react';
import { Typography, Grid, Paper, Box } from '@mui/material';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, PieChart, Pie, Cell } from 'recharts';

const riskData = [
  { name: 'Low Risk', value: 400 },
  { name: 'Medium Risk', value: 300 },
  { name: 'High Risk', value: 100 },
];

const COLORS = ['#0088FE', '#00C49F', '#FFBB28'];

const diseaseData = [
  { name: 'CAD', cases: 120 },
  { name: 'Diabetes', cases: 80 },
  { name: 'Stroke', cases: 40 },
  { name: 'Cancer', cases: 60 },
];

function DemoDashboard() {
  return (
    <div>
      <Typography variant="h4" gutterBottom>
        Dashboard Overview
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Paper>
            <Box p={2}>
              <Typography variant="h6" gutterBottom>
                Risk Distribution
              </Typography>
              <PieChart width={400} height={300}>
                <Pie
                  data={riskData}
                  cx={200}
                  cy={150}
                  labelLine={false}
                  outerRadius={80}
                  fill="#8884d8"
                  dataKey="value"
                >
                  {riskData.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                  ))}
                </Pie>
                <Tooltip />
                <Legend />
              </PieChart>
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper>
            <Box p={2}>
              <Typography variant="h6" gutterBottom>
                Top Diseases
              </Typography>
              <BarChart width={400} height={300} data={diseaseData}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar dataKey="cases" fill="#8884d8" />
              </BarChart>
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper>
            <Box p={2}>
              <Typography variant="h6" gutterBottom>
                Recent Activities
              </Typography>
              <Typography variant="body2">• New survey created: Health Habits 2024</Typography>
              <Typography variant="body2">• Risk assessment completed for Patient ID: 1234</Typography>
              <Typography variant="body2">• Updated cohort: High-risk diabetes patients</Typography>
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper>
            <Box p={2}>
              <Typography variant="h6" gutterBottom>
                Quick Stats
              </Typography>
              <Typography variant="body2">Total Patients: 1,234</Typography>
              <Typography variant="body2">Active Surveys: 5</Typography>
              <Typography variant="body2">Risk Assessments This Month: 89</Typography>
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
}

export default DemoDashboard;

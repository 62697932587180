import React from 'react';
import { Typography, TextField, Button, Grid } from '@mui/material';

function DemoLogin() {
  return (
    <div>
      <Typography variant="h4" gutterBottom>
        Login
      </Typography>
      <form>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TextField fullWidth label="Email" variant="outlined" />
          </Grid>
          <Grid item xs={12}>
            <TextField fullWidth label="Password" type="password" variant="outlined" />
          </Grid>
          <Grid item xs={12}>
            <Button variant="contained" color="primary">
              Log In
            </Button>
          </Grid>
        </Grid>
      </form>
    </div>
  );
}

export default DemoLogin;

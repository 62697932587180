import React, { useState, useEffect, useRef } from 'react';
import './Placeholder.css';
import LassoVisualization from './Lasso'

import axios from 'axios';
import './CustomWaitingList.css';
import DnaMatrixBackground from './DnaMatrixBackground';
import debounce from 'lodash/debounce';
import FlowFieldWords from './flow_full';
import { MTAG, SBayesR, PRSCSauto, LDpred2, PUMACUBS, GenomicLASSO, Lassosum } from './Methods';




// Placeholder component for Home
const MachineLearning = () => <div>
  <LassoVisualization />
  <GenomicLASSO />
  <Lassosum />
  <SBayesR />
  <PRSCSauto />
  <LDpred2 />
  <MTAG />
  <PUMACUBS />

  </div>;

// Placeholder component for Dashboard
const Dashboard = () => <div>Dashboard Page Content</div>;

// Placeholder component for Example
const Example = () => <div>Example Page Content</div>;

// Placeholder component for Login
const Login = () => <div>Login Page Content</div>;

// Placeholder component for About
const About = () => <div>About Page Content</div>;

// Placeholder component for PolygenicDiseases
const PolygenicDiseases = () => {
  return (
    <div style={{
      fontFamily: 'Arial, sans-serif',
      padding: '20px',
      backgroundColor: '#F3E5F5',
      background: 'linear-gradient(135deg, #F3E5F5 30%, #EDE7F6 90%)'
    }}>
      <h1 style={{
        textAlign: 'center',
        color: '#4A148C',
        backgroundColor: '#FFF',
        textShadow: '1px 1px 2px rgba(0,0,0,0.2)'
      }}><b>Polygenic Diseases and Scores</b></h1>
      
      <div style={{
        backgroundColor: 'rgba(237, 231, 246, 0.9)',
        padding: '15px',
        borderRadius: '10px',
        margin: '20px 0',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        border: '1px solid #D1C4E9'
      }}>
        <h2 style={{
          color: '#6A1B9A',
          borderBottom: '2px solid #D1C4E9'
        }}>Overview</h2>
        <p style={{ color: '#333' }}>
          This page contains a general overview of polygenic scores applied to humans, including an overview of the technical background and the current state-of-the-art in the application of polygenic scores to disease. Conventional genetic screening can overlook the many-gene architecture of common diseases.
        </p>
        
        <h3 style={{ color: '#7B1FA2' }}>GWAS and Polygenic Scores</h3>
        <p style={{ color: '#333' }}>
          The first step of many polygenic scores is running a Genome-Wide Association Study (GWAS). Polygenic scores can be created by using the per-allele effect sizes as discovered by the GWAS, perhaps optimizing these before creating the score, or by training a model directly on the data itself (increasing accuracy). A GWAS, which identifies a set of genetic variants in different individuals to see if any variant is associated with a trait. These associations are very weak, but can tell us which pathways are important, and are useful in aggregate.
        </p>
        
        <h3 style={{ color: '#7B1FA2' }}>Factors Influencing Prediction Accuracy</h3>
        <p style={{ color: '#333' }}>
          The prediction accuracy of a common polygenic score is influenced by three factors: the heritability explained by common SNPs for that disease or complex trait, the adequacy of the GWAS sample size for accurate effect size estimation, and the method's ability to predict accurately. All are necessary.
        </p>
      </div>

      <div style={{
        backgroundColor: 'rgba(237, 231, 246, 0.9)',
        padding: '15px',
        borderRadius: '10px',
        margin: '20px 0',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        border: '1px solid #D1C4E9'
      }}>
        <h3 style={{ color: '#7B1FA2' }}>How Does a GWAS Work?</h3>
        <p style={{ color: '#333' }}>
          Illustration of a simulated genotype by phenotype regression for a single SNP. Each dot represents an individual. A GWAS of a continuous trait repeats this analysis at each SNP.
        </p>
        
        <h3 style={{ color: '#7B1FA2' }}>GWA Studies</h3>
        <p style={{ color: '#333' }}>
          GWA studies compare the DNA of participants having varying phenotypes for a particular trait or disease. These participants may be people with a disease (cases) and similar people without the disease (controls), or they may be people with different phenotypes for a particular trait, for example blood pressure. GWA studies identify SNPs and other variants in DNA associated with a disease, but they cannot on their own specify which genes are causal.
        </p>
      </div>


<div style={{ 
  backgroundColor: 'rgba(237, 231, 246, 0.9)',
  padding: '15px',
  borderRadius: '10px',
  margin: '20px 0',
  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  border: '1px solid #D1C4E9',
  background: 'linear-gradient(to right, #EDE7F6, #D1C4E9)'
}}>

  <h2 style={{ color: '#6A1B9A', borderBottom: '2px solid #D1C4E9' }}>Disease Risk Prediction with Polygenic Scores</h2>

  <h3 style={{ color: '#7B1FA2' }}>Coronary Artery Disease</h3>
  
  <p style={{ color: '#333' }}>
    A recent multi-ancestry study found that a coronary artery disease (CAD) polygenic risk score (PRS) was strongly predictive of disease prevalence and incidence. The PRS outperformed traditional clinical risk factors like blood pressure, cholesterol levels, and medical conditions.
    
    Notably, 3% of the study population had a PRS that was as informative for CAD risk as actually having a CAD event. 20%, 10%, and 5% of participants had 3-4-and 5-fold higher CAD odds compared to the general population. 

    The top 8-29% of the PRS distribution had risk equivalent to having conditions like peripheral artery disease and severe hypercholesterolemia. Since those conditions already warrant preventive treatment, a high polygenic score could identify additional people who may benefit from medications or lifestyle changes to reduce CAD risk.

    The CAD PRS also significantly improved 10-year CAD risk prediction when added to standard clinical risk calculators. This demonstrates it provides value above and beyond existing methods.

    In summary, CAD PRS shows promise for refining risk stratification and prevention efforts. More research is still needed on implementation.
  </p><p style={{fontSize: '14px', color: '#7B5FA2',  lineHeight: '1.6'}}> <a href="https://www.nature.com/articles/s41591-023-02429-x" target="_blank" rel="noopener noreferrer">[link]</a> </p>

  <h3 style={{ color: '#7B1FA2' }}>Type 2 Diabetes</h3>

  <p style={{ color: '#333' }}>
    A study found that a type 2 diabetes (T2D) PRS sharply elevated risk prediction, especially for the top 20% of the score distribution. 

    Compared to the rest of the population, the top 20% had 19-fold higher T2D odds after adjusting for risk factors like BMI and family history. The top 5% had an astounding 47-fold increased odds.

    The T2D PRS achieved an AUC of 0.87 for predicting T2D prevalence, demonstrating excellent performance. It was somewhat lower for predicting future onset.

    Those with high polygenic risk could be selected for prevention programs or more aggressive screening. 
  </p><p style={{fontSize: '14px', color: '#7B5FA2',  lineHeight:'1.6'}}> <b></b> <a href="https://www.nature.com/articles/s41598-023-31496-w" target="_blank" rel="noopener noreferrer">[link]</a> </p>

  <h3 style={{ color: '#7B1FA2' }}>Alzheimer's Disease</h3>

  <p style={{ color: '#333' }}>
   A recent Alzheimer's disease PRS was able to predict onset a decade earlier for people with the highest genetic risk compared to the lowest risk group. It performed comparably to having the APOE e4 allele.
   
   It it possible that people with elevated polygenic risk may benefit from more aggressive prevention efforts and monitoring for early symptoms.

   Alzheimer's PRS shows initial promise for improving disease prediction, which may be a useful feature alongside other predictors.
  </p><p style={{fontSize: '14px', color: '#7B5FA2', lineHeight:'1.6'}}> <b></b> <a href="https://journals.plos.org/plosmedicine/article?id=10.1371/journal.pmed.1002258" target="_blank" rel="noopener noreferrer">[link]</a> </p>
  
   <h3 style={{ color: '#7B1FA2' }}>Stroke</h3>

  <p style={{ color: '#333' }}>
A stroke PRS with over 3 million SNPs was recently found to outperform most individual clinical stroke risk factors. It was more predictive than history of conditions like diabetes and smoking.

This score was able to significantly stratify risk in various ancestral populations. The highest genetic risk groups had markedly elevated stroke odds compared to average.

The stroke PRS demonstrates ability to refine risk estimates beyond standard tools. It could help guide prevention and monitoring efforts for those most vulnerable to stroke.
  </p><p style={{fontSize: '14px', color: '#7B5FA2',  lineHeight:'1.6'}}> <b></b> <a href="https://www.nature.com/articles/s41586-022-05165-3" target="_blank" rel="noopener noreferrer">[link 1]</a> <a href="https://www.nature.com/articles/s41467-019-13848-1" target="_blank" rel="noopener noreferrer">[link 2]</a> </p>

</div>
    </div>
  );
};



// Placeholder component for PolygenicHealthReport
const PolygenicHealthReport = () => {
  const [numberOfIndividuals, setNumberOfIndividuals] = useState(1);
  const [numberOfCategories, setNumberOfCategories] = useState(1);

  const handleIndividualChange = (event) => {
    setNumberOfIndividuals(event.target.value);
  };

  const handleCategoryChange = (event) => {
    setNumberOfCategories(event.target.value);
  };

  const paypalIndividualUrl = `https://www.paypal.com/paypalme/polycypher/${numberOfIndividuals * 349}`;
  const paypalCategoryUrl = `https://www.paypal.com/paypalme/polycypher/${numberOfCategories * 10}`;


  const cardStyle = {
    maxWidth: '350px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '20px',
    padding: '20px',
    borderRadius: '10px',
    boxShadow: '0 0 10px rgba(0, 0, 0, 0.2)',
    backgroundColor: '##BC36D2', // Changed background color to purple
  };

  const buttonStyle = {
    backgroundColor: '#4CAF50', // Green background for button
    color: 'white',
    padding: '10px 20px',
    textAlign: 'center',
    textDecoration: 'none',
    display: 'inline-block',
    fontSize: '16px',
    margin: '4px 2px',
    cursor: 'pointer',
    borderRadius: '5px',
  };

  return (
    <div className="container" style={{ padding: '20px', textAlign: 'left', backgroundColor: '#7D3C98' }}> {/* Changed background color to purple */}
      <h1 style={{ textAlign: 'center' }}>Individual Polygenic Score Report</h1>
      <div style={{ textAlign: 'center' }}>
      <p><i>Your personalized report of polygenic scores</i></p>
      </div>
      <p>Many traits and disorders are affected by a multitude of genes, not just one. We can test for thousands of traits and diseases to identify outliers. We believe this information should be available to everyone, so we offer this service at a lower cost than any other company.</p>
      <p>If you have taken an ancestry test, we can use the raw data to calculate polygenic scores. We'll tell you how many and which variants each score is calculated from. We accept data from all companies.</p>
      <p>We calculate every polygenic trait score and polygenic disease risk score offered by all other services, and we use only the best scientific findings to do so.</p>
      <p>The process begins when you email us your raw DNA data file. Once we process your file, you'll get a personalized report containing your polygenic scores. We include the scientific study that the data is from and which genetic variants were used to calculate each score.</p>
      <p>Your data is never sent outside the U.S. for storage or processing. By default, we'll email the report to you, but we can also send you an encrypted report.</p>
      <p>We accept all raw DNA formats (.vcf, .txt, etc.)</p>
      <p><strong>Note:</strong> Your data is kept strictly confidential. You have the option to request all traces of your DNA data to be deleted from our records once you have received your report.</p>

      <div style={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap' }}>
        <div style={cardStyle}>
          <h3><b>Get the Complete Report</b></h3>
          <p><i>I want to see everything (1000+ polygenic scores)</i></p>
          <h2 style={{ fontSize: '16px' }}>$349 per individual</h2>
          <input type="range" min="1" max="50" value={numberOfIndividuals} onChange={handleIndividualChange} />
          <p>Number of individuals: {numberOfIndividuals}</p>
          <button onClick={() => window.open(paypalIndividualUrl, '_blank')} className="button">Buy Now</button>
        </div>
        <div style={cardStyle}>
          <h3><b>Get Individual Polygenic Scores</b></h3>
          <p><i>I'm interested in only a few polygenic scores</i></p>
          <h2 style={{ fontSize: '16px' }}>$10 per score category</h2>
          <input type="range" min="1" max="50" value={numberOfCategories} onChange={handleCategoryChange} />
          <p>Number of diseases or traits: {numberOfCategories}</p>
          <button onClick={() => window.open(paypalCategoryUrl, '_blank')} className="button">Buy Now</button>
        </div>
      </div>

      <p>After payment, the upload form will appear below.</p>

      <h2>Our Refund Policy: full refund for any reason</h2>
      <p>Your satisfaction is our top priority. We aim to provide accurate and high-quality polygenic score reports, and we want you to be completely satisfied with the product you receive. If, for any reason, you are not entirely satisfied, we will give you a refund.</p>
      <p>Our policy offers you a refund at any time if you are unsatisfied with our service for any reason.</p>
      <p>We offer refunds at any percentage of your order. The refund amount is entirely your decision, and we will honor it unconditionally.</p>
    </div>
  );
  };







// Placeholder component for ClinicalTrialEnrichment
const ClinicalTrialEnrichment = () => 


{


  const styles = {
    container: {
      color: '#FFF',
      padding: '20px',
      maxWidth: '800px',
      margin: 'auto'
    },
    header: {
      color: 'white',
      textAlign: 'center',
      marginBottom: '20px'
    },
    section: {
      marginBottom: '15px'
    },
    subHeader: {
      color: '#FFF',
      fontWeight: 'bold',
      marginBottom: '10px'
    },
    paragraph: {
      lineHeight: '1.6',
      textAlign: 'justify'
    },
    list: {
      listStyleType: 'circle',
      marginLeft: '20px'
    },
    listItem: {
      marginBottom: '5px'
    },
    link: {
      color: '#0077CC',
      textDecoration: 'none'
    }
  };
  
    return (
      <div style={styles.container}>
        <h1 style={styles.header}>Clinical Trials with PolyCypher</h1>
  
        <div style={styles.section}>
          <h2 style={styles.subHeader}>Revolutionizing Clinical Trials</h2>
          <p style={styles.paragraph}>
            At PolyCypher, we believe in the power of polygenic risk scores (PRS) to decrease the cost and increase the effectiveness of clinical trials and longitudinal studies. Our PRS platform can identify individuals with an accelerated trajectory of disease onset, leading to more efficient and effective trials.
          </p>
        </div>
  
        <div style={styles.section}>
          <h2 style={styles.subHeader}>Enhancing Trial Success and Reducing Costs</h2>
          <p style={styles.paragraph}>
            By focusing on individuals most likely to benefit from trial interventions, our approach aims to decrease the necessary sample size and associated costs. This targeted enrollment enhances the likelihood of demonstrating disease onset or progression, leading to more successful outcomes.
          </p>
          <p style={styles.paragraph}>
            For instance, in coronary artery disease, our PRS platform can identify a subset of the population with a significantly increased genetic risk -- a group that traditional risk factors or family history may not reliably detect.
          </p>
        </div>
  
        <div style={styles.section}>
          <h2 style={styles.subHeader}>Case Study: Coronary Artery Disease</h2>
          <p style={styles.paragraph}>
            Studies show that individuals with the highest polygenic scores exhibit the greatest benefit from interventions like statins. This concept doesn't just apply to common diseases but extends to a wide range of disease states.
          </p>
          <p style={styles.paragraph}>
            Enriching clinical trial samples with individuals at higher polygenic risk not only increases the trial's statistical power but also significantly reduces the cost and number of participants required.
          </p>
        </div>
  
        <div style={styles.section}>
          <h2 style={styles.subHeader}>Our Commitment to Innovation</h2>
          <p style={styles.paragraph}>
            PolyCypher's commitment to innovative PRS technology makes us a valuable partner in clinical trials. Our platform's ability to enhance trial efficacy offers a unique differentiator for our clients, ensuring that trials are both cost-effective and successful.
          </p>
        </div>
  
        <div style={styles.section}>
          <h2 style={styles.subHeader}>References</h2>
          <ul style={styles.list}>
            <li style={styles.listItem}><a style={styles.link} href="https://www.nature.com/articles/s41467-022-30675-z">Nature - Article on PRS and Clinical Trials</a></li>
            <li style={styles.listItem}><a style={styles.link} href="https://www.nature.com/articles/s41591-023-02429-x">Nature - Polygenic Scores for Coronary Artery Disease</a></li>
            <li style={styles.listItem}><a style={styles.link} href="https://www.ahajournals.org/doi/10.1161/CIRCULATIONAHA.116.024436">AHA Journals - Treatment Benefit and Polygenic Risk</a></li>
            <li style={styles.listItem}><a style={styles.link} href="https://www.sciencedirect.com/science/article/pii/S014067361461730X">Lancet - Clinical Trials and Polygenic Scores</a></li>
          </ul>
        </div>
      </div>
    );
  };

// Component for PolygenicGameteDonorScreening
const PolygenicGameteDonorScreening = () => {
  return (
    <div style={{ fontFamily: 'Trebuchet MS, serif', color: '#2e2e2c', backgroundColor: '#f9f9f9', padding: '40px', border: '1px solid #ddd', borderRadius: '8px' }}>
      <h1 style={{ textAlign: 'center', backgroundColor: '#eff0ff', color: '#0056b3', marginBottom: '30px' }}>Advanced Disease Screening for Gamete Donors</h1>
      
      <p style={{ fontSize: '18px', lineHeight: '1.6' }}>
        We work with fertility clinics to enhance carrier screening, focusing on disease risk reduction. Our specialized polygenic gamete donor screening supplements traditional monogenic testing, providing comprehensive analysis to identify potential disease carriers. This approach refines donor selection, giving prospective parents informed insights for more tailored reproductive choices.
      </p>
      
      <div style={{ backgroundColor: '#e8f0fe', padding: '20px', borderRadius: '8px', marginTop: '20px', borderLeft: '5px solid #0056b3' }}>
        <h2 style={{ color: '#549' }}>Inclusive and Sensitive Family Planning</h2>
        <p style={{ fontSize: '16px', lineHeight: '1.6' }}>
          Our service is inclusive, sensitive, and provides comprehensive support for family planning. We ensure detailed, personalized guidance for all clients, regardless of their family structure or reproductive goals, in an affirming and respectful environment, especially for LGBTQ+ individuals and couples on their parenthood journey.
        </p>
      </div>
    </div>
  );
};



// Placeholder component for PolygenicModeling
const PolygenicModeling = () => {
  return (
    <div style={{ fontFamily: 'Georgia, serif', color: '#2e2e2c', backgroundColor: '#f9f9f9', padding: '40px', border: '1px solid #ddd', borderRadius: '8px' }}>
      <h1 style={{ textAlign: 'center', backgroundColor: '#eff0ff', color: '#0056b3', marginBottom: '30px' }}>Exploring the Frontier of Drug Development with Polygenic Scores</h1>
      
      <p style={{ fontSize: '18px', lineHeight: '1.6' }}>
        The integration of genome-wide association studies (GWAS) into drug development has brought a paradigm shift in pharmaceutical research. Studies like Nelson et al.'s <a href="https://journals.plos.org/plosgenetics/article?id=10.1371/journal.pgen.1008489" target="_blank" rel="noopener noreferrer" style={{ color: '#007bff', textDecoration: 'none' }}>research published in PLOS Genetics</a> indicate a potential doubling in the success rate of drug approvals when backed by genetic evidence, particularly in early phases of drug development.
      </p>
      
      <div style={{ backgroundColor: '#e8f0fe', padding: '20px', borderRadius: '8px', marginTop: '20px', borderLeft: '5px solid #0056b3' }}>
        <h2 style={{ color: '#0056b3' }}>Advancements in pharmacogenomics</h2>
        <p style={{ fontSize: '16px', lineHeight: '1.6' }}>
          Polygenic scores, especially in the field of pharmacogenomics, are paving the way for more precise and personalized medical treatments. Their ability to predict individual responses to drugs is revolutionizing patient care. For more insights, see this <a href="https://www.nature.com/articles/s41576-021-00387-z" target="_blank" rel="noopener noreferrer" style={{ color: '#007bff', textDecoration: 'none' }}>article in Nature Reviews Genetics</a>.
        </p>
      </div>

      <h3 style={{ color: '#f770CC', marginTop: '30px' }}>Enhancing Drug Efficacy with Polygenic Risk Score (PRS) Methods</h3>
      <p style={{ fontSize: '16px', lineHeight: '1.6' }}>
        PRS methods are at the forefront of tailoring treatments based on individual genetic make-up. By utilizing single nucleotide polymorphisms (SNPs), these methods are creating new horizons in drug efficacy and safety. <a href="https://www.nature.com/articles/s41598-019-54849-w" target="_blank" rel="noopener noreferrer" style={{ color: '#d9534f', textDecoration: 'none' }}>This study in Scientific Reports</a> offers a detailed look at the potential of PRS in medical treatment.
      <br></br>
        PolyCypher is building a clear-box model for understanding targetable pathway-based polygenic contributions to disease.
      </p>

      <p style={{ textAlign: 'center', marginTop: '40px', fontStyle: 'italic', fontSize: '18px' }}>
        The future of personalized medicine is being shaped today by polygenic scores, marking a new era in healthcare.
      </p>
    </div>
  );
};


// Placeholder component for Partnerships
const Partnerships = () => {
  return (
    <div style={{ color: '#333', padding: '40px', backgroundColor: '#f9f9f9', border: '1px solid #ddd', borderRadius: '8px' }}>
      <h1 style={{ textAlign: 'center', backgroundColor: '#ffffff', color: '#0056b3', marginBottom: '30px' }}><b>Strategic Partnerships for Polygenic Platform Services</b></h1>

      <p style={{ fontSize: '18px', lineHeight: '1.6' }}>
        Leverage the power of our polygenic platform to enhance your healthcare or discovery modeling solutions. Our cutting-edge platform offers seamless integration of polygenic analysis and prediction into your existing systems, providing an innovative approach to personalized medicine.
      </p>

      <div style={{ backgroundColor: '#e8f0fe', padding: '20px', borderRadius: '8px', marginTop: '20px', borderLeft: '5px solid #0056b3' }}>
        <h2 style={{ fontFamily: 'Segoe', color: '#0056b3' }}>Why Partner with Us?</h2>
        <p style={{ fontSize: '16px', lineHeight: '1.6' }}>
          Our expertise in genomic data analysis and predictive modeling positions us uniquely to provide backend solutions for a range of applications, from drug development to personalized healthcare. Our platform is designed for robustness, scalability, and accuracy, making it an ideal choice for organizations looking to incorporate genetic insights into their services.
        </p>
      </div>

      <h3 style={{ color: '#49184f', marginTop: '30px' }}>Benefits of Our Polygenic Platform</h3>
      <ul style={{ fontSize: '16px', lineHeight: '1.6', listStyleType: 'circle', marginLeft: '20px' }}>
        <li>Advanced Polygenic Risk Scoring: Leverage sophisticated algorithms for accurate risk prediction and personalized treatment strategies.</li>
        <li>Data Integration: Easily integrate large-scale genomic data into your systems, enhancing your data-driven decision-making capabilities.</li>
        <li>Customizable Solutions: Tailor our platform to suit your specific needs, ensuring flexibility and efficiency in application.</li>
      </ul>
    </div>
  );
};








// Placeholder component for Accessibility
const Accessibility = () => {

const [zipCodeIncome, setZipCodeIncome] = useState('');
const [householdIncome, setHouseholdIncome] = useState('');
const [eligibility, setEligibility] = useState('');
const [error, setError] = useState('');
const [discountInfo, setDiscountInfo] = useState('');

const validateInputs = () => {
  if (!zipCodeIncome || !householdIncome) {
    setError('Please fill in all fields');
    return false;
  }
  if (isNaN(zipCodeIncome) || isNaN(householdIncome)) {
    setError('Income fields must be numeric');
    return false;
  }
  setError('');
  return true;
};

const calculateDiscount = () => {
  if (!validateInputs()) return;

  let zipIncome = parseInt(zipCodeIncome);
  let hhIncome = parseInt(householdIncome);
  let discount;

  if (zipIncome < 42000 || hhIncome < 90000) {
    discount = 'You are eligible for free testing';
  } else if (hhIncome < 120000) {
    discount = 'You are eligible for 50% off';
  } else if (hhIncome < 150000) {
    discount = 'You are eligible for 25% off';
  } else {
    discount = 'You are not eligible for free testing';
  }

  setDiscountInfo(`${discount}.`);
};

return (
  <div className="polyCypher" style={{ maxWidth: '800px', margin: 'auto', backgroundColor: '#f2f2f8', padding: '20px', borderRadius: '10px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
    <h1 style={{ fontFamily: 'Trebuchet MS', color: '#4a4e69', backgroundColor: '#f9d9f9', textAlign: 'center', borderBottom: '2px solid #9a8c98', paddingBottom: '10px' }}><b>Our Commitment to Accessibility</b></h1>

    <div className="accessibility-info" style={{ backgroundColor: '#e9ecef', padding: '15px', borderRadius: '8px', marginTop: '20px' }}>
      <h2 style={{ color: '#22223b', marginBottom: '10px' }}><b>Financial Accessibility & Free Genomic Screening</b></h2>
      <p style={{ color: '#4a4e69', lineHeight: '1.6' }}>
        We are dedicated to ensuring that financial constraints don't hinder access to our services. Eligibility for free or discounted genetic testing is determined by the following criteria:
      </p>
      <ul style={{ listStyleType: 'circle', marginLeft: '20px' }}>
        <li style={{ color :'#4a3e69', marginBottom: '5px' }}>Residents, or physicians who pratice in zip codes with an average adjusted gross income less than $42,000 in 2020 qualify for free analysis.</li>
        <li style={{ color :'#4a3e69', marginBottom: '5px' }}>Households with a total combined income under $90K are eligible for free testing.</li>
      </ul>
      <p style={{ color: '#4a4e69', lineHeight: '1.6', marginTop: '10px' }}>
        Understanding your genome is a fundamental right. PolyCypher is committed to making predictive genomics available to everyone. If your circumstances don't meet these criteria but still pose a challenge in accessing our services, please contact us for special accommodations.
      </p>
    </div>

    <div className="discount-form" style={{ backgroundColor: '#4a3e69', padding: '15px', borderRadius: '8px', marginTop: '20px' }}>
      {error && <p className="error-message" style={{ color: 'red' }}>{error}</p>}
      <div className="input-fields" style={{ display: 'flex', flexDirection: 'column', gap: '10px', marginBottom: '15px' }}>
        <input
          type="text"
          value={zipCodeIncome}
          onChange={(e) => setZipCodeIncome(e.target.value)}
          placeholder="Enter Zip Code Income"
          style={{ padding: '10px', borderRadius: '5px', border: '1px solid #ccc' }}
        />
        <input
          type="text"
          value={householdIncome}
          onChange={(e) => setHouseholdIncome(e.target.value)}
          placeholder="Enter Household Income"
          style={{ padding: '10px', borderRadius: '5px', border: '1px solid #ccc' }}
        />
      </div>
      <button onClick={calculateDiscount} style={{ backgroundColor: '#fddfff', color: '#4a3e69', padding: '10px 15px', borderRadius: '5px', border: 'none', cursor: 'pointer' }}>Determine Discount</button>
      <p className="discount-result" style={{ color: 'black', marginTop: '10px' }}>{discountInfo}</p>
    </div>
  </div>
)

};

// Export all components
export {
  MachineLearning, Dashboard, Example, Login, About,
  PolygenicHealthReport, ClinicalTrialEnrichment, PolygenicGameteDonorScreening,
  PolygenicModeling, Partnerships, Accessibility, PolygenicDiseases
};
import React, { useState } from 'react';
import { Typography, Paper, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from '@mui/material';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, LineChart, Line } from 'recharts';
import { TrendingUp, GroupWork, Update } from '@mui/icons-material';

// Mock data for visualizations
const generateRiskReductionData = (startDate, endDate) => {
  // In a real scenario, this would fetch data based on the date range
  return [
    { disease: 'CAD', expected: 15, actual: 18 },
    { disease: 'Diabetes', expected: 12, actual: 10 },
    { disease: 'Stroke', expected: 8, actual: 9 },
    { disease: 'Cancer', expected: 5, actual: 6 },
  ];
};

const riskTrackingData = [
  { month: 'Jan', predicted: 80, actual: 82 },
  { month: 'Feb', predicted: 78, actual: 79 },
  { month: 'Mar', predicted: 75, actual: 74 },
  { month: 'Apr', predicted: 72, actual: 70 },
  { month: 'May', predicted: 70, actual: 68 },
  { month: 'Jun', predicted: 68, actual: 65 },
];

const underwritingImpactData = [
  { area: 'Improved Accuracy', description: 'More precise risk assessment leading to better pricing', impact: 'High' },
  { area: 'Cost Reduction', description: 'Streamlined processes and reduced manual reviews', impact: 'Medium' },
  { area: 'Risk Mitigation', description: 'Better identification and management of high-risk policyholders', impact: 'High' },
  { area: 'Customer Satisfaction', description: 'Tailored policies and faster underwriting process', impact: 'Medium' },
  { area: 'Fraud Detection', description: 'Enhanced ability to identify potential fraudulent claims', impact: 'Medium' },
];

function HealthLearning() {
  const [startDate, setStartDate] = useState('2024-01-01');
  const [endDate, setEndDate] = useState('2024-12-31');
  const [riskReductionData, setRiskReductionData] = useState(generateRiskReductionData(startDate, endDate));

  const handleDateChange = (date, isStart) => {
    if (isStart) {
      setStartDate(date);
    } else {
      setEndDate(date);
    }
    setRiskReductionData(generateRiskReductionData(startDate, endDate));
  };

  return (
    <div>
      <Typography variant="h4" gutterBottom>
        Continuous Health Learning
      </Typography>
      
            <Grid container spacing={3}>
        <Grid item xs={12} md={4}>
          <Paper style={{ padding: '20px' }}>
            <TrendingUp />
            <Typography variant="h6">Support underwriting research</Typography>
            <Typography variant="body2">Leverage data to improve risk assessment and pricing models</Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} md={4}>
          <Paper style={{ padding: '20px' }}>
            <GroupWork />
            <Typography variant="h6">Understand health of policyholder base</Typography>
            <Typography variant="body2">Gain insights into population health trends and risk factors</Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} md={4}>
          <Paper style={{ padding: '20px' }}>
            <Update />
            <Typography variant="h6">Continual learning via data aggregation and analysis</Typography>
            <Typography variant="body2">Improve predictive models over time with real-world data</Typography>
          </Paper>
        </Grid>
      </Grid>

      <Typography variant="h5" gutterBottom style={{ marginTop: '40px' }}>
        Risk Reduction Analysis
      </Typography>
      <Typography variant="body1" paragraph>
        Comparing expected risk reduction with actual outcomes across different diseases.
      </Typography>
      <Grid container spacing={2} style={{ marginBottom: '20px' }}>
        <Grid item>
          <TextField
            label="Start Date"
            type="date"
            value={startDate}
            onChange={(e) => handleDateChange(e.target.value, true)}
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item>
          <TextField
            label="End Date"
            type="date"
            value={endDate}
            onChange={(e) => handleDateChange(e.target.value, false)}
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
      </Grid>
      <BarChart width={600} height={300} data={riskReductionData}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="disease" />
        <YAxis label={{ value: 'Risk Reduction (%)', angle: -90, position: 'insideLeft' }} />
        <Tooltip />
        <Legend />
        <Bar dataKey="expected" fill="#8884d8" name="Expected Reduction" />
        <Bar dataKey="actual" fill="#82ca9d" name="Actual Reduction" />
      </BarChart>

      <Typography variant="h5" gutterBottom style={{ marginTop: '40px' }}>
        Predicted vs Actual Risk Tracking
      </Typography>
      <Typography variant="body1" paragraph>
        Comparing predicted risk levels with actual outcomes over time.
      </Typography>
      <LineChart width={600} height={300} data={riskTrackingData}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="month" />
        <YAxis label={{ value: 'Risk Score', angle: -90, position: 'insideLeft' }} />
        <Tooltip />
        <Legend />
        <Line type="monotone" dataKey="predicted" stroke="#8884d8" name="Predicted Risk" />
        <Line type="monotone" dataKey="actual" stroke="#82ca9d" name="Actual Risk" />
      </LineChart>

      <Typography variant="h5" gutterBottom style={{ marginTop: '40px' }}>
        Impact on Underwriting Process
      </Typography>
      <Typography variant="body1" paragraph>
        How continuous health learning improves various aspects of underwriting.
      </Typography>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Impact Area</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>Impact Level</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {underwritingImpactData.map((row) => (
              <TableRow key={row.area}>
                <TableCell>{row.area}</TableCell>
                <TableCell>{row.description}</TableCell>
                <TableCell>{row.impact}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}

export default HealthLearning;

/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createSurveyResponse = /* GraphQL */ `
  mutation CreateSurveyResponse(
    $input: CreateSurveyResponseInput!
    $condition: ModelSurveyResponseConditionInput
  ) {
    createSurveyResponse(input: $input, condition: $condition) {
      id
      createdAt
      owner
      heightFeet
      heightInches
      weight
      bloodPressure
      cholesterolLevel
      ldlCholesterol
      hdlCholesterol
      triglycerides
      fastingBloodSugar
      hba1c
      smokingStatus
      alcoholConsumption
      exerciseFrequency
      exerciseIntensity
      diabetesDiagnosis
      hypertensionDiagnosis
      familyHistory
      sleepHours
      psaScreening
      colonoscopyScreening
      updatedAt
      __typename
    }
  }
`;
export const updateSurveyResponse = /* GraphQL */ `
  mutation UpdateSurveyResponse(
    $input: UpdateSurveyResponseInput!
    $condition: ModelSurveyResponseConditionInput
  ) {
    updateSurveyResponse(input: $input, condition: $condition) {
      id
      createdAt
      owner
      heightFeet
      heightInches
      weight
      bloodPressure
      cholesterolLevel
      ldlCholesterol
      hdlCholesterol
      triglycerides
      fastingBloodSugar
      hba1c
      smokingStatus
      alcoholConsumption
      exerciseFrequency
      exerciseIntensity
      diabetesDiagnosis
      hypertensionDiagnosis
      familyHistory
      sleepHours
      psaScreening
      colonoscopyScreening
      updatedAt
      __typename
    }
  }
`;
export const deleteSurveyResponse = /* GraphQL */ `
  mutation DeleteSurveyResponse(
    $input: DeleteSurveyResponseInput!
    $condition: ModelSurveyResponseConditionInput
  ) {
    deleteSurveyResponse(input: $input, condition: $condition) {
      id
      createdAt
      owner
      heightFeet
      heightInches
      weight
      bloodPressure
      cholesterolLevel
      ldlCholesterol
      hdlCholesterol
      triglycerides
      fastingBloodSugar
      hba1c
      smokingStatus
      alcoholConsumption
      exerciseFrequency
      exerciseIntensity
      diabetesDiagnosis
      hypertensionDiagnosis
      familyHistory
      sleepHours
      psaScreening
      colonoscopyScreening
      updatedAt
      __typename
    }
  }
`;

import React, { useState, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import styled from 'styled-components';
import { MathJax, MathJaxContext } from 'better-react-mathjax';

const customStyles = {
    cells: {
      style: {
        whiteSpace: 'normal', // Break lines as needed
        wordBreak: 'break-word', // Break words that are too long for the container
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        maxWidth: '150px', // Set a maximum width and allow the text to take up as much space as needed
      },
    },
    headCells: {
      style: {
        whiteSpace: 'normal', // Ensure the header also wraps correctly
        wordBreak: 'break-word',
        maxWidth: '150px',
      },
    },
  };

const config = {
    loader: { load: ['input/tex', 'output/svg'] },
  };
  


const columnsMath = [
{
    name: 'Method',
    selector: row => row.name,
    sortable: true,
    wrap: true,
    grow: 0.1, // adjust the grow factor as needed
},
{
    name: 'Distribution of SNP Effects',
    cell: row => <MathJax>{row.distribution}</MathJax>,
    ignoreRowClick: true,
    allowOverflow: true,
    wrap: true,
    grow: 3.6, // this column might contain larger content
},
{
    name: 'Tuning Sample',
    selector: row => row.tuningSample,
    sortable: true,
    wrap: true,
    grow: 0.9,
},
{
    name: 'Predefined Parameters',
    selector: row => row.predefinedParameters,
    wrap: true,
    grow: 1,
},
{
    name: 'Parameters Estimated in Tuning Sample',
    selector: row => row.estimatedParameters,
    wrap: true,
    grow: 1.5,
},
];


const dataMath = [
    {
      id: 1,
      name: 'PC+T',
      distribution: 'None',
      tuningSample: 'Yes',
      predefinedParameters: '—',
      estimatedParameters: 'p-value threshold',
    },
    {
      id: 2,
      name: 'SBLUP',
      distribution: '\\( \\beta \\sim N(0, \\frac{\\sigma_g^2}{m}) \\)',
      tuningSample: 'No',
      predefinedParameters: `LD radius in kb, (\\( \\gamma \\))`,
      estimatedParameters: '—',
    },
    {
      id: 3,
      name: 'LDpred2-Inf',
      distribution: '\\( \\beta \\sim N(0, \\frac{\\sigma_g^2}{m}) \\)',
      tuningSample: 'No',
      predefinedParameters: '\\( h_g^2 \\), LD radius in cM or kb',
      estimatedParameters: '—',
    },
    {
      id: 4,
      name: 'LDpred-funct',
      distribution: '\\( \\beta \\sim N(0, c\\sigma_g^2) \\)',
      tuningSample: 'No',
      predefinedParameters: '\\( h_g^2 \\), LD radius in number of SNPs',
      estimatedParameters: '—',
    },
    {
      id: 5,
      name: 'LDpred2',
      distribution: '\\( \\beta_k \\sim N \\left( 0, \\frac{h_g^2}{m} \\right) \\), with probability of \\( \\pi \\), and 0 with probability of \\( 1 - \\pi \\)',
      tuningSample: 'Yes',
      predefinedParameters: '\\( h_g^2 \\), \\( \\pi \\) software default values, LD radius in cM or kb',
      estimatedParameters: 'sparsity, \\( \\pi \\)',
    },
    {
      id: 6,
      name: 'Lassosum',
      distribution: '\\( f(\\beta) = y^T y + (1 - s) \\beta^T X_r^T X_r \\beta - 2 \\beta^T X^T y + s \\beta^T \\beta + 2\\lambda \\|\\beta\\|^{1}_{1} \\)',
      tuningSample: 'Yes',
      predefinedParameters: 'LD blocks',
      estimatedParameters: '\\( \\lambda \\), s',
    },
    {
      id: 7,
      name: 'PRS-CS',
      distribution: '\\( \\beta_j \\sim N(0, \\frac{\\sigma^2}{n} \\psi_j) \\)',
      tuningSample: 'Yes',
      predefinedParameters: `a = 1, b = 0.5, sample size LD Blocks`,
      estimatedParameters: '\\( \\phi \\)',
    },
    {
      id: 8,
      name: 'PRS-CS-auto',
      distribution: 'Same as PRS-CS, but estimates \\( \\phi \\) from the discovery GWAS.',
      tuningSample: 'No',
      predefinedParameters: 'a = 1, b = 0.5, sample size LD Blocks',
      estimatedParameters: '—',
    },
    {
      id: 9,
      name: 'SBayesR',
      distribution: `\\[
        \\beta_j \\mid \\pi, \\sigma^2_{\\beta} \\sim
        \\begin{cases}
        0, & \\text{with probability of } \\pi_1 \\\\
        N(0, \\gamma_{2}^2\\sigma^2_{\\beta}), & \\text{with probability of } \\pi_2 \\\\
        \\vdots \\\\
        N(0, \\gamma_{C}^2\\sigma^2_{\\beta}), & \\text{with probability of } 1 - \\sum_{c=1}^{C-1} \\pi_c
        \\end{cases}
      \\]`,
      tuningSample: 'No',
      predefinedParameters: 'LD radius in cM or kb, C = 4, \\( \\gamma \\) software default values',
      estimatedParameters: '—',
    },
    {
      id: 10,
      name: 'MegaPRS',
      distribution: `\\[
        \\begin{matrix}
        \\text{Lasso: } & \\beta_j \\sim DE(\\lambda \\sigma_j) \\\\
        \\text{Ridge regression: } & \\beta_j \\sim N (0, v \\sigma_j^2) \\\\
        \\text{BOLT-LMM: } & \\beta_j 
        \\begin{cases}
        N (0, (1 - f_2) / \\pi \\sigma_j^2 ), & \\text{with probability of } \\pi \\\\
        N (0, (f_2) / (1 - \\pi) \\sigma_j^2 ), & \\text{with probability of } 1 - \\pi
        \\end{cases}
        \\end{matrix}
      \\]`,
      tuningSample: 'Yes',
      predefinedParameters: 'LD radius in cM or kb, Parameters used in BLD-LDAK, Grid search parameter values for each method',
      estimatedParameters: 'The tuning cohort is used to estimate the parameters that maximize prediction for each model, and from these the model that maximizes prediction is selected.',
    },
];


const DistributionsDescription = () => {
    const smallTextStyle = {
        fontSize: '10px', // Adjust the size as needed
        color: 'black', // Set the text color to black
        'color': 'black !important', // Force the color to be black using !important
        ':root &': { color: 'black' } // Ensure that the root element enforces this style
    };

    return (
        <MathJaxContext config={config}>
            <div style={smallTextStyle}>
                <strong>Distributions:</strong><br />
                <MathJax inline>{"N: normal distribution; "}</MathJax>
                <MathJax inline>{"\\( \\chi^2 \\): chi-squared distribution; "}</MathJax>
                <MathJax inline>{"Dir: Dirichlet distribution; "}</MathJax>
                <MathJax inline>{"DE: double exponential distribution; "}</MathJax>
                <MathJax inline>{"\\( \\mathbb{I}[1] \\): the \\( \\chi^2 \\) (SNP-based heritability) is a predefined parameter, it is estimated from the discovery GWAS, where discovery GWAS is the genome-wide set of association statistics (SNP identification number, reference allele, frequency of reference allele, association effect size for reference allele, standard error of effect size, association p value, sample size). Bold indicates matrix notation, and italic indicates scalar notation. All methods require a reference sample with genotypes to model LD between SNPs."}</MathJax><br /><br />
                <MathJax inline>{"cm: centimorgan; "}</MathJax>
                <MathJax inline>{"GWAS: genome-wide association study; "}</MathJax>
                <MathJax inline>{"kb: kilobase pair; "}</MathJax>
                <MathJax inline>{"LD: linkage disequilibrium; "}</MathJax>
                <MathJax inline>{"SNP: single nucleotide polymorphism."}</MathJax>
            </div>
        </MathJaxContext>
    );
};
















const LassoVisualization = () => {
    const [sampleSize, setSampleSize] = useState(0);
    const [isVisualizationComplete, setVisualizationComplete] = useState(false);
    const letters = ['A', 'C', 'T', 'G'];
    // Update target sample size to be between 70,000 and 4,000,000
    const targetSampleSize = Math.random() * (4000000 - 70000) + 70000;

    useEffect(() => {
        if (isVisualizationComplete) {
            const features = document.querySelectorAll(".lasso-circle");
            features.forEach((feature) => {
                if (feature.style.transform !== 'scale(0)') {
                    feature.classList.add('goldenGlow');
                }
            });
        }
    }, [isVisualizationComplete]);



    const startVisualization = () => {
        setVisualizationComplete(false);
        const features = document.querySelectorAll(".lasso-circle");
        let maxDelay = features.length * 15; // Duration for the last circle's animation
    
        features.forEach((feature, index) => {
            const delay = index * 15;
            setTimeout(() => {
                const shrinkValue = Math.random();
                feature.style.transform = `scale(${shrinkValue > 0.8 ? 0.5 + Math.random() * 0.7 : 0})`;
            }, delay);
        });
    
        let currentSampleSize = Math.floor(targetSampleSize / maxDelay);
        const incrementRate = Math.floor(targetSampleSize / maxDelay);

        const sampleSizeInterval = setInterval(() => {
            const nextSampleSize = currentSampleSize + incrementRate * 35;
            if (nextSampleSize >= targetSampleSize) {
                clearInterval(sampleSizeInterval);
                setSampleSize(targetSampleSize); // Ensures it's an integer
                setVisualizationComplete(true);
            } else {
                setSampleSize(Math.floor(currentSampleSize));
                currentSampleSize = nextSampleSize;
            }
        }, 15);
        
        
        
        
    };
    


    const resetVisualization = () => {
        setVisualizationComplete(false);
        const features = document.querySelectorAll(".lasso-circle");
        features.forEach((feature) => {
            feature.style.transform = 'scale(1)';
            feature.style.fontSize = '15px';
            feature.classList.remove('goldenGlow');
        });
        setSampleSize(0);
    };

    return (
        <div style={containerStyle}>
            <h1 style={titleStyle}><b>How do we decide which genes to use?</b></h1>
            <h4 style={subtitleStyle}>As we get more data, we are able to figure out which genes are important.</h4>
            <div style={gridStyle}>
                {Array.from({ length: 200 }).map((_, i) => (
                    <div key={i}
                         className={`lasso-circle lasso${letters[i % 4]}`}
                         style={circleStyle(letters[i % 4], isVisualizationComplete)}>
                        {letters[i % 4]}
                    </div>
                ))}
            </div>
            {/* Sample Size Display */}
            <div style={sampleCounterStyle}>
    Sample Size: {Math.floor(sampleSize).toLocaleString()}
</div>

            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
    <button 
        style={{ 
            padding: '10px 20px', 
            backgroundColor: '#3498db', 
            color: 'white', 
            border: 'none', 
            borderRadius: '5px', 
            cursor: 'pointer', 
            marginRight: '10px', 
            transition: 'background-color 0.3s'
        }}
        onMouseEnter={e => e.target.style.backgroundColor = '#2c82c9'}
        onMouseLeave={e => e.target.style.backgroundColor = '#3498db'}
        onClick={startVisualization}
    >
        Start Visualization
    </button>
    <button 
        style={{ 
            padding: '10px 20px', 
            backgroundColor: '#3498db', 
            color: 'white', 
            border: 'none', 
            borderRadius: '5px', 
            cursor: 'pointer', 
            transition: 'background-color 0.3s'
        }}
        onMouseEnter={e => e.target.style.backgroundColor = '#2c82c9'}
        onMouseLeave={e => e.target.style.backgroundColor = '#3498db'}
        onClick={resetVisualization}
    >
        Reset Visualization
    </button>

















</div>
<MathJaxContext config={config}>
            <DataTable
            title="There are many machine learning methods used to create polygenic scores"
            columns={columnsMath}
            data={dataMath}
            paginationPerPage={20}
            defaultSortFieldId={1}
            pagination
            customStyles={customStyles}
            />
        </MathJaxContext>
        <div style={{ color: 'black', fontSize: '10px' }}>
        <DistributionsDescription />
        </div>
        </div>
    );
};

const containerStyle = {
    border: '3px solid #3498db',
    padding: '30px',
    borderRadius: '15px',
    justifyContent: 'center',
    alignItems: 'center',
    fontFamily: 'Helvetica',
    position: 'relative',
    backgroundColor: 'white',
    margin: '0 auto',
    boxShadow: '0px 0px 15px rgba(0, 0, 0, 0.1)',
    width: '100vw',
    flex: 1,
    overflow: 'hidden',
    zIndex: 999999,
    transform: 'scale(1)',
    clipPath: 'polygon(0 0, 100% 0, 100% 100%, 0 100%)',
    minHeight: '400px',
    paddingBottom: '50px'
};

const titleStyle = {
    margin: '0',
    padding: '0',
    textAlign: 'center',
    color: 'black',
    backgroundColor: 'white',
    fontFamily: 'Helvetica'
};

const subtitleStyle = {
    ...titleStyle,
    marginTop: '2%',
    marginBottom: '2%'
};

const gridStyle = {
    display: 'grid',
    gridTemplateColumns: 'repeat(20, 1fr)',
    gap: '5px',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    width: '80%',
    margin: '0 auto'
};

const circleStyle = (letter, isVisualizationComplete) => ({
    width: '30px',
    height: '30px',
    borderRadius: '50%', // This line ensures the shape is a circle
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: 'bold',
    transition: 'all 3s',
    backgroundColor: letterColor(letter),
    boxShadow: isVisualizationComplete ? '0 0 20px #FFD700, 0 0 30px #FFD700' : 'none',
    fontSize: '15px', // Set a default font size
    transform: 'scale(1)' // Ensure the scale is set to 1 by default
});

const letterColor = (letter) => {
    switch (letter) {
        case 'A': return 'teal';
        case 'C': return 'purple';
        case 'T': return 'dodgerblue';
        case 'G': return 'deeppink';
        default: return 'transparent';
    }
};

const sampleCounterStyle = {
    fontSize: '20px',
    justifyContent: 'center',
    color: 'black',
    alignItems: 'center',
    position: 'relative',
    marginTop: '20px',
    textAlign: 'center',
    fontFamily: 'Helvetica'
};

const controlPanelStyle = {
    marginTop: '20px',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    fontFamily: 'Helvetica'
};

const buttonStyle = {
    padding: '10px 20px',
    backgroundColor: '#3498db',
    color: 'white',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    marginRight: '10px',
    transition: 'background-color 0.3s',
    ':hover': {
        backgroundColor: '#2c82c9'
    }
};

export default LassoVisualization;
import React, { useState } from 'react';
import { Auth } from 'aws-amplify';
import { useNavigate } from 'react-router-dom';
import './SignUpForm.css';

const SignUpForm = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [accountType, setAccountType] = useState('Member');
  const [error, setError] = useState('');
  const [message, setMessage] = useState('');
  const [isSignedUp, setIsSignedUp] = useState(false);
  const [verificationCode, setVerificationCode] = useState('');
  const [isLoginMode, setIsLoginMode] = useState(false);
  const navigate = useNavigate();

  const [name, setName] = useState('');

  const handleSignUp = async (event) => {
    event.preventDefault();
    setError('');
    setMessage('');
    try {
    await Auth.signUp({
        username: email,
        password,
        attributes: {
            email,
            name,
            'custom:accountType': accountType,
        },
      });
      setIsSignedUp(true);
      setMessage('Sign-up successful! Please check your email for the confirmation code.');
    } catch (error) {
      if (error.code === 'UsernameExistsException') {
        setError('User already exists. Please use a different email.');
      } else {
        console.error('Error signing up:', error);
        setError('Error signing up. Please try again.');
      }
    }
  };

  const handleConfirmSignUp = async (event) => {
    event.preventDefault();
    setError('');
    setMessage('');
    try {
      await Auth.confirmSignUp(email, verificationCode);
      setMessage('Account confirmed! Redirecting to login...');
      setTimeout(() => {
        setIsLoginMode(true);
        setMessage(''); // Clear the message when redirecting to login
      }, 2000);
    } catch (error) {
      console.error('Error confirming sign up:', error);
      setError('Error confirming sign up. Please check the code and try again.');
    }
  };

  const handleLogin = async (event) => {
    event.preventDefault();
    setError('');
    setMessage('');
    try {
      const user = await Auth.signIn(email, password);
      if (user.challengeName === 'SMS_MFA' || user.challengeName === 'SOFTWARE_TOKEN_MFA') {
        const mfaCode = prompt('Enter MFA code:');
        await Auth.confirmSignIn(user, mfaCode);
      }
      setMessage('Login successful! Redirecting...');
      setTimeout(() => {
        navigate('/user-details');
      }, 2000);
    } catch (error) {
      console.error('Error logging in:', error);
      if (error.code === 'UserNotFoundException') {
        setError('User not found. Please check your email or sign up.');
      } else if (error.code === 'NotAuthorizedException') {
        setError('Incorrect email or password. Please try again.');
      } else {
        setError('An error occurred during login. Please try again.');
      }
    }
  };
  


  return (
    <div>
      {isLoginMode ? (
        <>
          <h2 className="form-title">Log In</h2>
          {error && <div className="error-message">{error}</div>}
          {message && <div className="success-message">{message}</div>}
          <form className="sign-up-form" onSubmit={handleLogin}>
            <input
              type="email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              className="input-field"
            />
            <input
              type="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              className="input-field"
            />
            <button type="submit" className="submit-button">Log In</button>
          </form>
          <div className="no-account-prompt">
                Don't have an account?
                <span onClick={() => setIsLoginMode(false)} className="no-account-link">
                    Sign Up
                </span>
            </div>

        </>
      ) : (
        <>
          <h2 className="form-title">Create an Account</h2>
          {error && <div className="error-message">{error}</div>}
          {message && <div className="success-message">{message}</div>}
          {!isSignedUp ? (
            <form className="sign-up-form" onSubmit={handleSignUp}>
                <input
                type="text"
                placeholder="Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
                className="input-field"
                />
              <input
                type="email"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                className="input-field"
              />
              <input
                type="password"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
                className="input-field"
              />
              <div className="toggle-buttons">
                <button
                  type="button"
                  className={`toggle-button ${accountType === 'Member' ? 'active' : ''}`}
                  onClick={() => setAccountType('Member')}
                >
                  Member
                </button>
                <button
                  type="button"
                  className={`toggle-button ${accountType === 'Organization' ? 'active' : ''}`}
                  onClick={() => setAccountType('Organization')}
                >
                  Organization
                </button>
              </div>
              <button type="submit" className="submit-button">Sign Up</button>
            </form>
          ) : (
            <form className="sign-up-form" onSubmit={handleConfirmSignUp}>
              <input
                type="text"
                placeholder="Verification Code"
                value={verificationCode}
                onChange={(e) => setVerificationCode(e.target.value)}
                required
                className="input-field"
              />
              <button type="submit" className="submit-button">Confirm Account</button>
            </form>
          )}
            <div className="account-prompt">
            Already have an account?
            <span onClick={() => setIsLoginMode(true)} className="account-link">
                Log In
            </span>
            </div>

        </>
      )}
    </div>
  );
};

export default SignUpForm;
import React, { Component } from 'react';
import p5 from 'p5';

class FlowFieldWords extends Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
    this.myP5 = null;
    this.particles = [];
    this.flowField = [];
    this.scale = 10;
    this.cols = 0;
    this.rows = 0;
    this.textPaths = [];
    this.font = null;
    this.zoff = 0;
    this.fontLoaded = false;
    this.handleResize = this.handleResize.bind(this); // Only needed if handleResize is not an arrow function
  }

  componentWillUnmount() {
    this.myP5.remove();
    window.removeEventListener('resize', this.handleResize);
  }

  handleResize = () => {
    // Use p5 instance to resize canvas
    this.myP5.resizeCanvas(window.innerWidth, window.innerHeight / 2);
    // Recalculate columns and rows for the flow field
    this.cols = this.myP5.floor(this.myP5.width / this.scale);
    this.rows = this.myP5.floor(this.myP5.height / this.scale);
    // Clear previous particles and text paths to recalculate
    this.particles = [];
    this.textPaths = [];
    // Recalculate the points for the text
    if (this.fontLoaded) {
      this.initializeParticles(this.myP5);
    }
  };


  componentDidMount() {
    this.myP5 = new p5(this.sketch, this.myRef.current);
    window.addEventListener('resize', this.handleResize);
    // Call handleResize immediately to set the initial size correctly
    this.handleResize();
  }

  sketch = (p) => {
    p.preload = () => {
      this.font = p.loadFont('Roboto.ttf', () => {
        this.fontLoaded = true;
      });
    };

    p.setup = () => {
      p.createCanvas(document.body.clientWidth, p.windowHeight / 2);
      this.cols = p.floor(p.width / this.scale);
      this.rows = p.floor(p.height / this.scale);
      p.colorMode(p.HSB, 360, 100, 100, 100);
    };

    p.draw = () => {
      p.background(0, 3);

      if (this.fontLoaded) {
        this.initializeParticles(p);
        this.calculateFlowField(p);

        for (let particle of this.particles) {
          particle.behaviors(p, this.flowField);
          particle.run(p, this.flowField);
          particle.update(p);
          particle.edges(p);
          particle.show(p);
        }
      }
    };

    this.initializeParticles = (p) => {
      if (this.particles.length === 0 && this.fontLoaded) {
          // Calculate the position dynamically based on the window size
          const windowWidth = window.innerWidth;
          const windowHeight = window.innerHeight;

          // Adjust font size and position based on window size
          const fontSize = windowWidth * 0.15;
          const xCenter = windowWidth*0.1 ;
          const yCenter = windowHeight*0.3 ;

          // Calculate the points for the text
          let points = this.font.textToPoints('Our Science', xCenter, yCenter, fontSize, {
            sampleFactor: 2
          });

        let pathLength = 10;
        for (let i = 0; i < points.length; i += pathLength) {
          let path = [];
          for (let j = 0; j < pathLength && i + j < points.length; j++) {
            path.push(p.createVector(points[i + j].x, points[i + j].y));
          }
          this.textPaths.push(path);
        }

        for (let path of this.textPaths) {
          let particle = new Particle(path[0].x, path[0].y, true, path, p, this.scale, this.cols);
          this.particles.push(particle);
        }

        for (let i = 0; i < 10; i++) {
          this.particles.push(new Particle(p.random(p.width), p.random(p.height), false, [], p, this.scale, this.cols));
        }
      }
    };

    this.calculateFlowField = (p) => {
      let yoff = 0;
      for (let y = 0; y < this.rows; y++) {
        let xoff = 0;
        for (let x = 0; x < this.cols; x++) {
          let index = x + y * this.cols;
          let angle = p.noise(xoff, yoff, this.zoff) * p.TWO_PI * 4;
          let v = p5.Vector.fromAngle(angle);
          v.setMag(1);
          this.flowField[index] = v;
          xoff += 0.1;
        }
        yoff += 0.1;
      }
      this.zoff += 0.01;
    };
  };

  render() {
    return <div ref={this.myRef}></div>;
  }
}

class Particle {
  constructor(x, y, isTextParticle, path, p, scale, cols) {
    this.home = p.createVector(x, y); // Home position is the text point
    this.pos = p.createVector(x, y);
    this.vel = p.createVector();
    this.acc = p.createVector();
    this.prevPos = this.pos.copy(); // Ensure prevPos is initialized here
    this.isTextParticle = isTextParticle;
    this.maxSpeed = isTextParticle ? 0.8 : p.random(0.1, 1);
    this.maxForce = isTextParticle ? 10 : p.random(0.5, 3);
    this.color = !isTextParticle ? p.color(200, 200, 50, 25) : p.color(260, 300, 300, 25);
    this.path = path;
    this.scale = scale;
    this.cols = cols;
  }
  

  run(p) {
    this.update(p);
    // Ensure updatePrev is called just after updating the position and before drawing the line.
    this.updatePrev(p);
    this.edges(p);
    this.show(p);
  }

  behaviors(p, flowField) {
    if (this.isTextParticle) {
      this.wander(p); // Add subtle motion for text particles
      this.returnToHome(p); // Keep text particles close to their home position
      this.avoidMouse(p);
      this.textupdateColor(p);
    } else {
      this.updateColor(p);
      this.avoidMouse(p);
      this.follow(flowField, p);
    }
  }


  shimmerEffect(p) {
    let mouse = p.createVector(p.mouseX, p.mouseY);
    let d = p.dist(p.mouseX, p.mouseY, this.pos.x, this.pos.y);
    let force = (100 / (d * d)); // Inverse square law

    // Map the force to a range for the hue
    let hue = p.map(force, 0, 0.01, 240, 345, true); // Blue to Pink-Red range

    // Adjust saturation and brightness
    let saturation = 95;
    let brightness = 100;

    this.color = p.color(hue, saturation, brightness);
  }

  updateColor(p) {
    let d = p.dist(p.mouseX, p.mouseY, this.pos.x, this.pos.y);

    if (d < 150) { // Close to the mouse
      let force = 100 / (d * d); // Inverse square law
      let hue = p.map(force, 0, 0.01, 240, 320, true); // Blue to Pink-Red range

      this.color = p.color(hue, 95, 100);
    } else { // Far from the mouse
      let hue = p.map(d, 0, p.width, 220, 350);
      this.color = p.color(hue, 80, 100);
    }
  }


  textupdateColor(p) {
    let d = p.dist(p.mouseX, p.mouseY, this.pos.x, this.pos.y);

    if (d < 150) { // Close to the mouse
      let force = 10 / (d * d); // Inverse square law
      let hue = p.map(force, 0, 0.01, 220, 260, true); // Darker blue range

      this.color = p.color(hue, 95, 100);
    } else { // Far from the mouse
      let hue = p.map(d, 0, p.width, 220, 260);
      this.color = p.color(hue, 220, 260);
    }
  }


  wander(p) {
    let wanderForce = p5.Vector.random2D().setMag(1*this.maxForce);
    this.applyForce(wanderForce);
  }

  returnToHome(p) {
    let homeForce = p5.Vector.sub(this.home, this.pos);
    homeForce.setMag(1*this.maxForce);
    this.applyForce(homeForce);
  }

  follow(vectors, p) {
    let x = p.floor(this.pos.x / this.scale);
    let y = p.floor(this.pos.y / this.scale);
    let index = x + y * this.cols;
    let force = vectors[index];
    this.applyForce(force);
  }

  avoidMouse(p) {
    let mouse = p.createVector(p.mouseX, p.mouseY);
    let dir = p5.Vector.sub(this.pos, mouse);
    let d = dir.mag();
    let strength = 75000 / (d * d); // Inverse square law
    dir.normalize();
    dir.mult(strength);
    this.applyForce(dir);
  }

  arrive(target, p) {
    let desired = p5.Vector.sub(target, this.pos);
    let d = desired.mag();
    let speed = this.maxSpeed;
    if (d < 100) {
      speed = p.map(d, 0, 100, 0, this.maxSpeed);
    }
    desired.setMag(speed);
    let steer = p5.Vector.sub(desired, this.vel);
    steer.limit(this.maxForce);
    return steer;
  }

  applyForce(force) {
    this.acc.add(force);
  }

  update(p) {
    this.vel.add(this.acc);
    this.vel.limit(this.maxSpeed);
    this.pos.add(this.vel);
    this.acc.mult(0);
  }

  updatePrev(p) {
    this.prevPos.x = this.pos.x;
    this.prevPos.y = this.pos.y;
  }

  show(p) {
    p.stroke(this.color);
    p.strokeWeight(this.isTextParticle ? 6 : 5);
    p.line(this.pos.x, this.pos.y, this.prevPos.x, this.prevPos.y);
  }

  edges(p) {
    if (this.pos.x > p.width) {
      this.pos.x = 0;
      this.updatePrev(p);
    }
    if (this.pos.x < 0) {
      this.pos.x = p.width;
      this.updatePrev(p);
    }
    if (this.pos.y > p.height) {
      this.pos.y = 0;
      this.updatePrev(p);
    }
    if (this.pos.y < 0) {
      this.pos.y = p.height;
      this.updatePrev(p);
    }
  }
  
}

export default FlowFieldWords;
import React, { useState, useEffect, useRef } from 'react';
import './Placeholder.css';
import './CustomWaitingList.css';
import './Science.css'
import FlowFieldWords from './flow_full';
import { useNavigate } from 'react-router-dom';






// Placeholder component for Science
const Science = () => {
  const navigate = useNavigate();
  const [initialNavigationDone, setInitialNavigationDone] = useState(false);

    const styles = {
        container: {
          color: '#333',
          backgroundColor: '#f8f8f8',
          padding: '20px',
          maxWidth: '80vw',
          margin: '40px auto',
          borderRadius: '8px',
          boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
          fontFamily: '"Helvetica Neue", Helvetica, Arial, sans-serif'
        },
        header: {
          color: '#2c3e50',
          textAlign: 'center',
          marginBottom: '30px'
        },
        section: {
          background: '#fff',
          position: 'center',
          maxWidth: '80vw',
          margin: 'auto',
          padding: '20px',
          borderRadius: '8px',
          marginBottom: '20px',
          borderLeft: '5px solid #3498db',
        },
        subHeader: {
          color: '#2c3e50',
          fontSize: '22px',
          fontWeight: '600',
          marginBottom: '10px',
          borderBottom: '2px solid #3498db',
          paddingBottom: '5px',
        },
        paragraph: {
          color: '#555',
          fontSize: '16px',
          lineHeight: '1.6',
          textAlign: 'justify',
          maxWidth: '80vw',
          marginBottom: '15px',
        },
        list: {
            listStyle: 'none', // Remove default list style
            paddingLeft: '0',  // Align with the content
            marginLeft: '0',   // Align with the content
            listStyleType: 'disc', // This will ensure items are shown with bullet points
            marginLeft: '20px',    // Proper indentation for list items
            padding: '0',
          },
          listItem: {
            marginBottom: '15px',
            paddingLeft: '20px', // Space for custom bullet
            position: 'relative',
            fontSize: '16px',
            lineHeight: '1.6',
            color: '#555',
            '::before': {
              content: '"•"',  // Custom bullet symbol
              color: '#3498db', // Bullet color
              fontWeight: 'bold', // Makes the bullet bolder
              display: 'inline-block', 
              width: '20px', // Fixed width for alignment
              marginLeft: '-20px', // Align bullet within the flow
            }
          },
        reference: {
          color: '#3498db',
          textDecoration: 'none',
          fontSize: '0.85em',
          marginLeft: '5px',
          position: 'relative',
          top: '-2px',
        },
        referenceNumber: {
          fontWeight: 'bold',
          color: '#34495e',
          fontSize: '0.7em',
          position: 'relative',
          top: '-5px',
          left: '2px',
        },
        link: {
          color: '#3498db',
          textDecoration: 'none',
          fontWeight: '600',
          ':hover': {
            textDecoration: 'underline',
          }
        },
    }
      


  return (
    <div>
    <FlowFieldWords />

    <div style={styles.container}>
      <div style={styles.section}>
        <h2 style={styles.subHeader}>What we do</h2>
        <p style={styles.paragraph}>
          Our technology platform is developing world-class, data-rich solutions to inform disease prevention, improve healthcare, and accelerate therapeutics development.
        </p>
      </div>

      <div style={styles.section}>
        <h2 style={styles.subHeader}>Our Focus</h2>
        <p style={styles.paragraph}>
          We concentrate on addressing the complex nature of most diseases which are polygenic, as opposed to monogenic. Our approach encompasses a comprehensive analysis rather than a per-gene method, unlocking clinically actionable insights. We aim to revolutionize clinical implementation of polygenic scores and provide patients and physicians with accurate, trustworthy options.
        </p>
      </div>

      <div style={styles.section}>
        <h2 style={styles.subHeader}>The Problem</h2>
        <ul style={styles.list}>
          <li style={styles.listItem}>Lack of comprehensive polygenic testing in clinical practice.</li>
          <li style={styles.listItem}>Ineffective clinical risk estimation and drug trial strategies.</li>
          <li style={styles.listItem}>Inadequate pharmaceutical targeting focusing on single genes.</li>
          <li style={styles.listItem}>Consumer options for quality polygenic scores are limited.</li>
        </ul>
      </div>
  
      <div style={styles.section}>
        <h2 style={styles.subHeader}>Our Solution</h2>
        <p style={styles.paragraph}>
          PolyCypher develops advanced polygenic models of disease, enabling accurate identification of high-risk individuals and in-depth disease modeling.
        </p>
      </div>
  
      <div style={styles.section}>
        <h2 style={styles.subHeader}>Opportunity</h2>
        <p style={styles.paragraph}>
          The genomic revolution is ushering in a new era of precision medicine. With the abundance of DNA sequencing and molecular data, the need for polygenic modeling in understanding complex diseases is more significant than ever. Our advanced polygenic models will redefine the approach to common diseases and precision medicine.
        </p>
      </div>
    </div>

    <section className="section-with-background">
  <h2 style={{ textAlign: 'center' }}><b>Learn About Polygenic Scores</b></h2>
  <div className="card-container" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', flexWrap: 'wrap', gap: '20px' }}>
    <a className="insight-card" href="/how-gwas-works" style={{ flex: '1 0 200px' }}>
      <h3>How does a GWAS work?</h3>
      <p>Dive into the mechanics of Genome-Wide Association Studies and understand the foundational work behind polygenic scores.</p>
    </a>
    <a className="insight-card" href="/current-gwas-trends" style={{ flex: '1 0 200px' }}>
      <h3>Current GWAS trends</h3>
      <p>Stay updated with the latest trends and advancements in GWAS that are shaping the future of genetic research.</p>
    </a>
    <a className="insight-card" href="/polygenic-scores-gwas" style={{ flex: '1 0 200px' }}>
      <h3>Polygenic scores from GWAS data</h3>
      <p>Learn how polygenic scores are derived from GWAS data and their implications for personalized medicine.</p>
    </a>
    <a className="insight-card" href="/improving-polygenic-scores" style={{ flex: '1 0 200px' }}>
      <h3>Improving polygenic scores</h3>
      <p>Discover the methodologies and strategies employed to enhance the accuracy and reliability of polygenic scores.</p>
    </a>
    <a className="insight-card" href="/multi-trait-approaches" style={{ flex: '1 0 200px' }}>
      <h3>Multi-trait approaches</h3>
      <p>Explore how combining multiple traits can provide a deeper understanding and improved predictions in complex diseases.</p>
    </a>
    <a className="insight-card" href="/bayesian-approaches" style={{ flex: '1 0 200px' }}>
      <h3>Bayesian approaches</h3>
      <p>Understand the role of Bayesian statistics in the development of more refined polygenic risk scores.</p>
    </a>
    <a className="insight-card" href="/disease-prs-studies" style={{ flex: '1 0 200px' }}>
      <h3>Useful studies regarding disease PRSs</h3>
      <p>Review significant studies that have advanced our understanding of polygenic risk scores in disease prediction and prevention.</p>
    </a>
    <a className="insight-card" href="/terms-concepts" style={{ flex: '1 0 200px' }}>
      <h3>Terms and concepts</h3>
      <p>Familiarize yourself with the key terms and concepts critical to the field of genetic risk scoring.</p>
    </a>
    <a className="insight-card" href="/clinical-perception" style={{ flex: '1 0 200px' }}>
      <h3>Clinical perception of polygenic scores</h3>
      <p>Understand the clinical perspective on polygenic scores and their integration into healthcare practice.</p>
    </a>
    <a className="insight-card" href="/caveats-limitations" style={{ flex: '1 0 200px' }}>
      <h3>Caveats and limitations</h3>
      <p>Examine the challenges and limitations inherent in the current use of polygenic scores and how they may be addressed.</p>
    </a>
  </div>
</section>



  {/* New Section for Polygenic Scores Overview */}
  <div style={styles.section}>
          <h2 style={styles.subHeader}>Polygenic Scores Overview</h2>
          <p style={styles.paragraph}>
            The above section contains information on the technical background and the current state-of-the-art 
            in the application of polygenic scores to disease.
          </p>
          <p style={styles.paragraph}>
            The first step of many polygenic scores is running a GWAS. Polygenic scores can 
            be created by using the per-allele effect sizes as discovered by the GWAS, 
            perhaps optimizing these before creating the score, or by training a model 
            directly on the data itself (increasing accuracy).
            <a href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC4529984/" style={styles.reference}>[1]</a>
          </p>
          <p style={styles.paragraph}>
            The prediction accuracy of a common polygenic score is influenced by three 
            factors: the heritability explained by common SNPs for that disease or complex 
            trait, the adequacy of the GWAS sample size for accurate effect size estimation, 
            and the method's ability to predict accurately. All are necessary.
          </p>

          {/* Secondary Reviews Section */}
          <h2 style={styles.subHeader}>Secondary Reviews of Polygenic Scores</h2>
          <p style={styles.paragraph}>
            These reviews provide a deeper insight into the applications and usefulness 
            of polygenic scores in medicine. They cover various aspects from general theory 
            and methods to specific applications in different medical domains.
          </p>
          <ul style={styles.list}>
            <i>Learn more:</i>
            <li style={styles.listItem}>
                Overview of polygenic score theory and methods, 2013
                <a href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC3605113/" style={styles.reference}> [link]</a>
            </li>
            <li style={styles.listItem}>
                General review of polygenic scores, 2017
                <a href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC5683372/" style={styles.reference}> [link]</a>
            </li>
            <li style={styles.listItem}>
                Usefulness, validation of GWASs, 2017
                <a href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC5501872/" style={styles.reference}> [link]</a>
            </li>
            <li style={styles.listItem}>
                Polygenic scores are relevant in determining who gets prescribed statins, 2017
                <a href="https://www.ahajournals.org/doi/10.1161/circulationaha.116.024436" style={styles.reference}> [link]</a>
            </li>
            <li style={styles.listItem}>
                Polygenic scores can be more clinically useful than monogenic variants, 2018
                <a href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC6128408/" style={styles.reference}> [link]</a>
            </li>
            <li style={styles.listItem}>
                Usefulness of polygenic scores, 2018
                <a href="https://www.nature.com/articles/s41576-018-0018-x#Sec7" style={styles.reference}> [link]</a>
            </li>
            <li style={styles.listItem}>
                Polygenic scores predict some highly complex phenotypes, 2018&nbsp;<a href="https://www.nature.com/articles/nrg.2017.104" style={styles.reference}> [link]</a>
                &nbsp;and&nbsp;
                2022&nbsp;
                <a href="https://www.nature.com/articles/s41588-022-01016-z" style={styles.reference}> [link]</a> 
            </li>
            <li style={styles.listItem}>
                General polygenic score review, 2019
                <a href="https://academic.oup.com/hmg/article/28/R2/R133/5540980" style={styles.reference}> [link]</a>
            </li>
            <li style={styles.listItem}>
                Polygenic scores predict Alzheimer's disease well, 2020
                <a href="https://content.iospress.com/articles/journal-of-alzheimers-disease/jad191233" style={styles.reference}> [link]</a>
            </li>
            <li style={styles.listItem}>
                Polygenic risk scores applied to behavioral sciences, 2021
                <a href="https://www.nature.com/articles/s41380-021-01348-y" style={styles.reference}> [link]</a>
            </li>
            <li style={styles.listItem}>
                Polygenic scores can be made cheaply and may be actionable for heart disease, 2022
                <a href="https://www.annualreviews.org/doi/10.1146/annurev-med-042921-112629#_i9" style={styles.reference}> [link]</a>
            </li>
            <li style={styles.listItem}>
                Polygenic scores are slightly useful for predicting ADHD, 2022
                <a href="https://pubmed.ncbi.nlm.nih.gov/35988304/" style={styles.reference}> [link]</a>
            </li>
            <li style={styles.listItem}>
                Polygenic scores can be useful in treatment plans for hyperlipidemia, 2022
                <a href="https://journals.lww.com/co-lipidology/Abstract/2023/04000/The_clinical_utility_of_polygenic_risk_scores_for.4.aspx" style={styles.reference}> [link]</a>
            </li>
            <li style={styles.listItem}>
                Overview of how polygenic scores are created, 2022
                <a href="https://acamh.onlinelibrary.wiley.com/doi/10.1111/jcpp.13611" style={styles.reference}> [link]</a>
            </li>
            <li style={styles.listItem}>
                Polygenic scores can be better than traditional risk factors, consistently improve model performance, 2023
                <a href="https://www.ahajournals.org/doi/10.1161/CIRCGEN.122.003834" style={styles.reference}> [link]</a>
            </li>
            </ul>
        </div>
    </div>)
  }

export default Science;
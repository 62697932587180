import React from 'react';
import { Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';

const participationData = [
  { activity: 'Entered invitation code', percent: '75%' },
  { activity: 'Sent back testing kit', percent: '65%' },
  { activity: 'Viewed results', percent: '60%' },
  { activity: 'Shared results with doctor', percent: '40%' },
  { activity: 'Committed to lifestyle changes', percent: '35%' },
];

const sentimentData = [
  { question: 'Found service useful', percent: '85%' },
  { question: 'Plan to take positive action', percent: '70%' },
  { question: 'Increased trust in insurer', percent: '75%' },
  { question: 'More likely to stay with insurer', percent: '65%' },
  { question: 'Would recommend to others', percent: '60%' },
];

function ParticipationAndSentiment() {
  return (
    <div>
      <Typography variant="h4" gutterBottom>
        Participation & Sentiment Analysis
      </Typography>
      <Typography variant="h6" gutterBottom>
        Degree of Participation
      </Typography>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell style={{ fontWeight: 'bold' }}>Activity</TableCell>
              <TableCell style={{ fontWeight: 'bold' }}>Percent of Cohort</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {participationData.map((row) => (
              <TableRow key={row.activity}>
                <TableCell>{row.activity}</TableCell>
                <TableCell>{row.percent}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Typography variant="h6" gutterBottom style={{ marginTop: '20px' }}>
        Sentiment Analysis
      </Typography>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell style={{ fontWeight: 'bold' }}>Question</TableCell>
              <TableCell style={{ fontWeight: 'bold' }}>Percent of Cohort</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sentimentData.map((row) => (
              <TableRow key={row.question}>
                <TableCell>{row.question}</TableCell>
                <TableCell>{row.percent}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}

export default ParticipationAndSentiment;

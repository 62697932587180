import React from 'react';
import { NavLink } from 'react-router-dom';

const styles = {
  skipLink: {
    position: 'fixed',
    top: '-40px',
    left: '0',
    background: 'black',
    color: 'white',
    padding: '8px 16px',
    zIndex: 999999999999999,
    transition: 'top 0.3s ease-in-out',
    cursor: 'pointer',
    textDecoration: 'none',
    fontWeight: 'bold',
    fontSize: '1em',
    borderRadius: '0 0 5px 5px',
  },
  headerContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '10px',
    background: 'black',
    color: 'white',
    zIndex: 999999999999999,
    width: '100%',
    //height: '10%',
  },
  headerSection: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100%',
  },
  logoContainer: {
    marginRight: '50px',
  },
  logo: {
    inlineSize: '75px',
    blockSize: '66px',
    display: 'block',
  },
  navigationMenu: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  menuList: {
    listStyle: 'none',
    display: 'flex',
    paddingLeft: 0,
    margin: 0,
  },

  menuItem: {
    fontWeight: 'bold',
    fontSize: '1.1em',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    padding: '10px 15px', // Added padding here
    position: 'relative', // Position relative for dropdown positioning
  },
  menuLink: {
    textDecoration: 'none',
    color: 'white',
    padding: '10px 15px',
    zIndex: 999999999999999,
    display: 'block',
    transition: 'color 0.3s, text-decoration 0.3s', // Transition for color and text-decoration
  },
  // Styles for the "Log in" button
  loginButton: {
    textDecoration: 'none',
    color: 'white',
    background: 'transparent',
    border: '2px solid white',
    borderRadius: '5px',
    padding: '10px 20px',
    fontWeight: 'bold',
    fontSize: '1.1em',
    marginLeft: 'auto', // Pushes the button to the far right
    cursor: 'pointer',
    transition: 'background-color 0.3s, border-color 0.3s',
  },
  // Hover styles for the "Log in" button
  loginButtonHover: {
    backgroundColor: '#007bff',
    borderColor: '#007bff',
  },
  // Dropdown menu styles remain the same
  dropdownMenu: {
    position: 'absolute',
    backgroundColor: 'white',
    minWidth: '160px',
    boxShadow: '0px 8px 16px 0px rgba(0,0,0,0.2)',
    zIndex: 999999999999999,
    listStyle: 'none',
    top: '100%',
    left: 0,
  },
  dropdownItem: {
    color: 'black',
    padding: '12px 16px',
    textDecoration: 'none',
    display: 'block',
    transition: 'background-color 0.3s',
  },
  // Hover styles for dropdown items
  dropdownItemHover: {
    backgroundColor: '#f1f1f1',
  },
  // Hover styles for menu links
  menuLinkHoverColor: '#007bff',
  menuLinkHoverTextDecoration: 'underline',
  loginButtonHoverBackgroundColor: '#007bff',
  loginButtonHoverBorderColor: '#007bff',
};





class Header extends React.Component {
  state = {
    isSkipLinkFocused: false,
    isServicesOpen: false,
    isTechnologyOpen: false,
  };

  handleSkipLinkFocus = () => this.setState({ isSkipLinkFocused: true });
  handleSkipLinkBlur = () => this.setState({ isSkipLinkFocused: false });
  toggleServicesDropdown = () => this.setState({ isServicesOpen: !this.state.isServicesOpen });
  toggleTechnologyDropdown = () => this.setState({ isTechnologyOpen: !this.state.isTechnologyOpen });



render() {
  const { isSkipLinkFocused, isServicesOpen, isTechnologyOpen } = this.state;
  return (
    <header style={styles.headerContainer}>
      <a
        href="#content"
        style={{
          ...styles.skipLink,
          top: isSkipLinkFocused ? '0' : '-40px',
        }}
        onFocus={this.handleSkipLinkFocus}
        onBlur={this.handleSkipLinkBlur}
      >
        Skip to content
      </a>
      <div style={styles.headerContainer}>
        <section style={styles.headerSection}>
          <div style={styles.logoContainer}>
            <NavLink to="/">
              <img style={styles.logo} src="https://i.imgur.com/5UFV3aT.png" alt="Logo" />
            </NavLink>
          </div>
          <nav style={styles.navigationMenu}>
            <ul style={styles.menuList}>
             {/* <li style={styles.menuItem} onMouseEnter={this.toggleServicesDropdown} onMouseLeave={this.toggleServicesDropdown}>
               <NavLink
                  to="/#background-section"
                style={styles.menuLink}
                onMouseEnter={(e) => {
                  e.currentTarget.style.color = styles.menuLinkHoverColor;
                  e.currentTarget.style.textDecoration = styles.menuLinkHoverTextDecoration;
                }}
                onMouseLeave={(e) => {
                  e.currentTarget.style.color = 'white';
                  e.currentTarget.style.textDecoration = 'none';
                }}
              >
                Services
              </NavLink>

                {isServicesOpen && (
                  <ul style={styles.dropdownMenu}>
                    <li style={styles.dropdownItem}><NavLink to="/polygenic-health-report">Polygenic Health Report</NavLink></li>
                    <li style={styles.dropdownItem}><NavLink to="/clinical-trial-enrichment">Clinical Trial Enrichment</NavLink></li>
                    <li style={styles.dropdownItem}><NavLink to="/polygenic-gamete-donor-screening">Polygenic Gamete Donor Screening</NavLink></li>
                    <li style={styles.dropdownItem}><NavLink to="/polygenic-modeling">Polygenic Modeling</NavLink></li>
                    <li style={styles.dropdownItem}><NavLink to="/partnerships">Partnerships</NavLink></li>
                  </ul>
                )}
                </li> */}
            {/*  <li style={styles.menuItem} onMouseEnter={this.toggleTechnologyDropdown} onMouseLeave={this.toggleTechnologyDropdown}>
                  <NavLink
                  to="/#background-section"
                  style={styles.menuLink}
                  onMouseEnter={(e) => {
                    e.currentTarget.style.color = styles.menuLinkHoverColor;
                    e.currentTarget.style.textDecoration = styles.menuLinkHoverTextDecoration;
                  }}
                  onMouseLeave={(e) => {
                    e.currentTarget.style.color = 'white';
                    e.currentTarget.style.textDecoration = 'none';
                  }}
                >
                  Technology
                </NavLink>
                {isTechnologyOpen && (
                  <ul style={styles.dropdownMenu}>
                    <li style={styles.dropdownItem}><NavLink to="/science">Science</NavLink></li>
                    <li style={styles.dropdownItem}><NavLink to="/accessibility">Accessibility</NavLink></li>
                    <li style={styles.dropdownItem}><NavLink to="/polygenic-diseases">Polygenic Diseases</NavLink></li>
                    <li style={styles.dropdownItem}><NavLink to="/ML">Machine Learning</NavLink></li>
                  </ul>
                )}
                </li> */}
              <li style={styles.menuItem}>
              <NavLink
                style={styles.menuLink}
                to="/about"
                onMouseEnter={(e) => {
                  e.currentTarget.style.color = styles.menuLinkHoverColor;
                  e.currentTarget.style.textDecoration = styles.menuLinkHoverTextDecoration;
                }}
                onMouseLeave={(e) => {
                  e.currentTarget.style.color = 'white';
                  e.currentTarget.style.textDecoration = 'none';
                }}
              >
                About
              </NavLink>
            </li>
            <li style={styles.menuItem}>
              <NavLink
                style={styles.menuLink}
                to="/contact"
                onMouseEnter={(e) => {
                  e.currentTarget.style.color = styles.menuLinkHoverColor;
                  e.currentTarget.style.textDecoration = styles.menuLinkHoverTextDecoration;
                }}
                onMouseLeave={(e) => {
                  e.currentTarget.style.color = 'white';
                  e.currentTarget.style.textDecoration = 'none';
                }}
              >
                Contact
              </NavLink>
            </li>
          </ul>
            <NavLink
            to="/waiting-list"
            style={styles.loginButton}
            onMouseEnter={(e) => {
              e.currentTarget.style.backgroundColor = styles.loginButtonHoverBackgroundColor;
              e.currentTarget.style.borderColor = styles.loginButtonHoverBorderColor;
            }}
            onMouseLeave={(e) => {
              e.currentTarget.style.backgroundColor = 'transparent';
              e.currentTarget.style.borderColor = 'white';
            }}
          >
            Mailing List
          </NavLink>
          </nav>
        </section>
      </div>
    </header>
  );
}
}

export default Header;

import React from 'react';
import { Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';

const diseaseResults = [
  { disease: 'Coronary artery disease', normal: 800, reduced: 150, elevated: 50 },
  { disease: 'Type 2 diabetes', normal: 750, reduced: 200, elevated: 50 },
  { disease: 'Breast cancer', normal: 850, reduced: 100, elevated: 50 },
];

const individualResults = [
  { id: 1, age: 45, sex: 'M', ldl: 130, bmi: 27, risk: 'Elevated' },
  { id: 2, age: 52, sex: 'F', ldl: 110, bmi: 24, risk: 'Normal' },
  { id: 3, age: 38, sex: 'M', ldl: 140, bmi: 30, risk: 'Elevated' },
  { id: 4, age: 60, sex: 'F', ldl: 150, bmi: 31, risk: 'Elevated' },
  { id: 5, age: 42, sex: 'M', ldl: 120, bmi: 26, risk: 'Normal' }
];

function TestResults() {
  return (
    <div>
      <Typography variant="h4" gutterBottom>
        Test Results
      </Typography>
      <Typography variant="h6" gutterBottom>
        Summary of Results by Disease
      </Typography>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell style={{ fontWeight: 'bold' }}>Disease</TableCell>
              <TableCell style={{ fontWeight: 'bold' }}>No. PHs Normal risk</TableCell>
              <TableCell style={{ fontWeight: 'bold' }}>No. PHs Reduced risk</TableCell>
              <TableCell style={{ fontWeight: 'bold' }}>No. PHs Elevated risk</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {diseaseResults.map((row) => (
              <TableRow key={row.disease}>
                <TableCell>{row.disease}</TableCell>
                <TableCell>{row.normal}</TableCell>
                <TableCell>{row.reduced}</TableCell>
                <TableCell>{row.elevated}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Typography variant="h6" gutterBottom style={{ marginTop: '20px' }}>
        Risk Distribution
      </Typography>
      <BarChart width={600} height={300} data={diseaseResults}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="disease" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Bar dataKey="normal" fill="#8884d8" name="Normal Risk" />
        <Bar dataKey="reduced" fill="#82ca9d" name="Reduced Risk" />
        <Bar dataKey="elevated" fill="#ffc658" name="Elevated Risk" />
      </BarChart>
      <Typography variant="h6" gutterBottom style={{ marginTop: '20px' }}>
        Deidentified Individual Results
      </Typography>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell style={{ fontWeight: 'bold' }}>ID</TableCell>
              <TableCell style={{ fontWeight: 'bold' }}>Age</TableCell>
              <TableCell style={{ fontWeight: 'bold' }}>Sex</TableCell>
              <TableCell style={{ fontWeight: 'bold' }}>LDL</TableCell>
              <TableCell style={{ fontWeight: 'bold' }}>BMI</TableCell>
              <TableCell style={{ fontWeight: 'bold' }}>Risk</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {individualResults.map((row) => (
              <TableRow key={row.id}>
                <TableCell>{row.id}</TableCell>
                <TableCell>{row.age}</TableCell>
                <TableCell>{row.sex}</TableCell>
                <TableCell>{row.ldl}</TableCell>
                <TableCell>{row.bmi}</TableCell>
                <TableCell>{row.risk}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}

export default TestResults;

import React, { useState } from 'react';
import { Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, FormControl, InputLabel, Select, MenuItem, FormGroup, TextField, Checkbox, FormControlLabel } from '@mui/material';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';

const patients = [
  { id: 1, age: 45, sex: 'M', cohort: 'High Risk', riskFactors: [
    { factor: 'LDL Cholesterol', initial: 'High', current: 'Normal', change: 'Improved' },
    { factor: 'Blood Pressure', initial: 'High', current: 'Normal', change: 'Improved' },
    { factor: 'Physical Activity', initial: 'Low', current: 'Moderate', change: 'Improved' },
    { factor: 'BMI', initial: 'Obese', current: 'Overweight', change: 'Improved' },
    { factor: 'Smoking', initial: 'Yes', current: 'No', change: 'Improved' }
  ], riskTrend: [
    { month: 'Jan', risk: 80 }, { month: 'Feb', risk: 75 }, { month: 'Mar', risk: 70 },
    { month: 'Apr', risk: 65 }, { month: 'May', risk: 60 }, { month: 'Jun', risk: 55 }
  ]},
  { id: 2, age: 52, sex: 'F', cohort: 'Medium Risk', riskFactors: [
    { factor: 'LDL Cholesterol', initial: 'Normal', current: 'Normal', change: 'No Change' },
    { factor: 'Blood Pressure', initial: 'High', current: 'Normal', change: 'Improved' },
    { factor: 'Physical Activity', initial: 'Moderate', current: 'High', change: 'Improved' },
    { factor: 'BMI', initial: 'Normal', current: 'Normal', change: 'No Change' },
    { factor: 'Smoking', initial: 'No', current: 'No', change: 'No Change' }
  ], riskTrend: [
    { month: 'Jan', risk: 60 }, { month: 'Feb', risk: 58 }, { month: 'Mar', risk: 55 },
    { month: 'Apr', risk: 53 }, { month: 'May', risk: 50 }, { month: 'Jun', risk: 48 }
  ]},
  { id: 3, age: 38, sex: 'M', cohort: 'Low Risk', riskFactors: [
    { factor: 'LDL Cholesterol', initial: 'High', current: 'Normal', change: 'Improved' },
    { factor: 'Blood Pressure', initial: 'Normal', current: 'Normal', change: 'No Change' },
    { factor: 'Physical Activity', initial: 'Low', current: 'High', change: 'Improved' },
    { factor: 'BMI', initial: 'Overweight', current: 'Normal', change: 'Improved' },
    { factor: 'Smoking', initial: 'Yes', current: 'No', change: 'Improved' }
  ], riskTrend: [
    { month: 'Jan', risk: 70 }, { month: 'Feb', risk: 65 }, { month: 'Mar', risk: 60 },
    { month: 'Apr', risk: 55 }, { month: 'May', risk: 50 }, { month: 'Jun', risk: 45 }
  ]},
  { id: 4, age: 60, sex: 'F', cohort: 'High Risk', riskFactors: [
    { factor: 'LDL Cholesterol', initial: 'High', current: 'High', change: 'No Change' },
    { factor: 'Blood Pressure', initial: 'High', current: 'Normal', change: 'Improved' },
    { factor: 'Physical Activity', initial: 'Low', current: 'Moderate', change: 'Improved' },
    { factor: 'BMI', initial: 'Obese', current: 'Overweight', change: 'Improved' },
    { factor: 'Smoking', initial: 'No', current: 'No', change: 'No Change' }
  ], riskTrend: [
    { month: 'Jan', risk: 85 }, { month: 'Feb', risk: 82 }, { month: 'Mar', risk: 80 },
    { month: 'Apr', risk: 78 }, { month: 'May', risk: 75 }, { month: 'Jun', risk: 72 }
  ]},
  { id: 5, age: 42, sex: 'M', cohort: 'Medium Risk', riskFactors: [
    { factor: 'LDL Cholesterol', initial: 'Normal', current: 'Normal', change: 'No Change' },
    { factor: 'Blood Pressure', initial: 'High', current: 'Normal', change: 'Improved' },
    { factor: 'Physical Activity', initial: 'Moderate', current: 'High', change: 'Improved' },
    { factor: 'BMI', initial: 'Overweight', current: 'Normal', change: 'Improved' },
    { factor: 'Smoking', initial: 'Yes', current: 'Yes', change: 'No Change' }
  ], riskTrend: [
    { month: 'Jan', risk: 65 }, { month: 'Feb', risk: 63 }, { month: 'Mar', risk: 60 },
    { month: 'Apr', risk: 58 }, { month: 'May', risk: 55 }, { month: 'Jun', risk: 53 }
  ]},
];

const riskFactors = {
  'LDL Cholesterol': ['High', 'Normal', 'Low'],
  'Blood Pressure': ['High', 'Normal', 'Low'],
  'Physical Activity': ['High', 'Moderate', 'Low'],
  'BMI': ['Obese', 'Overweight', 'Normal'],
  'Smoking': ['Yes', 'No']
};

function RiskTracking() {
  const [selectedPatient, setSelectedPatient] = useState(null);
  const [filters, setFilters] = useState({
    patientId: '',
    age: { min: '', max: '' },
    sex: '',
    cohort: '',
    riskFactors: {
      'LDL Cholesterol': { value: '', useInitial: false, useCurrent: true },
      'Blood Pressure': { value: '', useInitial: false, useCurrent: true },
      'Physical Activity': { value: '', useInitial: false, useCurrent: true },
      'BMI': { value: '', useInitial: false, useCurrent: true },
      'Smoking': { value: '', useInitial: false, useCurrent: true }
    }
  });

  const handlePatientSelect = (patient) => {
    setSelectedPatient(patient);
  };

  const handleFilterChange = (category, value, factor = null) => {
    if (factor) {
      setFilters(prev => ({
        ...prev,
        riskFactors: {
          ...prev.riskFactors,
          [factor]: {
            ...prev.riskFactors[factor],
            value: value
          }
        }
      }));
    } else {
      setFilters(prev => ({ ...prev, [category]: value }));
    }
  };

  const handleCheckboxChange = (factor, type) => {
    setFilters(prev => ({
      ...prev,
      riskFactors: {
        ...prev.riskFactors,
        [factor]: {
          ...prev.riskFactors[factor],
          [type]: !prev.riskFactors[factor][type]
        }
      }
    }));
  };

  const filteredPatients = patients.filter(patient => {
    return (
      (filters.patientId === '' || patient.id.toString().includes(filters.patientId)) &&
      (filters.age.min === '' || patient.age >= parseInt(filters.age.min)) &&
      (filters.age.max === '' || patient.age <= parseInt(filters.age.max)) &&
      (filters.sex === '' || patient.sex === filters.sex) &&
      (filters.cohort === '' || patient.cohort === filters.cohort) &&
      Object.entries(filters.riskFactors).every(([factor, status]) => {
        const patientFactor = patient.riskFactors.find(rf => rf.factor === factor);
        return (
          patientFactor &&
          (status.value === '' || 
           (status.useInitial && patientFactor.initial === status.value) ||
           (status.useCurrent && patientFactor.current === status.value))
        );
      })
    );
  });

  return (
    <div>
      <Typography variant="h4" gutterBottom>
        Risk Tracking
      </Typography>
      <Paper style={{ padding: '20px', marginBottom: '20px' }}>
        <Typography variant="h6" gutterBottom>
          Filters
        </Typography>
        <FormGroup row>
          <TextField
            label="Patient ID"
            value={filters.patientId}
            onChange={(e) => handleFilterChange('patientId', e.target.value)}
            style={{ marginRight: '20px' }}
          />
          <TextField
            label="Min Age"
            type="number"
            value={filters.age.min}
            onChange={(e) => handleFilterChange('age', { ...filters.age, min: e.target.value })}
            style={{ marginRight: '20px' }}
          />
          <TextField
            label="Max Age"
            type="number"
            value={filters.age.max}
            onChange={(e) => handleFilterChange('age', { ...filters.age, max: e.target.value })}
            style={{ marginRight: '20px' }}
          />
          <FormControl style={{ minWidth: 120, marginRight: '20px' }}>
            <InputLabel>Sex</InputLabel>
            <Select
              value={filters.sex}
              onChange={(e) => handleFilterChange('sex', e.target.value)}
            >
              <MenuItem value="">All</MenuItem>
              <MenuItem value="M">Male</MenuItem>
              <MenuItem value="F">Female</MenuItem>
            </Select>
          </FormControl>
          <FormControl style={{ minWidth: 120 }}>
            <InputLabel>Cohort</InputLabel>
            <Select
              value={filters.cohort}
              onChange={(e) => handleFilterChange('cohort', e.target.value)}
            >
              <MenuItem value="">All</MenuItem>
              <MenuItem value="High Risk">High Risk</MenuItem>
              <MenuItem value="Medium Risk">Medium Risk</MenuItem>
              <MenuItem value="Low Risk">Low Risk</MenuItem>
            </Select>
          </FormControl>
        </FormGroup>
        <FormGroup row style={{ marginTop: '20px' }}>
          {Object.entries(riskFactors).map(([factor, options]) => (
            <FormControl key={factor} style={{ minWidth: 120, marginRight: '20px' }}>
              <InputLabel>{factor}</InputLabel>
              <Select
                value={filters.riskFactors[factor].value}
                onChange={(e) => handleFilterChange('riskFactors', e.target.value, factor)}
              >
                <MenuItem value="">All</MenuItem>
                {options.map(option => (
                  <MenuItem key={option} value={option}>{option}</MenuItem>
                ))}
              </Select>
              <FormGroup row>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={filters.riskFactors[factor].useInitial}
                      onChange={() => handleCheckboxChange(factor, 'useInitial')}
                      size="small"
                    />
                  }
                  label="Initial"
                  style={{ color: 'black' }}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={filters.riskFactors[factor].useCurrent}
                      onChange={() => handleCheckboxChange(factor, 'useCurrent')}
                      size="small"
                    />
                  }
                  label="Current"
                  style={{ color: 'black' }}
                />
              </FormGroup>
            </FormControl>
          ))}
        </FormGroup>
      </Paper>
       <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell style={{ fontWeight: 'bold' }}>Patient ID</TableCell>
              <TableCell style={{ fontWeight: 'bold' }}>Age</TableCell>
              <TableCell style={{ fontWeight: 'bold' }}>Sex</TableCell>
              <TableCell style={{ fontWeight: 'bold' }}>Cohort</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredPatients.map((patient) => (
              <TableRow
                key={patient.id}
                onClick={() => handlePatientSelect(patient)}
                style={{
                  cursor: 'pointer',
                  backgroundColor: selectedPatient && selectedPatient.id === patient.id ? '#e0e0e0' : 'transparent'
                }}
              >
                <TableCell>{patient.id}</TableCell>
                <TableCell>{patient.age}</TableCell>
                <TableCell>{patient.sex}</TableCell>
                <TableCell>{patient.cohort}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {selectedPatient && (
        <div style={{ marginTop: '20px' }}>
          <Typography variant="h6" gutterBottom>
            Risk Factors for Patient {selectedPatient.id}
          </Typography>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell style={{ fontWeight: 'bold' }}>Risk Factor</TableCell>
                  <TableCell style={{ fontWeight: 'bold' }}>Initial Status</TableCell>
                  <TableCell style={{ fontWeight: 'bold' }}>Current Status</TableCell>
                  <TableCell style={{ fontWeight: 'bold' }}>Change</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {selectedPatient.riskFactors.map((row) => (
                  <TableRow key={row.factor}>
                    <TableCell>{row.factor}</TableCell>
                    <TableCell>{row.initial}</TableCell>
                    <TableCell>{row.current}</TableCell>
                    <TableCell>{row.change}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Typography variant="h6" gutterBottom style={{ marginTop: '20px' }}>
            Risk Trend Over Time
          </Typography>
          <LineChart width={600} height={300} data={selectedPatient.riskTrend}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="month" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Line type="monotone" dataKey="risk" stroke="#8884d8" name="Risk Score" />
          </LineChart>
        </div>
      )}
    </div>
  );
}

export default RiskTracking;

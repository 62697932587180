import React from 'react';
import { Drawer, List, ListItem, ListItemText, Box } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';

const drawerWidth = 240;

const menuItems = [
  { text: 'Dashboard', path: '/demo' },
  { text: 'Tests and Disease', path: '/demo/tests-and-disease' },
  { text: 'Cohorts for Testing', path: '/demo/cohorts' },
  { text: 'Participation & Sentiment', path: '/demo/participation' },
  { text: 'Test Results', path: '/demo/results' },
  { text: 'Risk Tracking', path: '/demo/risk-tracking' },
  { text: 'Surveys', path: '/demo/surveys' },
  { text: 'Health Learning', path: '/demo/health-learning' },
];

function Sidebar() {
  const location = useLocation();

  return (
    <Drawer
      variant="permanent"
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        position: 'relative',
        '& .MuiDrawer-paper': {
          width: drawerWidth,
          boxSizing: 'border-box',
          position: 'relative',
          height: '100%',
        },
      }}
    >
      <Box sx={{ overflow: 'auto' }}>
        <List>
          {menuItems.map((item) => (
            <ListItem
              button
              key={item.text}
              component={Link}
              to={item.path}
              selected={location.pathname === item.path}
              sx={{
                backgroundColor: location.pathname === item.path ? '#e0e0e0' : 'transparent',
              }}
            >
              <ListItemText primary={item.text} />
            </ListItem>
          ))}
        </List>
      </Box>
    </Drawer>
  );
}

export default Sidebar;

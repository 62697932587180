import React, { useEffect, useRef } from 'react';
import './About.css';
import * as THREE from 'three';
import { UnrealBloomPass } from 'three/examples/jsm/postprocessing/UnrealBloomPass.js';
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer.js';
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass.js';
import { ShaderPass } from 'three/examples/jsm/postprocessing/ShaderPass.js';

const FounderCard = ({ name, role, image, description, id, delay }) => {
  const [isVisible, setIsVisible] = React.useState(false);

  const handleScroll = () => {
    const card = document.getElementById(id);
    const rect = card.getBoundingClientRect();
    if (rect.top <= window.innerHeight && rect.bottom >= 0) {
      setTimeout(() => {
        setIsVisible(true);
      }, delay);
    }
  };

    React.useEffect(() => {
      handleScroll();  // check visibility on mount
      window.addEventListener('scroll', handleScroll);
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }, []);


  return (
    <div id={id} className={`founder-card ${isVisible ? 'visible' : ''}`} style={{ transitionDelay: `${delay}ms` }}>
      <img src={image} alt={name} />
      <h3 style={{ color: 'white' }}>{name}</h3>
      <h4>{role}</h4>
      <p>{description}</p>
    </div>
  );
};

const About = () => {
  const threeContainer = useRef(null);

  useEffect(() => {
    let mouseX = 0, mouseY = 0;
    const scene = new THREE.Scene();
    const camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000);
    const renderer = new THREE.WebGLRenderer({ alpha: true });
    renderer.setSize(window.innerWidth, window.innerHeight);

    threeContainer.current.appendChild(renderer.domElement);

    const renderPass = new RenderPass(scene, camera);
    const bloomPass = new UnrealBloomPass(new THREE.Vector2(window.innerWidth, window.innerHeight), 1.5, 0.4, 0.85);
    bloomPass.threshold = 0;
    bloomPass.strength = 1.5;
    bloomPass.radius = 0;

    const composer = new EffectComposer(renderer);
    composer.addPass(renderPass);
    composer.addPass(bloomPass);

    const blurShader = {
      uniforms: {
        "tDiffuse": { value: null },
        "uBlur": { value: 1.0 }
      },
      vertexShader: `
        varying vec2 vUv;
        void main() {
          vUv = uv;
          gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );
        }
      `,
      fragmentShader: `
        uniform sampler2D tDiffuse;
        uniform float uBlur;
        varying vec2 vUv;
        void main() {
          vec4 sum = vec4(0.0);
          float weights[9];
          weights[0] = 0.05; weights[1] = 0.09; weights[2] = 0.12;
          weights[3] = 0.15; weights[4] = 0.16; weights[5] = 0.15;
          weights[6] = 0.12; weights[7] = 0.09; weights[8] = 0.05;

          for (int i = -1; i <= 2; i++) {
              float weight = weights[i + 7];
              vec2 offset = vec2(float(i) * uBlur);
              sum += texture2D(tDiffuse, vUv + offset) * weight;
          }

          gl_FragColor = sum;
      }
      `
    };

    const blurPass = new ShaderPass(blurShader);
    blurPass.uniforms["uBlur"].value = 0.05;
    composer.addPass(blurPass);

    renderer.setSize(window.innerWidth, window.innerHeight);

    threeContainer.current.appendChild(renderer.domElement);

    // Particles
    const geometry = new THREE.BufferGeometry();
    const vertices = [];
    const sizes = [];
    const colors = [];

    for (let i = 0; i < 50000; i++) {
      const x = (Math.random() - 0.5) * 2000;
      const y = (Math.random() - 0.5) * 2000;
      const z = (Math.random() - 0.5) * 2000;
      vertices.push(x, y, z);
      sizes.push(Math.random() * 10);
      // Color control: Blue, Purple, Pink
      const color = new THREE.Color();
      const colorOptions = [
        0x0000FF, // Blue
        0x00F0FF, // Blue
        0x800080, // Purple
        0xFF78CC  // Pink
      ];
      color.setHex(colorOptions[Math.floor(Math.random() * colorOptions.length)]);
      colors.push(color.r, color.g, color.b);
      }

    geometry.setAttribute('position', new THREE.Float32BufferAttribute(vertices, 3));
    geometry.setAttribute('size', new THREE.Float32BufferAttribute(sizes, 1));
    geometry.setAttribute('color', new THREE.Float32BufferAttribute(colors, 3));

    const material = new THREE.PointsMaterial({ size: 5, vertexColors: true, sizeAttenuation: true });
    const particles = new THREE.Points(geometry, material);
    scene.add(particles);

    camera.position.z = 1000;

    const onDocumentMouseMove = (event) => {
      mouseX = (event.clientX / window.innerWidth) * 2 - 1;
      mouseY = -(event.clientY / window.innerHeight) * 2 + 1;
    };

    document.addEventListener('mousemove', onDocumentMouseMove, false);

    const animate = () => {
      requestAnimationFrame(animate);

      particles.rotation.x += 0.0001;
      particles.rotation.y += 0.0001;

      // Smooth Scroll and Mouse move
      particles.rotation.x += mouseY * 0.0002;
      particles.rotation.y += mouseX * 0.0002;

      composer.render();
    };

      // Create a WebGL renderer for the shader
      const shaderRenderer = new THREE.WebGLRenderer({ alpha: true });
      shaderRenderer.setSize(window.innerWidth, window.innerHeight);

      // Create a new scene and camera for the shader
      const shaderScene = new THREE.Scene();
      const shaderCamera = new THREE.PerspectiveCamera(45, window.innerWidth / window.innerHeight, 1, 1000);
      shaderCamera.position.z = 1;

      // Define the custom shader material
      const shaderMaterial = new THREE.ShaderMaterial({
        uniforms: {
          time: { value: 0.0 },
        },
        transparent: true,

        vertexShader: `
        varying vec2 vUv;
        void main() {
          vUv = uv;
          gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );
        }
      `,

      fragmentShader: `
        uniform float time;
        uniform float cardID;  // Unique ID for each card
        varying vec2 vUv;

        float random(vec2 st, float seed) {
          return fract(sin(dot(st.xy, vec2(12.9898, 78.233) + seed)) * 43758.5453123);
        }

        void main() {
          vec2 uv = vUv * 10.0;
          float randSeed = random(uv, time + cardID);  // Unique for each card

          // Increased granularity
          float x = uv.x + randSeed * 0.5;
          float y = uv.y + randSeed * 0.5;
          float wave = sin(x + time) * cos(y + time);
          float blendWave = smoothstep(-0.5, 0.5, wave);

          vec3 color1 = vec3(0.0, 0.0, 1.0);  // Blue
          vec3 color2 = vec3(0.5, 0.0, 0.5);  // Purple
          vec3 color3 = vec3(1.0, 0.0, 1.0);  // Pink

          vec3 finalColor = mix(color1, color2, blendWave);
          finalColor = mix(finalColor, color3, 1.0 - blendWave);

          // Add noise for analog film grain
          float noise = random(vUv, time) * 0.1;
          finalColor += noise;

          gl_FragColor = vec4(finalColor, 0.05);
        }
      `


      });

      // Create mesh and add it to the shader scene
      const shaderGeometry = new THREE.PlaneGeometry(2, 2);
      const shaderMesh = new THREE.Mesh(shaderGeometry, shaderMaterial);
      shaderScene.add(shaderMesh);

      // Insert the shader canvas into each .founder-card
      document.querySelectorAll('.founder-card').forEach((card) => {
        const newShaderRenderer = new THREE.WebGLRenderer({ alpha: true });
        newShaderRenderer.setSize(window.innerWidth, window.innerHeight);
        card.appendChild(newShaderRenderer.domElement);
        const newShaderMaterial = shaderMaterial.clone();
        const newShaderMesh = new THREE.Mesh(shaderGeometry, newShaderMaterial);
        shaderScene.add(newShaderMesh);

        const animateNewShader = () => {
          requestAnimationFrame(animateNewShader);
          newShaderMaterial.uniforms.time.value += 0.01;
          newShaderRenderer.render(shaderScene, shaderCamera);
        };
        animateNewShader();
      });

    animate();
  }, []);

  return (
      <div className="about">
      <div className="background"></div>  {/* Background layer */}
      <div ref={threeContainer} className="three-container" />  {/* Three.js container */}
      <div className="nonBlurTitle">About Us</div>  {/* Non-blurred title */}
      <div className="content">
      <div class="company-info">
        <p>
          We're building a healthcare technology platform to deliver our machine learning-derived polygenic models in order to create a new generation of preventive healthcare.
        </p>
      </div>
        <div className="founder-container">
        <FounderCard
          id="founder-2"
          name="Edward Messick"
          role="CTO"
          image="./Edward.png"
          description="Bioinformatics scientist and engineer with a decade of experience working on the cutting edge
          of precision medicine, pharma, bioinformatics, and biotech. Edward worked in the clinical trial industry at
          IQVIA and Elusys, on genomics platforms and pipelines at Gritstone Oncology, Arcaea, and GENEWIZ. At
          Genomic Prediction, he pioneered the application of polygenic scores to a clinical setting. His
          educational background is in cell biology, neuroscience, bioinformatics, and computer science."
          delay={180}
        />
        <FounderCard
          id="founder-1"
          name="Scott Sauers"
          role="COO"
          image="./cropped-Scott_remove.png"
          description="Data scientist, computational biologist, and researcher. While studying Alzheimer's disease at Washington
          University’s Neurology Department, he built a pipeline to calculate thousands of polygenic scores, and
          delivered the first real-world polygenic screenings for dozens of different diseases. He worked in neuropsychological diagnostics before building
          PolyCypher, and since created numerous genomics, machine learning, and language modeling scientific pipelines,
          including genotype imputation, an LLM for health records,  and genomic motif identifiers."
          delay={0}
        />
        <FounderCard
          id="founder-3"
          name="Gilead Kedem, MD"
          role="CEO"
          image="./Gilead_remove.png"
          description="Biotech executive, physician-scientist and bioinformatician, with 20 years of biotech industry
          experience in biology, precision medicine, and technology. Gilead pursued graduate studies in biomedical engineering at Johns Hopkins University and earned an M.D.
          from the Sackler School of Medicine at Tel Aviv University. After spearheading bioinformatics R&D at GENEWIZ,
          Gilead worked in business development and drug target validation, from Bioinformatics VP at Fortress
          Biotech to Bioinformatics Director at healthcare investment firm Deerfield Management."
          delay={250}
        />
      </div>
      </div>
    </div>
  );
};

export default About;
import React, { useState } from 'react';
import { Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@mui/material';

const diseases = [
  { name: 'Coronary artery disease', usIncidence: '5%', usPrevalence: '10%', insurerIncidence: '4%', insurerPrevalence: '8%' },
  { name: 'Hypercholesterolemia', usIncidence: '3%', usPrevalence: '7%', insurerIncidence: '2%', insurerPrevalence: '6%' },
  { name: 'Atrial fibrillation', usIncidence: '2%', usPrevalence: '4%', insurerIncidence: '1%', insurerPrevalence: '3%' },
];

function TestsAndDisease() {
  const [openDialog, setOpenDialog] = useState(false);
  const [submissionTime, setSubmissionTime] = useState(null);

  const handleCreateTest = () => {
    setOpenDialog(true);
    const currentTime = new Date().toLocaleString();
    setSubmissionTime(currentTime);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  return (
    <div>
      <Typography variant="h4" gutterBottom>
        Tests and Disease Information
      </Typography>
      <Typography variant="body1" paragraph>
        This tab describes the Standard PolyCypher Test, covering the diseases and statistics for the US and insurer's policyholder base.
      </Typography>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell style={{ fontWeight: 'bold' }}>Disease</TableCell>
              <TableCell style={{ fontWeight: 'bold' }}>US Incidence</TableCell>
              <TableCell style={{ fontWeight: 'bold' }}>US Prevalence</TableCell>
              <TableCell style={{ fontWeight: 'bold' }}>Insurer Incidence</TableCell>
              <TableCell style={{ fontWeight: 'bold' }}>Insurer Prevalence</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {diseases.map((disease) => (
              <TableRow key={disease.name}>
                <TableCell>{disease.name}</TableCell>
                <TableCell>{disease.usIncidence}</TableCell>
                <TableCell>{disease.usPrevalence}</TableCell>
                <TableCell>{disease.insurerIncidence}</TableCell>
                <TableCell>{disease.insurerPrevalence}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Button variant="contained" color="primary" style={{ marginTop: '20px' }} onClick={handleCreateTest}>
        Create Customized Risk Test
      </Button>
      {submissionTime && (
        <Typography variant="body2" style={{ marginTop: '10px' }}>
          Submitted at: {submissionTime}
        </Typography>
      )}
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>Request Submitted</DialogTitle>
        <DialogContent>
          <DialogContentText>
            PolyCypher has been contacted regarding your request for a customized risk test. Our team will review your request and get back to you shortly.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default TestsAndDisease;

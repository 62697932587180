import React, { useEffect } from 'react';
import './About.css';

const FounderCard = ({ name, role, image, description, id, delay }) => {
  const [isVisible, setIsVisible] = React.useState(false);

  const handleScroll = () => {
    const card = document.getElementById(id);
    const rect = card.getBoundingClientRect();
    if (rect.top <= window.innerHeight && rect.bottom >= 0) {
      setTimeout(() => {
        setIsVisible(true);
      }, delay);
    }
  };

  React.useEffect(() => {
    handleScroll();
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div id={id} className={`founder-card ${isVisible ? 'visible' : ''}`} style={{ transitionDelay: `${delay}ms` }}>
      <img src={image} alt={name} />
      <h3>{name}</h3>
      <h4>{role}</h4>
      <p>{description}</p>
    </div>
  );
};

const About = () => {
  useEffect(() => {
    // Remove all the THREE.js and particle effect logic
  }, []);

  return (
      <div className="about">
      <div className="background"></div>  {/* Background layer */}
      <div className="nonBlurTitle">About Us</div>  {/* Non-blurred title */}
      <div className="content">
      <div class="company-info">
        <p>
          We're building a healthcare technology platform to deliver our machine learning-derived polygenic models to clinicians in order to create a new generation of preventive healthcare.
        </p>
      </div>
        <div className="founder-container">
        <FounderCard
          id="founder-2"
          name="Edward Messick"
          role="CTO"
          image="./Edward.png"
          description="Bioinformatics scientist and engineer with a decade of experience working on the cutting edge of precision medicine, pharma, bioinformatics, and biotech. Edward worked in the clinical trial industry at IQVIA and Elusys. He worked on cancer immunotherapies at Gritstone Oncology, and automated bioinformatics pipelines at Arcaea, a cosmetic biotechnology startup. At Genomic Prediction, he pioneered the application of polygenic scores to a clinical setting (IVF). He holds degrees in Cell Biology/Neuroscience (BS, Rutgers) and Bioinformatics/CS (MSc Bioinformatics, NJIT)."
          delay={180}
        />
        <FounderCard
          id="founder-1"
          name="Scott Sauers"
          role="COO"
          image="./cropped-Scott_remove.png"
          description="Data scientist, computational biologist, and researcher studying Alzheimer's disease at Washington University’s Neurology Department. After building a pipeline to calculate thousands of polygenic scores, Scott delivered the first real-world polygenic screenings for dozens of different diseases, and was the first to bring polygenic screening to the gamete donor clinic. He worked in neuropsychological diagnostics before building PolyCypher, and since created numerous genomic machine learning engineering scientific pipelines, including genotype imputation and genomic motif identifiers."
          delay={0}
        />
        <FounderCard
          id="founder-3"
          name="Gilead Kedem"
          role="CEO"
          image="./Gilead_remove.png"
          description="Biotech executive, physician-scientist and bioinformatician, with 20 years of biotech industry experience in biology, precision medicine, and technology. Gilead holds a B.S. in Physics from the University of Maryland, pursued graduate studies in biomedical engineering at Johns Hopkins University and earned an M.D. from the Sackler School of Medicine at Tel Aviv University, Israel. Gilead has worked in R&D, business development, and investing, from Bioinformatics VP at Fortress Biotech to Bioinformatics Director at healthcare investment firm Deerfield Management."
          delay={250}
        />
      </div>
      </div>
    </div>
  );
};

export default About;
import React, { useEffect, useRef } from 'react';
import './DnaMatrixBackground.css';

const DnaMatrixBackground = () => {
  const canvasRef = useRef(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    canvas.width = canvas.parentElement.offsetWidth;
    canvas.height = canvas.parentElement.offsetHeight;

    const letters = ['G', 'C', 'T', 'A'];
    const baseColors = {
      'A': 'hsl(330, 100%, 75%)',
      'G': 'hsl(180, 100%, 40%)',
      'C': 'hsl(240, 100%, 35%)',
      'T': 'hsl(300, 100%, 40%)',
    };
    let particles = [];

    const adjustColor = (baseColor) => {
      const [hue, sat, light] = baseColor.match(/\d+/g).map(Number);
      const randomSat = sat + (Math.random() - 0.5) * 20;
      const randomLight = light + (Math.random() - 0.5) * 10;
      return `hsl(${hue}, ${Math.min(100, Math.max(0, randomSat))}%, ${Math.min(100, Math.max(0, randomLight))}%)`;
    };

    class Particle {
      constructor(fontSize, baseSpeed) {
        this.x = Math.random() * canvas.width;
        this.y = Math.random() * canvas.height;
        this.originalX = this.x;
        this.fontSize = fontSize;
        this.speed = baseSpeed;
        this.letter = letters[Math.floor(Math.random() * letters.length)];
        this.color = adjustColor(baseColors[this.letter]);
        this.opacity = 0.8;
        this.angle = Math.random() * Math.PI * 2;
        this.shouldMoveHorizontally = Math.random() < 0.1;
        this.directionFactor = Math.random() < 0.5 ? -1 : 1;
      }

      draw() {
        ctx.globalAlpha = this.opacity;
        ctx.font = `${this.fontSize}px monospace`;
        ctx.fillStyle = this.color;
        ctx.shadowColor = this.color;
        ctx.shadowBlur = (this.fontSize / 2) * 0.95;
        ctx.fillText(this.letter, this.x, this.y);
        ctx.shadowBlur = 0;
      }

      update() {
        if (this.shouldMoveHorizontally) {
          this.angle += 0.01 * this.directionFactor;
          this.x = this.originalX + Math.sin(this.angle) * (canvas.width * 0.01);
        }
        this.y -= this.speed;
        if (this.y < -this.fontSize) {
          this.y = canvas.height + this.fontSize;
          this.x = Math.random() * canvas.width;
          this.originalX = this.x;
          this.angle = Math.random() * Math.PI * 2;
          this.directionFactor = Math.random() < 0.5 ? -1 : 1;
        }
      }
    }

    // Initialize
    let numberOfParticles = 2200;
    for (let i = 0; i < numberOfParticles; i++) {
      let fontSize, baseSpeed;
      const rand = Math.random();
      if (rand < 0.25) {
        fontSize = 4;
        baseSpeed = 0.15 + Math.random() * 0.2;
      } else if (rand < 0.35) {
        fontSize = 8;
        baseSpeed = 0.2 + Math.random() * 0.2;
      } else if (rand < 0.55) {
        fontSize = 16;
        baseSpeed = 0.4 + Math.random() * 0.4;
      } else if (rand < 0.8) {
        fontSize = 32;
        baseSpeed = 0.8 + Math.random() * 0.6;
      } else if (rand < 0.95) {
        fontSize = 64;
        baseSpeed = 1.6 + Math.random() * 0.8;
      } else {
        fontSize = 96;
        baseSpeed = 2 + Math.random() * 1;
      }
      particles.push(new Particle(fontSize, baseSpeed));
    }

    particles.sort((a, b) => a.fontSize - b.fontSize);

    // Animate
    const animate = () => {
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      particles.forEach(p => {
        p.update();
        p.draw();
      });
      requestAnimationFrame(animate);
    };

    animate();
  }, []);

  return (
    <div className="dnaMatrixBackground">
      <canvas ref={canvasRef} className="dnaMatrixCanvas"></canvas>
    </div>
  );
};

export default DnaMatrixBackground;
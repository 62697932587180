import React, { useState } from 'react';
import { Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, Snackbar } from '@mui/material';

const cohorts = [
  { name: 'Has genotype', description: 'PHs with existing genotypes', policyholders: 1000 },
  { name: 'Likely to have disease', description: 'PHs with high-risk biomarkers', policyholders: 500 },
  { name: 'Hidden risk', description: 'PHs with EHR data suggesting hidden risks', policyholders: 750 },
];

function CohortsForTesting() {
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [inviteTimes, setInviteTimes] = useState({});
  const [snackbarMessage, setSnackbarMessage] = useState('');

  const handleInvite = (cohortName) => {
    if (inviteTimes[cohortName]) {
      setSnackbarMessage(`Invites already sent at ${inviteTimes[cohortName]}`);
    } else {
      const currentTime = new Date().toLocaleString();
      setInviteTimes(prev => ({ ...prev, [cohortName]: currentTime }));
      setSnackbarMessage("Invites successfully sent");
    }
    setSnackbarOpen(true);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  return (
    <div>
      <Typography variant="h4" gutterBottom>
        Cohorts for Testing
      </Typography>
      <Typography variant="body1" paragraph>
        Our platform can create cohorts of policyholders to recommend for PRS testing based on various criteria.
      </Typography>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell style={{ fontWeight: 'bold' }}>Cohort name</TableCell>
              <TableCell style={{ fontWeight: 'bold' }}>Cohort description</TableCell>
              <TableCell style={{ fontWeight: 'bold' }}>No. Policyholders</TableCell>
              <TableCell style={{ fontWeight: 'bold' }}>Invite all</TableCell>
              <TableCell style={{ fontWeight: 'bold' }}>Invite top N PHs</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {cohorts.map((cohort) => (
              <TableRow key={cohort.name}>
                <TableCell>{cohort.name}</TableCell>
                <TableCell>{cohort.description}</TableCell>
                <TableCell>{cohort.policyholders}</TableCell>
                <TableCell>
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    onClick={() => handleInvite(cohort.name)}
                  >
                    Invite All
                  </Button>
                  {inviteTimes[cohort.name] && (
                    <Typography variant="caption" display="block">
                      Sent at: {inviteTimes[cohort.name]}
                    </Typography>
                  )}
                </TableCell>
                <TableCell>
                  <Button
                    variant="contained"
                    color="secondary"
                    size="small"
                    onClick={() => handleInvite(`${cohort.name}-top`)}
                  >
                    Invite Top 100
                  </Button>
                  {inviteTimes[`${cohort.name}-top`] && (
                    <Typography variant="caption" display="block">
                      Sent at: {inviteTimes[`${cohort.name}-top`]}
                    </Typography>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        message={snackbarMessage}
      />
    </div>
  );
}

export default CohortsForTesting;

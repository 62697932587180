import React, { useRef, useEffect, useState } from 'react';
import './Home.css';
import debounce from 'lodash/debounce';
import ParallaxText3D from './Uncover';
import { useNavigate } from 'react-router-dom';





const PolyCypherPage = () => {
  const [cardPositions, setCardPositions] = useState([]);
  const navigate = useNavigate(); // Use useNavigate instead of useHistory

  const updateCardPositions = () => {
    // Select all card elements
    const cards = document.querySelectorAll('.st-card, .st-card');

    const positions = Array.from(cards).map(card => {
      const rect = card.getBoundingClientRect();
      return {
        x: rect.left + window.scrollX,
        y: rect.top + window.scrollY,
        width: rect.width,
        height: rect.height
      };
    });

    // Only update state if positions have actually changed
    setCardPositions(prevPositions => {
      if (JSON.stringify(prevPositions) !== JSON.stringify(positions)) {
        return positions;
      }
      return prevPositions;
    });
  };

  // Debounced version of the update function
  const debouncedUpdateCardPositions = debounce(updateCardPositions, 100);



  const isMobile = () => {
    const userAgent = navigator.userAgent;
    const mobileDevices = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent);
    const screenIsSmall = window.innerWidth <= 650;

    return mobileDevices || screenIsSmall;
  };

  useEffect(() => {
    if (isMobile()) {
      navigate('/mobile-homepage'); // Use navigate instead of history.push
    }
  }, [navigate]);


  useEffect(() => {
    window.addEventListener('resize', debouncedUpdateCardPositions);
    
    // Update card positions after the layout has likely stabilized
    setTimeout(updateCardPositions, 500);

    return () => {
      window.removeEventListener('resize', debouncedUpdateCardPositions);
    };
  }, );




  const sectionRef = useRef(null);

  useEffect(() => {
    const handleMouseMove = (e) => {
      if (sectionRef.current) {
        const { top, bottom, height } = sectionRef.current.getBoundingClientRect();
        let opacityTop;

        if (e.clientY < top) {
          // Mouse is above the section
          opacityTop = 1;
        } else if (e.clientY > bottom) {
          // Mouse is below the section
          opacityTop = 0;
        } else {
          // Mouse is within the section
          const distanceFromTop = e.clientY - top;
          opacityTop = Math.max(0, Math.min(1, 1 - (0.4*distanceFromTop / height)));
        }

        sectionRef.current.style.background = `linear-gradient(rgba(41, 0, 51, ${opacityTop}), rgba(41, 0, 51, 1)), url("your-background-image.jpg")`;
      }
    };

    // Attach mouse move listener to the window
    window.addEventListener('mousemove', handleMouseMove);

    // Cleanup
    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);









  return (
    <div>
        <leadblock className="leadblock">
        <div className="leadblock-content">
        <p className="leadblock-title">
        <s>Genetic</s>&nbsp;<span style={{ fontWeight: 500 }}>Genomic</span>
    <span className="title-emphasis">
        <span className="carousel-words">testing&nbsp;&nbsp; </span>
        <span className="carousel-words">&nbsp;&nbsp;&nbsp;&nbsp;screening</span>
        <span className="carousel-words">&nbsp;&nbsp;&nbsp;analysis&nbsp;&nbsp; </span>
        <span className="carousel-words">&nbsp;&nbsp;&nbsp;&nbsp;modeling&nbsp;&nbsp; </span>
    </span>
      </p>
          <p className="leadblock-subtitle">Ultra in-depth polygenic modeling is necessary to understand and predict disease. We've built a  platform that uses and applies this technology to usher in a new era of precision medicine.
</p>
          <a href="/about" className="leadblock-link">ABOUT POLYCYPHER &rarr;</a>
        </div>
      </leadblock>

      {/* Section with PolyCypher Description */}
      <section style={{ backgroundColor: 'white', textAlign: 'center', paddingTop: '7%', paddingBottom: '7%', paddingLeft: '0px', paddingRight: '0px' }}>
        <img
          decoding="async"
          width="50"
          height="45"
          src="/black_logo.png" // Assuming your image is in the public folder
          alt="PolyCypher Logo"
          loading="lazy"
        />
        <p style={{ color: 'black' }}> {/* Inline style for black text */}
        <section className="main-description">
          <strong>PolyCypher</strong> is pioneering a genomic analysis platform that leverages <b>massive biobanks</b> & machine learning models to forge the <b>absolute best</b> polygenic analysis. We focus on complex, <b>multi-gene</b> diseases that most affect human health.
        </section>
        </p>
      </section>

      {/* */}

      <div style={{ position: 'relative' }}>
      {/* ParallaxText3D */}
      <div style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', zIndex: -1 }}>
        <ParallaxText3D />
      </div>

      {/* Purple section */}
      <section ref={sectionRef} style={{
        color: 'white',
        textAlign: 'center',
        padding: '80px 0',
        position: 'relative',
        zIndex: 1,
      }}>
        <div style={{ position: 'relative', zIndex: 5 }}>
        <h2 class="text-white font-bold">Propelling genetic screening into the 21st century</h2>
        </div>
      </section>
    </div>






    </div>
  );
};

export default PolyCypherPage;
import React, { createContext, useContext, useState, useEffect } from 'react';

const CognitoContext = createContext();

const exchangeCodeForTokens = async (code) => {
    try {
        const domain = 'http://localhost:4000'
        const endpoint = `${domain}/exchangeCode`;
        const response = await fetch(endpoint, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ code })
        });

        if (!response.ok) {
            const errorData = await response.json();
            throw new Error(`Failed to exchange code for tokens. Server returned: ${errorData.error || 'Unknown error'}`);
        }

        const data = await response.json();
        localStorage.setItem('tokens', JSON.stringify({ access_token: data.access_token, email: data.email }));

        if (!data.email) {
            throw new Error("Tokens do not contain the required email attribute");
        }

        return data.email;
    } catch (error) {
        console.error(error);
        throw error;
    }
};

const verifyToken = async (token) => {
    try {
        const response = await fetch('http://localhost:4000/isTokenValid', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ token })
        });

        if (!response.ok) {
            throw new Error('Token verification failed due to server error');
        }

        const data = await response.json();
        return data.isValid;
    } catch (error) {
        console.error('Token verification failed:', error);
        return false;
    }
};

export const CognitoProvider = ({ children }) => {
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [email, setEmail] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const storedTokens = localStorage.getItem('tokens');
        if (storedTokens) {
            const { access_token, email } = JSON.parse(storedTokens);
            verifyToken(access_token).then(isValid => {
                if (isValid) {
                    setIsLoggedIn(true);
                    setEmail(email);
                    //console.log(email)
                    setLoading(false);
                } else {
                    localStorage.removeItem('tokens');
                    setLoading(false);
                }
            }).catch(err => {
                //console.error('Token verification failed:', err);
                localStorage.removeItem('tokens');
                setLoading(false);
            });
        } else {
            const urlParams = new URLSearchParams(window.location.search);
            const code = urlParams.get('code');
            if (code) {
                setLoading(true);
                exchangeCodeForTokens(code).then(email => {
                    setIsLoggedIn(true);
                    setEmail(email);
                    //console.log(email)
                    setLoading(false);
                }).catch(err => {
                    setError(err.message);
                    setLoading(false);
                });
            } else {
                setLoading(false);
            }
        }
    }, []);

    const handleLogout = () => {
        localStorage.removeItem('tokens');
        setIsLoggedIn(false);
        setEmail(null);
    };

    return (
        <CognitoContext.Provider value={{ isLoggedIn, handleLogout, email, error, loading }}>
            {children}
        </CognitoContext.Provider>
    );
};

export const useCognito = () => {
    return useContext(CognitoContext);
};
import React from 'react';
import './MobileHomepage.css';

const MobileHomepage = () => {
  return (
    <div className="polycypher-mobile-container">

      <main className="polycypher-mobile-main">
        <section className="polycypher-mobile-hero">
          <h1 className="polycypher-mobile-hero-title">Precision Health Intelligence</h1>
          <p className="polycypher-mobile-hero-text">PolyCypher Health is pioneering a new era of precision medicine.</p>
          <a href="/about" className="polycypher-mobile-cta-button">Learn More</a>
        </section>

        <section className="polycypher-mobile-info">
          <h2 className="polycypher-mobile-info-title">Genomic Predictive Health</h2>
          <p className="polycypher-mobile-info-text">
            Our platform leverages massive biobanks and machine learning models 
            to forge the absolute best polygenic analysis. We focus on complex, 
            multi-gene diseases that most affect human health.
          </p>
          <img src="/white_logo512.png" alt="PolyCypher White Logo" className="polycypher-mobile-info-logo" />
        </section>

        <section className="polycypher-mobile-team">
          <h2 className="polycypher-mobile-team-title">Our Team</h2>
          <div className="polycypher-mobile-team-grid">
            <div className="polycypher-mobile-team-member">
              <img src="/Edward.png" alt="Edward Messick" className="polycypher-mobile-team-image" />
              <h3 className="polycypher-mobile-team-name">Edward Messick</h3>
              <p className="polycypher-mobile-team-role">CTO</p>
            </div>
            <div className="polycypher-mobile-team-member">
              <img src="/Scott_remove.png" alt="Scott Sauers" className="polycypher-mobile-team-image" />
              <h3 className="polycypher-mobile-team-name">Scott Sauers</h3>
              <p className="polycypher-mobile-team-role">COO</p>
            </div>
            <div className="polycypher-mobile-team-member">
              <img src="/Gilead_remove.png" alt="Gilead Kedem" className="polycypher-mobile-team-image" />
              <h3 className="polycypher-mobile-team-name">Gilead Kedem, MD</h3>
              <p className="polycypher-mobile-team-role">CEO</p>
            </div>
          </div>
        </section>

        <section className="polycypher-mobile-mailing-list">
          <h2 className="polycypher-mobile-mailing-list-title">Stay Informed</h2>
          <p className="polycypher-mobile-mailing-list-text">Join our mailing list for the latest updates in genomic health.</p>
          <form className="polycypher-mobile-subscribe-form">
            <input type="email" placeholder="Your email address" required className="polycypher-mobile-subscribe-input" />
            <button type="submit" className="polycypher-mobile-subscribe-button">Subscribe</button>
          </form>
        </section>
      </main>
    </div>
  );
};

export default MobileHomepage;
import React, { useState, useMemo } from 'react';

import {
  BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer,
  RadarChart, PolarGrid, PolarAngleAxis, PolarRadiusAxis, Radar, ScatterChart, Scatter,
  ZAxis, ReferenceLine, Cell, Label, LabelList
} from 'recharts';
import { ChevronDown, ChevronUp } from 'lucide-react';
import './PolyCypherHealthReport.css';

import { Auth, API, graphqlOperation } from 'aws-amplify';
import { v4 as uuidv4 } from 'uuid';
import { createSurveyResponse } from './graphql/mutations';


const diseases = [
  'Coronary artery disease', 'Type 2 diabetes', 'Stroke', 'Prostate cancer',
  'Colorectal cancer', 'Breast cancer', 'Atrial fibrillation', "Alzheimer's disease",
  'Hypercholesterolemia', 'Obesity / BMI'
];
const useReportData = () => {
  return useMemo(() => {
    return diseases.map(disease => {
      const percentileRisk = Math.floor(Math.random() * 100);
      const relativeRisk = ((percentileRisk / 50) * 1.5).toFixed(2);
      const absoluteRisk = (percentileRisk / 1000).toFixed(3); // Adjusted to be more realistic
      const avgAbsoluteRisk = (Math.random() * 0.05).toFixed(3); // Random average risk between 0-5%
      return {
        disease,
        relativeRisk,
        percentileRisk,
        absoluteRisk,
        avgAbsoluteRisk,
        polygenicRisk: percentileRisk < 33 ? 'Low' : percentileRisk < 66 ? 'Normal' : 'High'
      };
    });
  }, []);
};

const generateNormalDistribution = (mean, stdDev, count) => {
  const data = [];
  for (let i = 0; i < count; i++) {
    let u = 0, v = 0;
    while (u === 0) u = Math.random();
    while (v === 0) v = Math.random();
    let num = Math.sqrt(-2.0 * Math.log(u)) * Math.cos(2.0 * Math.PI * v);
    num = num * stdDev + mean;
    data.push(Math.max(0, Math.min(100, num)));
  }
  return data;
};

const generateHistogramData = (userPercentile) => {
  const data = generateNormalDistribution(50, 15, 1000);
  const buckets = Array(20).fill(0);
  data.forEach(value => {
    const bucketIndex = Math.min(Math.floor(value / 5), 19);
    buckets[bucketIndex]++;
  });

  return buckets.map((count, index) => ({
    range: `${index * 5}-${(index + 1) * 5}`,
    count,
    fill: `rgb(${Math.min(255, Math.max(0, (index - 12) * 51))}, 0, ${Math.max(0, 255 - (index - 12) * 51)})`
  }));
};

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div className="custom-tooltip">
        <p className="label">{`Range: ${label}`}</p>
        <p className="value">{`Count: ${payload[0].value}`}</p>
      </div>
    );
  }
  return null;
};



const CustomLabel = ({ viewBox }) => {
  const { x, y } = viewBox;
  const textOffset = 1; //  center the text

  return (
    <g>
      <text x={x - textOffset} y={y - 10} fill="red" fontSize={14} fontWeight="bold" textAnchor="middle">
        You
      </text>
    </g>
  );
};

const RiskHistogram = ({ disease, relativeRisk, percentileRisk }) => {
  const histogramData = useMemo(() => {
    const data = generateHistogramData(percentileRisk);
    return data.map((item, index) => ({
      ...item,
      x: index * 5 + 2.5  // Center of each bar
    }));
  }, [percentileRisk]);

  return (
    <div className="risk-histogram">
      <h3>{disease}</h3>
      <p>Relative Risk (Odds Ratio): {relativeRisk}</p>
      <ResponsiveContainer width="100%" height={200}>
        <BarChart data={histogramData} margin={{ top: 20, right: 30, left: 30, bottom: 5 }}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="x"
            type="number"
            domain={[0, 100]}
            tickFormatter={(value) => `${Math.floor(value)}`}
          />
          <YAxis hide />
          <Tooltip content={<CustomTooltip />} />
          <Bar dataKey="count">
            {histogramData.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={entry.fill} />
            ))}
          </Bar>
          <ReferenceLine
            x={percentileRisk}
            stroke="red"
            strokeDasharray="3 3"
            label={<CustomLabel value="You" />}
          />
        </BarChart>
      </ResponsiveContainer>
      <div className="risk-legend">
        <span className="lower-risk">Lower Risk</span>
        <span className="average-risk">Average Risk</span>
        <span className="higher-risk">Higher Risk</span>
      </div>
    </div>
  );
};





const CustomDropdown = ({ options, value, onChange, placeholder, id, openDropdown, setOpenDropdown }) => {
  const isOpen = openDropdown === id;

  const handleToggle = () => {
    if (isOpen) {
      setOpenDropdown(null);
    } else {
      setOpenDropdown(id);
    }
  };

  return (
    <div className="custom-dropdown">
      <div className="dropdown-header" onClick={handleToggle}>
        {value || placeholder}
        {isOpen ? <ChevronUp size={20} /> : <ChevronDown size={20} />}
      </div>
      {isOpen && (
        <ul className="dropdown-list">
          {options.map((option) => (
            <li key={option} onClick={() => {
              onChange(option);
              setOpenDropdown(null);
            }}>
              {option}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

const CustomMultiSelect = ({ options, value, onChange, placeholder, id, openDropdown, setOpenDropdown }) => {
  const isOpen = openDropdown === id;

  const handleToggle = () => {
    if (isOpen) {
      setOpenDropdown(null);
    } else {
      setOpenDropdown(id);
    }
  };

  const handleOptionToggle = (option) => {
    const newValue = value.includes(option)
      ? value.filter(item => item !== option)
      : [...value, option];
    onChange(newValue);
  };

  return (
    <div className="custom-multi-select">
      <div className="multi-select-header" onClick={handleToggle}>
        {value.length > 0 ? `${value.length} selected` : placeholder}
        {isOpen ? <ChevronUp size={20} /> : <ChevronDown size={20} />}
      </div>
      {isOpen && (
        <ul className="multi-select-list">
          {options.map((option) => (
            <li 
              key={option} 
              onClick={() => handleOptionToggle(option)}
              className={value.includes(option) ? 'selected' : ''}
            >
              <input
                type="checkbox"
                checked={value.includes(option)}
                onChange={() => {}}
              />
              {option}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

const PatientInfoCard = ({ patientInfo }) => {
  const [errorMessage, setErrorMessage] = useState('');
  const [surveyExpanded, setSurveyExpanded] = useState(false);
  const [surveyData, setSurveyData] = useState({});
  const [openDropdown, setOpenDropdown] = useState(null);
  const [submissionStatus, setSubmissionStatus] = useState(null);
  const [surveyCompleted, setSurveyCompleted] = useState(false);

  const handleSurveyChange = (name, value) => {
    setSurveyData({ ...surveyData, [name]: value });
  };

  const handleSubmitSurvey = async (e) => {
    e.preventDefault();
    setSubmissionStatus('submitting');
    setErrorMessage('');

    try {
      const session = await Auth.currentSession();
      const user = await Auth.currentAuthenticatedUser();
      const jwtToken = session.getIdToken().getJwtToken();

      console.log('User:', user);
      console.log('JWT Token:', jwtToken);

      const surveyInput = {
        owner: user.attributes.sub,
        heightFeet: surveyData.heightFeet ? parseInt(surveyData.heightFeet) : null,
        heightInches: surveyData.heightInches ? parseInt(surveyData.heightInches) : null,
        weight: surveyData.weight ? parseFloat(surveyData.weight) : null,
        bloodPressure: surveyData.bloodPressure,
        cholesterolLevel: surveyData.cholesterolLevel ? parseFloat(surveyData.cholesterolLevel) : null,
        ldlCholesterol: surveyData.ldlCholesterol ? parseFloat(surveyData.ldlCholesterol) : null,
        hdlCholesterol: surveyData.hdlCholesterol ? parseFloat(surveyData.hdlCholesterol) : null,
        triglycerides: surveyData.triglycerides ? parseFloat(surveyData.triglycerides) : null,
        fastingBloodSugar: surveyData.fastingBloodSugar ? parseFloat(surveyData.fastingBloodSugar) : null,
        hba1c: surveyData.hba1c ? parseFloat(surveyData.hba1c) : null,
        smokingStatus: surveyData.smokingStatus,
        alcoholConsumption: surveyData.alcoholConsumption,
        exerciseFrequency: surveyData.exerciseFrequency,
        exerciseIntensity: surveyData.exerciseIntensity,
        diabetesDiagnosis: surveyData.diabetesDiagnosis,
        hypertensionDiagnosis: surveyData.hypertensionDiagnosis,
        familyHistory: surveyData.familyHistory,
        sleepHours: surveyData.sleepHours ? parseFloat(surveyData.sleepHours) : null,
        psaScreening: surveyData.psaScreening,
        colonoscopyScreening: surveyData.colonoscopyScreening,
      };

      const response = await API.graphql({
        ...graphqlOperation(createSurveyResponse, { input: surveyInput }),
        authMode: 'AMAZON_COGNITO_USER_POOLS',
        authToken: jwtToken
      });
  
      console.log('Survey submitted successfully:', response);
      setSubmissionStatus('success');
      setSurveyCompleted(true); // Set survey as completed
      setSurveyExpanded(false); // Collapse the survey section
    } catch (error) {
      console.error('Detailed error:', JSON.stringify(error, null, 2));
      console.error('Error submitting survey:', error);
      if (error.errors) {
        error.errors.forEach(err => {
          console.error('GraphQL error:', err.message);
          console.error('Error type:', err.errorType);
          console.error('Error path:', err.path);
          console.error('Error locations:', err.locations);
        });
      }
      setErrorMessage(error.message || 'An error occurred while submitting the survey');
      setSubmissionStatus('error');
    }
  };

  const surveyQuestions = [
    { 
      name: 'height', 
      label: 'Height', 
      type: 'custom', 
      render: ({ value, onChange }) => (
        <div className="height-input-container">
          <div className="height-input-field">
            <input
              type="number"
              id="heightFeet"
              name="heightFeet"
              placeholder="Feet"
              value={surveyData.heightFeet || ''}
              onChange={(e) => handleSurveyChange('heightFeet', e.target.value)}
              min="0"
              max="8"
            />
            <label htmlFor="heightFeet">ft</label>
          </div>
          <div className="height-input-field">
            <input
              type="number"
              id="heightInches"
              name="heightInches"
              placeholder="Inches"
              value={surveyData.heightInches || ''}
              onChange={(e) => handleSurveyChange('heightInches', e.target.value)}
              min="0"
              max="11"
            />
            <label htmlFor="heightInches">in</label>
          </div>
        </div>
      )
    },
    { name: 'weight', label: 'Weight (lbs)', type: 'number', placeholder: 'Enter your weight in pounds' },
    { name: 'bloodPressure', label: 'Blood Pressure', type: 'text', placeholder: 'e.g., 120/80' },
    { name: 'cholesterolLevel', label: 'Total Cholesterol Level (mg/dL)', type: 'number', placeholder: 'Enter your cholesterol level' },
    { name: 'ldlCholesterol', label: 'LDL Cholesterol (mg/dL)', type: 'number', placeholder: 'Enter your LDL cholesterol' },
    { name: 'hdlCholesterol', label: 'HDL Cholesterol (mg/dL)', type: 'number', placeholder: 'Enter your HDL cholesterol' },
    { name: 'triglycerides', label: 'Triglycerides (mg/dL)', type: 'number', placeholder: 'Enter your triglycerides level' },
    { name: 'fastingBloodSugar', label: 'Fasting Blood Sugar (mg/dL)', type: 'number', placeholder: 'Enter your fasting blood sugar' },
    { name: 'hba1c', label: 'HbA1c (%)', type: 'number', placeholder: 'Enter your HbA1c percentage' },
    { name: 'smokingStatus', label: 'Smoking Status', type: 'dropdown', options: ['Never smoked', 'Former smoker', 'Current smoker'] },
    { name: 'alcoholConsumption', label: 'Alcohol Consumption', type: 'dropdown', options: ['None', '1-2 drinks/week', '3-7 drinks/week', '8-14 drinks/week', '15+ drinks/week'] },
    { name: 'exerciseFrequency', label: 'Exercise Frequency', type: 'dropdown', options: ['0 times/week', '1-2 times/week', '3-4 times/week', '5+ times/week'] },
    { name: 'exerciseIntensity', label: 'Exercise Intensity', type: 'dropdown', options: ['Light', 'Moderate', 'Vigorous'] },
    { name: 'diabetesDiagnosis', label: 'Diabetes Status', type: 'dropdown', options: ['No diabetes', 'Prediabetes', 'Type 2 Diabetes', 'Type 1 Diabetes'] },
    { name: 'hypertensionDiagnosis', label: 'Hypertension Diagnosis', type: 'dropdown', options: ['No', 'Borderline', 'Yes'] },
    {
      name: 'familyHistory',
      label: 'Family History of Diseases',
      type: 'multi-select',
      options: [
        'Heart Disease',
        'Diabetes',
        'Colorectal Cancer',
        'Breast Cancer',
        'Prostate Cancer'
      ]
    },
    { name: 'sleepHours', label: 'Average Sleep per Night', type: 'number', placeholder: 'Enter hours of sleep' },
    { name: 'psaScreening', label: 'PSA Screening (for men)', type: 'dropdown', options: ['Never', 'Within last year', '1-2 years ago', 'More than 2 years ago'] },
    { name: 'colonoscopyScreening', label: 'Colonoscopy Screening', type: 'dropdown', options: ['Never', 'Within last 5 years', '5-10 years ago', 'More than 10 years ago'] },
  ];


  return (
    <div className="patient-info-card">
      <h2 className="patient-info-title">Patient Information</h2>
      <div className="patient-info-grid">
        {Object.entries(patientInfo).map(([key, value]) => (
          <div key={key} className="patient-info-item">
            <span className="info-label">{key}</span>
            <span className="info-value">{value}</span>
          </div>
        ))}
      </div>

 
      <div className="survey-section">
        <div 
          className={`survey-header ${surveyExpanded ? 'expanded' : ''}`}
          onClick={() => !surveyCompleted && setSurveyExpanded(!surveyExpanded)}
        >
          <span>{surveyCompleted ? "Risk Factor Survey (Completed)" : "Take Risk Factor Survey"}</span>
          {!surveyCompleted && (surveyExpanded ? <ChevronUp size={24} /> : <ChevronDown size={24} />)}
        </div>
        {surveyExpanded && !surveyCompleted && (
          <form onSubmit={handleSubmitSurvey} className="survey-form">
            {surveyQuestions.map((question) => (
              <div key={question.name} className="form-group">
                <label htmlFor={question.name}>{question.label}</label>
                {question.type === 'dropdown' ? (
                  <CustomDropdown
                    options={question.options}
                    value={surveyData[question.name]}
                    onChange={(value) => handleSurveyChange(question.name, value)}
                    placeholder="Select an option"
                    id={question.name}
                    openDropdown={openDropdown}
                    setOpenDropdown={setOpenDropdown}
                  />
                ) : question.type === 'multi-select' ? (
                  <CustomMultiSelect
                    options={question.options}
                    value={surveyData[question.name] || []}
                    onChange={(value) => handleSurveyChange(question.name, value)}
                    placeholder="Select diseases"
                    id={question.name}
                    openDropdown={openDropdown}
                    setOpenDropdown={setOpenDropdown}
                  />
                ) : question.type === 'custom' ? (
                  question.render({
                    value: surveyData[question.name],
                    onChange: (value) => handleSurveyChange(question.name, value)
                  })
                ) : (
                  <input
                    type={question.type}
                    id={question.name}
                    name={question.name}
                    value={surveyData[question.name] || ''}
                    onChange={(e) => handleSurveyChange(question.name, e.target.value)}
                    placeholder={question.placeholder}
                    className="survey-input"
                  />
                )}
              </div>
            ))}
            <button 
              type="submit" 
              className={`submit-button ${submissionStatus === 'submitting' ? 'submitting' : ''}`}
              disabled={submissionStatus === 'submitting'}
            >
              {submissionStatus === 'submitting' ? 'Submitting...' : 'Submit Survey'}
            </button>
            {submissionStatus === 'success' && (
              <div className="submission-message success">Survey submitted successfully!</div>
            )}
            {submissionStatus === 'error' && (
              <div className="submission-message error">Error submitting survey. Please try again.</div>
            )}
          </form>
        )}
        {surveyCompleted && submissionStatus === 'success' && (
          <div className="submission-message success"></div>
        )}
      </div>
    </div>
  );
};









const MemberReportDashboard = () => {
  const reportData = useReportData();
  const [activeTab, setActiveTab] = useState('overview');
  const [selectedDisease, setSelectedDisease] = useState(reportData[0].disease);
  
  const patientInfo = useMemo(() => ({
    Name: 'John Doe',
    Age: 42,
    Gender: 'Male',
    'Report Date': '2023-06-25'
  }), []);

  const radarData = useMemo(() => reportData.map(item => ({
    subject: item.disease,
    A: parseFloat(item.relativeRisk),
    fullMark: 4
  })), [reportData]);

  const scatterData = useMemo(() => reportData.map(item => ({
    x: parseFloat(item.absoluteRisk) * 100, // Convert to percentage
    y: parseFloat(item.relativeRisk),
    z: item.percentileRisk,
    name: item.disease
  })), [reportData]);

  const absoluteRiskData = useMemo(() => reportData.map(item => ({
    name: item.disease,
    risk: parseFloat(item.absoluteRisk) * 100,
    avgRisk: parseFloat(item.avgAbsoluteRisk) * 100
  })), [reportData]);

  return (
    <div className="polycypher-report">
    <div className="report-header">
      PolyCypher Health Report
    </div>

      <PatientInfoCard patientInfo={patientInfo} />

      <div className="report-tabs">
      {['overview', 'details', 'visualizations', 'histograms'].map((tab) => (
        <button
          key={tab}
          className={`report-tab ${activeTab === tab ? 'active' : ''}`}
          onClick={() => setActiveTab(tab)}
        >
          {tab.charAt(0).toUpperCase() + tab.slice(1)}
        </button>
      ))}
      </div>

      <div className="tab-content">
        {activeTab === 'overview' && (
          <div className="overview-charts">
            <div className="chart-card">
              <h3>Relative Risk Overview</h3>
              <ResponsiveContainer width="100%" height={400}>
                <RadarChart data={radarData}>
                  <PolarGrid />
                  <PolarAngleAxis dataKey="subject" />
                  <PolarRadiusAxis angle={30} domain={[0, 4]} />
                  <Radar name="Relative Risk" dataKey="A" stroke="#8884d8" fill="#8884d8" fillOpacity={0.6} />
                </RadarChart>
              </ResponsiveContainer>
            </div>
            <div className="chart-card">
              <h3>Percentile Risk Distribution</h3>
              <ResponsiveContainer width="100%" height={400}>
                <BarChart data={reportData} layout="vertical">
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis type="number" />
                  <YAxis dataKey="disease" type="category" width={150} />
                  <Tooltip />
                  <Legend />
                  <Bar dataKey="percentileRisk" fill="#82ca9d" name="Percentile Risk" />
                </BarChart>
              </ResponsiveContainer>
            </div>
          </div>
        )}



        {activeTab === 'details' && (
          <div className="details-table">
            <h3 className="table-title">Detailed Risk Assessment</h3>
            <div className="table-container">
              <table>
                <thead>
                  <tr>
                    {['Disease', 'Relative Risk', 'Percentile Risk', 'Absolute Risk', 'Polygenic Risk'].map(header => (
                      <th key={header}>{header}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {reportData.map((item, index) => (
                    <tr key={index}>
                      {Object.entries(item).map(([key, value], i) => (
                        <td key={i} className={key.toLowerCase().replace(' ', '-')}>
                          {key === 'Percentile Risk' ? `${value}%` : value}
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        )}



{activeTab === 'visualizations' && (
  <div className="visualizations">
    <div className="chart-card risk-correlation">
      <h3>Risk Correlation</h3>
      <div className="chart-container">
        <div className="disease-menu">
          {reportData.map(item => (
            <button
              key={item.disease}
              className={`disease-button ${selectedDisease === item.disease ? 'active' : ''}`}
              onClick={() => setSelectedDisease(item.disease)}
            >
              {item.disease}
            </button>
          ))}
        </div>
        <ResponsiveContainer width="80%" height={500}>
          <ScatterChart margin={{ top: 20, right: 20, bottom: 70, left: 70 }}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis 
              type="number" 
              dataKey="x" 
              name="Absolute Risk" 
              unit="%" 
              label={{ value: "Absolute Risk (%)", position: "bottom", offset: 50 }} 
            />
            <YAxis 
              type="number" 
              dataKey="y" 
              name="Relative Risk" 
              unit="x" 
              label={{ value: "Relative Risk", angle: -90, position: "insideLeft", offset: -50 }} 
            />
            <ZAxis type="number" dataKey="z" range={[40, 400]} name="Percentile" unit="%" />
            <Tooltip 
              cursor={{ strokeDasharray: '3 3' }}
              content={({ active, payload }) => {
                if (active && payload && payload.length) {
                  const data = payload[0].payload;
                  return (
                    <div className="custom-tooltip risk-correlation-tooltip">
                      <p className="disease">{data.name}</p>
                      <p>Absolute Risk: {data.x.toFixed(2)}%</p>
                      <p>Relative Risk: {data.y.toFixed(2)}x</p>
                      <p>Percentile: {data.z}%</p>
                    </div>
                  );
                }
                return null;
              }}
            />
            <Legend />
            <Scatter 
              name="Disease Risks" 
              data={scatterData} 
              fill="#8884d8"
              shape="circle"
            >
              {scatterData.map((entry, index) => (
                <Cell 
                  key={`cell-${index}`}
                  fill={entry.z < 33 ? "#2ecc71" : entry.z < 66 ? "#f39c12" : "#e74c3c"}
                />
              ))}
            </Scatter>
            <LabelList 
              dataKey="name" 
              position="top" 
              content={({x, y, value}) => {
                if (value === selectedDisease) {
                  return (
                    <text x={x} y={y} dy={-10} fill="#ff0000" fontSize={12} textAnchor="middle">
                      You
                    </text>
                  );
                }
                return null;
              }} 
            />
          </ScatterChart>
        </ResponsiveContainer>
      </div>
      <div className="chart-description">
        <p>This chart shows the relationship between absolute risk and relative risk for various diseases. The size of each bubble represents the risk percentile. The highlighted disease shows your position.</p>
      </div>
    </div>

    <div className="chart-card absolute-risk-comparison">
      <h3>Absolute Risk Comparison</h3>
      <ResponsiveContainer width="100%" height={500}>
        <BarChart
          data={absoluteRiskData}
          layout="vertical"
          margin={{ top: 20, right: 30, left: 100, bottom: 5 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis type="number" unit="%" />
          <YAxis dataKey="name" type="category" width={150} />
          <Tooltip
            content={({ active, payload }) => {
              if (active && payload && payload.length) {
                const data = payload[0].payload;
                return (
                  <div className="custom-tooltip absolute-risk-tooltip">
                    <p className="name">{data.name}</p>
                    <p>Your Absolute Risk: {data.risk.toFixed(2)}%</p>
                    <p>Average Absolute Risk: {data.avgRisk.toFixed(2)}%</p>
                  </div>
                );
              }
              return null;
            }}
          />
          <Legend />
          <Bar dataKey="risk" name="Your Absolute Risk" fill="#8884d8">
            {absoluteRiskData.map((entry, index) => (
              <Cell 
                key={`cell-${index}`}
                fill={entry.name === selectedDisease ? "#e74c3c" : "#8884d8"}
              />
            ))}
          </Bar>
          <Bar dataKey="avgRisk" name="Average Absolute Risk" fill="#82ca9d" />
          <ReferenceLine x="0" stroke="#000" />
        </BarChart>
      </ResponsiveContainer>
      <div className="chart-description">
        <p>This chart compares your absolute risk to the average absolute risk for all diseases. The red bars represent your risk, while the green bars represent the average risk in the population.</p>
      </div>
    </div>
  </div>
)}



        {activeTab === 'histograms' && (
          <div className="histograms">
            {reportData.map(item => (
              <RiskHistogram key={item.disease} {...item} />
            ))}
          </div>
        )}
      </div>

      <div className="risk-interpretation">
        <h2>Risk Interpretation</h2>
        <p>This report provides an assessment of your genetic predisposition to various diseases based on polygenic risk scores (PRS). The PRS is calculated using information from large-scale genetic studies and represents the cumulative effect of many genetic variants on disease risk.</p>
        <ul>
          <li><strong>Relative Risk:</strong> Compares your risk to the average risk in the population. A value greater than 1 indicates an increased risk, while a value less than 1 indicates a decreased risk.</li>
          <li><strong>Percentile Risk:</strong> Indicates where your risk falls compared to the general population. A higher percentile indicates a higher risk relative to others.</li>
          <li><strong>Absolute Risk:</strong> Represents the likelihood of developing the disease over a specific time period, typically lifetime risk.</li>
          <li><strong>Polygenic Risk:</strong> A qualitative assessment of your genetic risk, categorized as Low, Normal, or High based on your PRS.</li>
        </ul>
        <p>Remember that genetic risk is just one factor in overall disease risk. Lifestyle, environment, and other non-genetic factors also play crucial roles. Always consult with healthcare professionals for personalized medical advice and recommendations.</p>
      </div>
    </div>
  );
};

export default MemberReportDashboard;
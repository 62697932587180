import React, { useState, useEffect } from 'react';
import { Typography, Button, List, ListItem, ListItemText, Divider, Dialog, DialogTitle, DialogContent, DialogActions, TextField, Snackbar } from '@mui/material';
import { Link } from 'react-router-dom';
import { Alert } from '@mui/material';

const initialSurveys = [
  { id: 1, name: 'Health Habits Survey', results: [
    { question: 'Do you exercise regularly?', yes: 65, no: 35 },
    { question: 'Do you maintain a balanced diet?', yes: 58, no: 42 },
    { question: 'Do you get enough sleep?', yes: 42, no: 58 }
  ], sent: null },
  { id: 2, name: 'Customer Satisfaction Survey', results: [
    { question: 'Are you satisfied with our services?', yes: 78, no: 22 },
    { question: 'Would you recommend us to others?', yes: 82, no: 18 },
    { question: 'Do you find our platform easy to use?', yes: 70, no: 30 }
  ], sent: null },
  { id: 3, name: 'Risk Awareness Survey', results: [
    { question: 'Are you aware of your current health risks?', yes: 60, no: 40 },
    { question: 'Have you taken steps to mitigate your health risks?', yes: 55, no: 45 },
    { question: 'Do you regularly consult with your doctor about your health risks?', yes: 48, no: 52 }
  ], sent: null }
];

function Surveys() {
  const [surveys, setSurveys] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [newSurveyName, setNewSurveyName] = useState('');
  const [openResults, setOpenResults] = useState(false);
  const [selectedSurvey, setSelectedSurvey] = useState(null);
  const [openMessage, setOpenMessage] = useState(false);
  const [message, setMessage] = useState('');
  const [patientId, setPatientId] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  useEffect(() => {
    const storedSurveys = localStorage.getItem('surveys');
    if (storedSurveys) {
      setSurveys(JSON.parse(storedSurveys));
    } else {
      setSurveys(initialSurveys);
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('surveys', JSON.stringify(surveys));
  }, [surveys]);

  const handleCreateSurvey = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setNewSurveyName('');
  };

  const handleSaveSurvey = () => {
    if (newSurveyName.trim() !== '') {
      const newSurvey = {
        id: surveys.length + 1,
        name: newSurveyName.trim(),
        results: [],
        sent: null
      };
      setSurveys([...surveys, newSurvey]);
      handleCloseDialog();
    }
  };

  const handleViewResults = (survey) => {
    setSelectedSurvey(survey);
    setOpenResults(true);
  };

  const handleCloseResults = () => {
    setOpenResults(false);
    setSelectedSurvey(null);
  };

  const handleCreateMessage = () => {
    setOpenMessage(true);
  };

  const handleCloseMessage = () => {
    setOpenMessage(false);
    setMessage('');
    setPatientId('');
  };

  const handleSendMessage = () => {
    if (message && patientId) {
      setSnackbarMessage(`Message sent to Patient #${patientId}`);
      setSnackbarOpen(true);
      handleCloseMessage();
    }
  };

  const handleSendSurvey = (surveyId) => {
    const updatedSurveys = surveys.map(survey => {
      if (survey.id === surveyId) {
        return { ...survey, sent: new Date().toLocaleString() };
      }
      return survey;
    });
    setSurveys(updatedSurveys);
    setSnackbarMessage(`Survey sent to patients`);
    setSnackbarOpen(true);
  };

  return (
    <div>
      <Typography variant="h4" gutterBottom>
        Surveys and Communication
      </Typography>
      <Typography variant="h6" gutterBottom>
        Active Surveys
      </Typography>
      <List>
        {surveys.map((survey) => (
          <React.Fragment key={survey.id}>
            <ListItem button>
              <ListItemText 
                primary={survey.name} 
                secondary={survey.sent ? `Sent on: ${survey.sent}` : null}
              />
              {survey.results.length > 0 ? (
                <Button variant="outlined" color="primary" onClick={() => handleViewResults(survey)}>
                  View Results
                </Button>
              ) : survey.sent ? (
                <Button variant="outlined" color="primary" disabled>
                  Survey Sent
                </Button>
              ) : (
                <>
                  <Button 
                    variant="outlined" 
                    color="primary" 
                    component={Link} 
                    to={`/survey-questions/${survey.id}`}
                    style={{ marginRight: '10px' }}
                  >
                    Create Survey Questions
                  </Button>
                  <Button 
                    variant="contained" 
                    color="primary" 
                    onClick={() => handleSendSurvey(survey.id)}
                  >
                    Send Survey to Patients
                  </Button>
                </>
              )}
            </ListItem>
            <Divider />
          </React.Fragment>
        ))}
      </List>
      <Button variant="contained" color="primary" style={{ marginTop: '20px' }} onClick={handleCreateSurvey}>
        Create New Survey
      </Button>
      <Typography variant="h6" gutterBottom style={{ marginTop: '20px' }}>
        Messages
      </Typography>
      <Button variant="contained" color="secondary" style={{ marginRight: '10px' }} onClick={handleCreateMessage}>
        Create Message
      </Button>
      <Button variant="outlined" color="primary" style={{ marginRight: '10px' }}>
        Sent Messages
      </Button>
      <Button variant="outlined" color="primary">
        Inbox
      </Button>

      {/* Create Survey Dialog */}
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>Create New Survey</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Survey Name"
            type="text"
            fullWidth
            value={newSurveyName}
            onChange={(e) => setNewSurveyName(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSaveSurvey} color="primary">
            Create
          </Button>
        </DialogActions>
      </Dialog>

      {/* View Results Dialog */}
      <Dialog open={openResults} onClose={handleCloseResults} maxWidth="md" fullWidth>
        <DialogTitle>{selectedSurvey?.name} Results</DialogTitle>
        <DialogContent>
          {selectedSurvey?.results.map((result, index) => (
            <div key={index}>
              <Typography variant="subtitle1">{result.question}</Typography>
              <Typography>Yes: {result.yes}%</Typography>
              <Typography>No: {result.no}%</Typography>
              <Divider style={{ margin: '10px 0' }} />
            </div>
          ))}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseResults} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>

      {/* Create Message Dialog */}
      <Dialog open={openMessage} onClose={handleCloseMessage}>
        <DialogTitle>Create Message</DialogTitle>
        <DialogContent>
          <TextField
            margin="dense"
            label="Patient ID"
            type="text"
            fullWidth
            value={patientId}
            onChange={(e) => setPatientId(e.target.value)}
          />
          <TextField
            margin="dense"
            label="Message"
            type="text"
            fullWidth
            multiline
            rows={4}
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseMessage} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSendMessage} color="primary">
            Send
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={() => setSnackbarOpen(false)}>
        <Alert onClose={() => setSnackbarOpen(false)} severity="success">
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
}

export default Surveys;

import React from 'react';

const styles = {
    container: {
      color: '#000',
      backgroundColor: '#f8f8f8',
      padding: '20px',
      maxWidth: '80vw',
      margin: '40px auto',
      borderRadius: '8px',
      boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
      fontFamily: '"Helvetica Neue", Helvetica, Arial, sans-serif',
    },
    header: {
      color: '#000',
      textAlign: 'center',
      marginBottom: '30px',
    },
    section: {
      background: '#fff',
      maxWidth: '80vw',
      margin: 'auto',
      padding: '20px',
      borderRadius: '8px',
      marginBottom: '20px',
      borderLeft: '5px solid #3418db',
      boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)', // Added box shadow for depth
    },
    subHeader: {
      color: '#000',
      fontSize: '22px',
      fontWeight: '600',
      marginBottom: '10px',
      borderBottom: '2px solid #3418db',
      paddingBottom: '5px',
    },
    paragraph: {
      color: '#000',
      fontSize: '16px',
      lineHeight: '1.6',
      textAlign: 'justify',
      maxWidth: '80vw',
      marginBottom: '15px',
    },
    list: {
      listStyle: 'disc',
      paddingLeft: '20px',
      marginLeft: '0',
      padding: '0',
    },
    listItem: {
      marginBottom: '15px',
      fontSize: '16px',
      lineHeight: '1.6',
      color: '#000',
      position: 'relative',
      '::before': {
        content: '"•"',
        color: '#3418db',
        fontWeight: 'bold',
        display: 'inline-block',
        width: '20px',
        marginLeft: '-20px',
        position: 'absolute', // Changed position to absolute for proper alignment
        left: '0',
      },
    },
    reference: {
      color: '#3418db',
      textDecoration: 'none',
      fontSize: '0.85em',
      marginLeft: '5px',
      position: 'relative',
      top: '-2px',
    },
    referenceText: {
      fontWeight: 'bold',
      color: '#34495e',
      fontSize: '0.7em',
      position: 'relative',
      top: '-5px',
      left: '2px',
    },
    link: {
      color: '#3468db',
      textDecoration: 'none',
      fontWeight: '600',
      ':hover': {
        textDecoration: 'underline',
      },
    },
    href: {
      color: 'blue',
      textDecoration: 'underline',
    },
    figcaption: {
      color: '#000',
      fontSize: '0.7em',
      textAlign: 'center',
      marginTop: '5px',
      fontStyle: 'italic',
      fontWeight: 'normal',
    },
  };


  const MTAG = () => (
    <div style={styles.container}>
      <h2 style={styles.header}>Multi-trait analysis of GWAS (MTAG)</h2>
      <section style={styles.section}>
        <h2 style={styles.subHeader}>Introduction</h2>
        <p style={styles.paragraph}>
          The standard approach in genetic association studies is to meta-analyze association statistics from cohort-level genome-wide association analyses of a single trait. Such single-trait analyses do not exploit information that may be available from genome-wide association studies (GWAS).
        </p>
        <p style={styles.paragraph}>
          When multiple correlated phenotypes are available, a multi-trait analysis can improve the association power.
        </p>
        <h2 style={styles.subHeader}>Kernel-Based Testing</h2>
        <p style={styles.paragraph}>
          For a given phenotype, we adopt a rapid and powerful kernel-based testing method which can evaluate the joint effect of multiple variants within a gene. The joint effect, either linear or nonlinear, is captured through kernel functions. Given a series of candidate kernel functions, we propose an omnibus test strategy to integrate the test results based on different candidate kernels.
        </p>
        <h2 style={styles.subHeader}>P-Value Combination</h2>
        <p style={styles.paragraph}>
          A p-value combination method is then applied to integrate dependent p-values to assess the association between a gene and multiple correlated phenotypes.
        </p>
        <h2 style={styles.subHeader}>Requirements</h2>
        <p style={styles.paragraph}>
          It can be applied without access to individual-level data, only requiring GWAS summary statistics (SNP with effect size list).
        </p>
        <p style={styles.paragraph}>
          The summary statistics need not come from independent discovery samples.
        </p>
        <h2 style={styles.subHeader}>Resources</h2>
        <p style={styles.paragraph}>
          MTAG does not require powerful computational resources.
        </p>
        <p style={styles.paragraph}>
          For more information, you can visit the following links:
        </p>
        <ul style={styles.list}>
          <li style={styles.listItem}>
            <span style={styles.listItemContent}>
              <a href="https://github.com/JonJala/mtag" style={styles.link}>MTAG GitHub Repository</a>
            </span>
          </li>
          <li style={styles.listItem}>
            <span style={styles.listItemContent}>
              <a href="https://www.nature.com/articles/s41588-017-0009-4" style={styles.link}>MTAG Research Paper</a>
            </span>
          </li>
        </ul>
      </section>
    </div>
  );
    

  const SBayesR = () => (
    <div style={styles.container}>
      <h2 style={styles.header}>SBayesR - Summary-based Bayesian Regression</h2>
      <section style={styles.section}>
        <h3 style={styles.subHeader}>Introduction</h3>
        <p style={styles.paragraph}>
          SBayesR utilizes summary statistics from genome-wide association studies (GWAS) and offers improved prediction accuracy relative to commonly used state-of-the-art summary statistics methods, requiring only a fraction of the computational resources.
        </p>
        <p style={styles.paragraph}>
          This method enables the generation of a more accurate list of SNPs with effect sizes, which can be used to develop polygenic risk scores.
        </p>
  
        <h3 style={styles.subHeader}>Functionality</h3>
        <p style={styles.paragraph}>
          SBayesR can function effectively with only summary statistics and a reference panel, without the need for individual-level or training data. However, its performance with thresholded summary statistics (e.g., running on only 1,000-20,000 betas/p-values) has not been extensively tested.
        </p>
        <p style={styles.paragraph}>
          It employs a strategy of 'binning' SNPs into a mixture of normally-distributed priors, which helps in shrinking SNP effect sizes while maximizing variance explained, and takes into account linkage disequilibrium.
        </p>
  
        <h3 style={styles.subHeader}>Technical Details</h3>
        <p style={styles.paragraph}>
          In Bayes R, the conditional prior assumes that all effect sizes fall into one of four possible distributions. Each distribution is a normal distribution with a mean of zero. The variances for these distributions are defined as a fraction of the total SNP effect size variance, 𝜎𝛽^2.
        </p>
        <p style={styles.paragraph}>
          Gibbs sampling, an instance of Markov Chain Monte Carlo (MCMC) methods, is used for approximation. This involves thousands of iterations to reach a region of high probability and a stationary distribution of probabilities.
        </p>
  
        <h3 style={styles.subHeader}>Usage Example</h3>
        <p style={styles.paragraph}>
          An example command for running SBayesR:
        </p>
        <pre style={styles.code}>
          gctb --sbayes R --ldm path_to_ldm --gwas-summary path_to_gwas_summary --pi 0.95,0.02,0.02,0.01 --gamma 0.0,0.01,0.1,1 --exclude-mhc --hsq 0.5 --chain-length 25000 --burn-in 5000 --seed 12345 --thread 4 --no-mcmc-bin --out path_to_output
        </pre>
  
        <h3 style={styles.subHeader}>Additional Resources</h3>
        <p style={styles.paragraph}>
          For more information:
        </p>
        <ul style={styles.list}>
          <li style={styles.listItem}><a href="https://cnsgenomics.com/software/gctb/" style={styles.link}>GCTB Software</a></li>
          <li style={styles.listItem}><a href="https://cnsgenomics.com/software/gctb/#Tutorial" style={styles.link}>GCTB Tutorial</a></li>
          <li style={styles.listItem}><a href="https://www.nature.com/articles/s41467-019-12653-0" style={styles.link}>Research Paper on SBayesR</a></li>
        </ul>
      </section>
    </div>
  );
  

  const PRSCSauto = () => (
    <div style={styles.container}>
      <h2 style={styles.header}>PRS-CS-auto</h2>
      <section style={styles.section}>
        <h3 style={styles.subHeader}>Introduction</h3>
        <p style={styles.paragraph}>
          PRS-CS-auto is a polygenic prediction method that infers posterior effect sizes of SNPs using genome-wide association summary statistics and an external linkage disequilibrium (LD) reference panel. It utilizes a high-dimensional Bayesian regression framework, placing a continuous shrinkage (CS) prior on SNP effect sizes.
        </p>
  
        <h3 style={styles.subHeader}>Functionality</h3>
        <p style={styles.paragraph}>
          This method excels when the training sample size is large. It works by calculating PRS from a subset of genetic markers, improving upon more straightforward methods that may not account for local LD patterns accurately. PRS-CS-auto uses a Bayesian regression framework with CS priors, enabling adaptive shrinkage based on the strength of association signals in GWAS.
        </p>
        <p style={styles.paragraph}>
          SNP effect sizes in each LD block are updated jointly in a multivariate fashion, as opposed to updating each marker separately. This method requires only GWAS summary statistics and an external LD reference panel for its operation.
        </p>
  
        <h3 style={styles.subHeader}>Advantages</h3>
        <p style={styles.paragraph}>
          PRS-CS-auto dramatically improves the predictive performance of PRS over existing methods, particularly with large sample sizes (N). By constructing a prior density on SNP effect sizes that is both peaked at zero and heavy-tailed, the method imposes strong shrinkage on small effects likely to be noise, while allowing truly non-zero signals to remain largely unshrunken.
        </p>
  
        <h3 style={styles.subHeader}>Considerations</h3>
        <p style={styles.paragraph}>
          The ideal input for PRS-CS is a full set of unpruned summary statistics. Pruning or clumping, especially when only a limited number of variants are left, can reduce the effectiveness of PRS-CS, as it relies on appropriately modeling LD.
        </p>
  
        <h3 style={styles.subHeader}>Resources</h3>
        <p style={styles.paragraph}>
          For more information and access to the PRS-CS-auto tool:
        </p>
        <ul style={styles.list}>
          <li style={styles.listItem}><a href="https://github.com/getian107/PRScs" style={styles.link}>PRS-CS-auto GitHub Repository</a></li>
          <li style={styles.listItem}><a href="https://www.nature.com/articles/s41467-019-09718-5" style={styles.link}>PRS-CS-auto Research Paper</a></li>
        </ul>
      </section>
    </div>
  );
  

  const LDpred2 = () => (
    <div style={styles.container}>
      <h2 style={styles.header}>LDpred2 - Polygenic Risk Score Prediction</h2>
      <section style={styles.section}>
        <h3 style={styles.subHeader}>Key Features</h3>
        <p style={styles.paragraph}>
          LDpred2 offers two important options for polygenic risk score prediction:
        </p>
        <ul style={styles.list}>
          <li style={styles.listItem}>
            A ‘sparse’ option, which identifies zero-effect variants, potentially reducing the number of variants used in the polygenic score without compromising predictive performance. It includes a hyperparameter for testing sparse models.
          </li>
          <li style={styles.listItem}>
            An ‘auto’ option, which automatically learns the two LDpred parameters directly from data, eliminating the need for a validation set to tune hyperparameters.
          </li>
        </ul>
  
        <h3 style={styles.subHeader}>Recommendations</h3>
        <p style={styles.paragraph}>
          For LDpred2-auto, it is recommended to run multiple chains in parallel with different initial values for the parameter <code>p</code>, which will be used for quality control afterwards. This option can be used even without individual-level data, though the best results are achieved with unthresholded data. A function like <code>snp_thr_correct()</code> may be used if only thresholded data is available.
        </p>
  
        <h3 style={styles.subHeader}>Visualization</h3>
        <figure style={styles.figcaption}>
          <img src="https://static.miraheze.org/genomicswiki/e/e5/Imageldpred.png" alt="LDpred2 Visualization" style={{ width: '100%', maxWidth: '560px' }} />
          <figcaption style={styles.figcaption}>LDpred2 Visualization</figcaption>
        </figure>
      </section>
    </div>
  );
  

  const PUMACUBS = () => (
    <div style={styles.container}>
      <h2 style={styles.header}>PUMAS/PUMA-CUBS - Polygenic Risk Score Optimization</h2>
      <section style={styles.section}>
        <h3 style={styles.subHeader}>Overview</h3>
        <p style={styles.paragraph}>
          PUMAS/PUMA-CUBS is a novel approach in polygenic risk scoring that requires only GWAS summary statistics and LD blocks for model tuning, eliminating the need for external or individual-level data. This method allows for the creation of an optimized set of SNP weights, which can then be applied to individual-level data to enhance disease prediction accuracy.
        </p>
  
        <h3 style={styles.subHeader}>Methodology</h3>
        <p style={styles.paragraph}>
          The PUMAS software is used to identify the best PRS model. However, it does not directly output the optimal SNP weights. Instead, one uses PUMAS to find the most effective PRS model and then trains this model using the complete GWAS summary statistics to obtain the optimal set of SNP weights.
        </p>
        <p style={styles.paragraph}>
          Multiple optimal PRS models can be trained, each based on a different PRS method. PUMA-CUBS then comes into play to provide weights for linearly combining these PRS methods’ SNP weights, resulting in improved predictive performance.
        </p>
  
        <h3 style={styles.subHeader}>Resources and References</h3>
        <p style={styles.paragraph}>
          For more detailed information and access to the PUMAS/PUMA-CUBS software:
        </p>
        <ul style={styles.list}>
          <li style={styles.listItem}><a href="https://doi.org/10.1101/2022.10.26.513833" style={styles.link}>Research Paper on PUMAS/PUMA-CUBS</a></li>
          <li style={styles.listItem}><a href="https://github.com/qlu-lab/PUMAS" style={styles.link}>PUMAS GitHub Repository</a></li>
        </ul>
      </section>
    </div>
  );


  const GenomicLASSO = () => (
    <div style={styles.container}>
      <h2 style={styles.header}>Genomic LASSO</h2>
      <section style={styles.section}>
        <h3 style={styles.subHeader}>Objective Function</h3>
        <p style={styles.paragraph}>
          The LASSO (L1-penalized regression) method is used to select SNPs efficiently in the study of genomics. The objective function for LASSO takes the form:
        </p>
        <p style={styles.paragraph}>
          \( f(\beta) = (y - X\beta)^T(y - X\beta) + 2\lambda \|\beta\|_1 \)
        </p>
        <p style={styles.paragraph}>
          where \( y \) is the phenotype vector, \( X \) is the matrix of genomes, \( \beta \) is the vector of effect sizes, and \( \lambda \) is the penalization term.
        </p>
  
        <h3 style={styles.subHeader}>Modified Algorithm for Summary Statistics</h3>
        <p style={styles.paragraph}>
          In cases where only summary statistics are available, a modified LASSO algorithm can be applied. By estimating the LD matrix (\( X'X \)) and the phenotype-SNP correlations (\( X'y \)) from the summary statistics, LASSO can be adapted to work with this siloed data.
        </p>
        <p style={styles.paragraph}>
          Compressed sensing principles can also be applied, enabling the reconstruction of effect sizes from underdetermined systems, provided that the number of measurements (sample size) is sufficient relative to the sparsity of the effect and the dimensionality of the problem.
        </p>
  
        <h3 style={styles.subHeader}>Implications</h3>
        <p style={styles.paragraph}>
          This adaptation of LASSO is particularly beneficial in genomics, as it aligns with the efficient structure of human SNP genotype matrices, allowing for the analysis of large-scale data such as that found in GWAS studies. For a heritability (\( h^2 \)) of 0.5 and roughly one million SNPs, a sample size of about thirty times the number of markers with nonzero coefficients may be adequate.
        </p>
  
        <h3 style={styles.subHeader}>References</h3>
        <p style={styles.paragraph}>
          Further reading on the application of LASSO in genomics can be found in the following study: <a href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC6814833/pdf/41598_2019_Article_51258.pdf" style={styles.link}>Nature Scientific Reports</a>.
        </p>
      </section>
      <section style={styles.section}>
      <h3 style={styles.subHeader}>Truncated Lasso Penalty (TLP) Method</h3>
      <p style={styles.paragraph}>
        The Truncated Lasso Penalty (TLP) and the elastic net are techniques that can be utilized to generate polygenic risk scores (PRS) using only summary statistic data and publicly available reference data. These methods are particularly useful when there is limited public access to individual-level or full-summary statistic genomic data. Studies have shown that TLP performs comparably to LDpred (version 1) or lassosum in producing PRS, making it a valuable alternative in the field of genetic epidemiology.
      </p>
      <p style={styles.paragraph}>
        The TLP approach offers a promising avenue for researchers to continue advancing the generation of accurate PRS under constraints of data availability. For more information on penalized regression and model selection methods for PRS using summary statistics, refer to the following publication: <a href="https://journals.plos.org/ploscompbiol/article?id=10.1371/journal.pcbi.1008271" style={styles.link}>PLoS Computational Biology</a>.
      </p>
    </section>
    </div>
  );
  

const Lassosum = () => (
    <div style={styles.container}>
      <h2 style={styles.header}>Lassosum</h2>
      <section style={styles.section}>
        <h3 style={styles.subHeader}>Overview of Lassosum</h3>
        <p style={styles.paragraph}>
          Lassosum is a method for constructing polygenic scores (PGS) using summary statistics from genetic association studies along with a reference panel to account for linkage disequilibrium (LD). LD refers to the non-random association of alleles at different loci and is crucial in identifying variants associated with diseases or traits.
        </p>
  
        <h3 style={styles.subHeader}>Penalized Regression Framework</h3>
        <p style={styles.paragraph}>
          The method operates within a penalized regression framework, commonly known as lasso regression, where a tuning parameter controls the penalty's strength. This parameter influences the extent of shrinkage applied to the regression coefficients, aiding in the selection of relevant genetic variants.
        </p>
        <p style={styles.paragraph}>
          Lassosum utilizes a "pseudovalidation" process to select the optimal tuning parameter, striking a balance between model complexity and predictive accuracy.
        </p>
  
        <h3 style={styles.subHeader}>Advantages of Lassosum</h3>
        <p style={styles.paragraph}>
          Compared to simple clumping and thresholding methods based solely on P-values, lassosum typically yields better results by incorporating information about LD. This allows for a more nuanced and accurate identification of SNP associations.
        </p>
  
        <h3 style={styles.subHeader}>Evolutions and Related Methods</h3>
        <p style={styles.paragraph}>
          Lassosum has undergone various updates and inspired related methods that further enhance the accuracy of polygenic score calculations. These advancements reflect the ongoing improvements in genomic analysis techniques.
        </p>
  
        <h3 style={styles.subHeader}>Further Reading and Tools</h3>
        <p style={styles.paragraph}>
          To learn more about the lassosum method and access tools for PGS calculation, visit the <a href="https://github.com/tshmak/lassosum" style={styles.link}>Lassosum GitHub Repository</a>.
        </p>
      </section>
    </div>
  );  
  


export { MTAG, SBayesR, PRSCSauto, LDpred2, PUMACUBS, GenomicLASSO, Lassosum }
import React, { useEffect, useRef } from 'react';
import * as d3 from 'd3';
import './Dashboard.css';

const generateNormalData = (n, mean, stdDev) => {
  return Array.from({ length: n }, () => {
    let u1 = Math.random();
    let u2 = Math.random();
    let z1 = Math.sqrt(-2.0 * Math.log(u1)) * Math.cos(2.0 * Math.PI * u2);
    return z1 * stdDev + mean;
  });
};

const createHistogram = (data, element, userZScore) => {
    const svg = d3.select(element);
    const margin = { top: 10, right: 30, bottom: 30, left: 40 };
    const width = 460 - margin.left - margin.right;
    const height = 400 - margin.top - margin.bottom;

    svg.attr('width', width + margin.left + margin.right)
       .attr('height', height + margin.top + margin.bottom);

    const x = d3.scaleLinear()
                .domain([-4, 4])
                .range([0, width]);

    // Update the x-axis and add the label "Polygenic Score"
    svg.append('g')
        .attr('transform', `translate(${margin.left},${height + margin.top})`)
        .call(d3.axisBottom(x).tickFormat((d) => d).tickSize(0))
        .append("text")
        .attr("class", "label")
        .attr("x", width / 2)  // Center the text horizontally
        .attr("y", 30)  // Move it down a bit
        .style("text-anchor", "middle")
        .text("Polygenic Score");

  
    const histogram = d3.histogram()
                        .value((d) => d)
                        .domain(x.domain())
                        .thresholds(x.ticks(40));
  
    const bins = histogram(data);
  
    const y = d3.scaleLinear()
                .range([height, 0]);
    y.domain([0, d3.max(bins, (d) => d.length)]);
  
    const colorScale = d3.scaleLinear()
                         .domain([-4, 0, 4])
                         .range(["green", "blue", "red"]);
  
    const bar = svg.selectAll('.bar')
                   .data(bins)
                   .enter().append('g')
                   .attr('class', 'bar')
                   .attr('transform', (d) => `translate(${x(d.x0) + margin.left},${y(d.length) + margin.top})`);
  
    bar.append('rect')
       .attr('x', 1)
       .attr('width', x(bins[0].x1) - x(bins[0].x0) - 1)
       .attr('height', (d) => height - y(d.length))
       .attr('fill', (d) => colorScale(d.x0));
  
    // Add user's z-score as a vertical line with a triangle indicator
    const userLine = svg.append('line')
                        .attr('x1', x(userZScore) + margin.left)
                        .attr('y1', margin.top)
                        .attr('x2', x(userZScore) + margin.left)
                        .attr('y2', height + margin.top)
                        .attr('stroke', 'gold')
                        .attr('stroke-width', 2);
  
    svg.append('polygon')
        .attr('points', `${x(userZScore) + margin.left - 5},${height + margin.top} ${x(userZScore) + margin.left + 5},${height + margin.top} ${x(userZScore) + margin.left},${height + margin.top - 10}`)
        .attr('fill', 'gold');

    // Add text "higher risk" at the right of the histogram
    svg.append("text")
        .attr("x", width + margin.left - 10)
        .attr("y", margin.top + 100)
        .attr("text-anchor", "end")
        .style("fill", "red")
        .style("font-weight", "bold")
        .text("Higher risk");

    // Add text "lower risk" at the left of the histogram
    svg.append("text")
        .attr("x", margin.left + 10)
        .attr("y", margin.top + 100)
        .attr("text-anchor", "start")
        .style("fill", "green")
        .style("font-weight", "bold")
        .text("Lower risk");
  };  

const Example = () => {
    const dataCAD = generateNormalData(1000, 0, 1);
    const dataDisease2 = generateNormalData(1000, 0, 1);
    const dataStroke = generateNormalData(1000, 0, 1);
    const dataAlzheimer = generateNormalData(1000, 0, 1);
    const dataDiabetes = generateNormalData(1000, 0, 1);
  
    const refCAD = useRef(null);
    const refDisease2 = useRef(null);
    const refStroke = useRef(null);
    const refAlzheimer = useRef(null);
    const refDiabetes = useRef(null);
  
    useEffect(() => {
      createHistogram(dataCAD, refCAD.current, -0.5);
      createHistogram(dataDisease2, refDisease2.current, 1.2);
      createHistogram(dataStroke, refStroke.current, 0.3);
      createHistogram(dataAlzheimer, refAlzheimer.current, -1.1);
      createHistogram(dataDiabetes, refDiabetes.current, 0.8);
    }, [dataCAD, dataDisease2, dataStroke, dataAlzheimer, dataDiabetes]);
  
    return (
      <div className="example-report">
        <div className="header">
        <h1>PolyCypher Precision Health Report <small><i>(example)</i></small></h1>
        </div>
        <div className="report-content">
          <section>
            <h2>Check back soon!</h2>
          </section>
        </div>
        <div className="footer">
          PolyCypher Health Polygenic Score Report
        </div>
      </div>
    );
  };
  
  export default Example;